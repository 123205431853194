@import "./../../styles/utils/variables";

.upgrade-bar {
  background: $color-brand-1;
  box-shadow: 2px 4px 16px 0px rgba(51, 51, 51, 0.08);
  height: 63px;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
