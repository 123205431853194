@import "../../styles/utils/mixins";
@import "../../styles/utils/_variables";

@font-face {
  font-family: "Bookmania Semibold";
  src: url("../../styles/fonts/bookmania-semibold.otf") format("opentype");
}

.sidemenu {
  width: 77px;
  position: fixed;
  background: $color-font-base;
  z-index: 999;
  font-family: "Roboto", sans-serif;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  height: 100vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include screen-sm {
    width: 70px;
  }

  &__logo {
    padding-top: 33px;
    display: flex;
    padding-bottom: 33px;
    color: #fff;
    justify-content: center;
    align-items: center;
  }

  &__item {
    cursor: pointer;
    height: 72px;
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.-is-active {
      background: $color-brand-1;
    }

    &-label {
      color: #fff;
      font-size: 12px;
      font-weight: bold;
      text-decoration: none;
      margin-top: 5px;
    }

    &-icon {
      min-width: 20px;
    }

    &:hover {
      background: $color-brand-2;
      text-decoration: none;

      .sidemenu__item-label {
        text-decoration: none;
      }
    }
  }

  &__options {
    margin-bottom: 132px;
  }
}
