input,
select {
	background: #fff;
	border-radius: 5px !important;
	border: 1px solid $color-font-light !important;
	height: 38px !important;
	font-size: 14px !important;
	color: $color-font-base !important;

	&::placeholder {
		color: $color-font-light !important;
	}

	&:focus {
		box-shadow: none !important;
		border: 1px solid $color-font-light !important;
	}
}

textarea {
	background: #fff;
	border-radius: 5px !important;
	border: 1px solid $color-font-light !important;
	resize: none !important;

	&::placeholder {
		color: $color-font-light !important;
	}

	&:focus {
		box-shadow: none !important;
		border-color: $color-font-light !important;
	}
}

.dropdown-item {
	&:active {
		color: #16181b;
		text-decoration: none !important;
		background-color: #f8f9fa;
		outline: none;
	}
}

.react-datepicker__header {
	background-color: $color-light-gray !important;
	border-color: #e0e7ff !important;
}

blockquote {
	overflow: hidden;
	padding-right: 1.5em;
	padding-left: 1.5em;
	margin-left: 0;
	margin-right: 0;
	font-style: italic;
	border-left: 5px solid #ccc;
}

.react-datepicker {
	border-color: #e0e7ff !important;

	button {
		&:focus {
			outline: none;
		}
	}

	&__triangle {
		border-bottom-color: $color-light-gray !important;

		&:before {
			border-bottom-color: #e0e7ff !important;
		}
	}

	&__current-month,
	&-time__header,
	&-year-header {
		color: $color-brand-1;
	}

	&__time-container {
		border-color: #e0e7ff !important;
	}

	&__day,
	&__time-list-item {
		&:hover {
			background: rgba(116, 80, 200, 0.25) !important;
		}
	}

	&__day {
		&:hover {
			border-radius: 50%;
		}
	}

	&__day--keyboard-selected,
	&__day--selected,
	&__time-list-item--selected {
		background-color: $color-brand-1 !important;
	}

	&__day--keyboard-selected,
	&__day--selected {
		border-radius: 50%;
	}
}
