@import './../../styles/utils/mixins';
@import './../../styles/utils/variables';

@font-face {
    font-family: 'Bookmania Semibold';
    src: url('../../styles/fonts/bookmania-semibold.otf') format('opentype');
};

.dpa {
    &__info {
        overflow: auto;
        height: calc(100vh - 425px);
        max-width: 600px;

        &-title {
            font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
            letter-spacing: -0.2px;
            font-size: 28px;
            margin-bottom: 24px;
        }

        &-subtitle {
            font-size: 14px;
            font-weight: bold;
            color: $color-brand-1;
            margin-bottom: 32px;
        }

        &-text {
            font-size: 14px;
            color: $color-font-gray;
            margin-bottom: 64px;
        }
    }

    &__btns {
        padding-bottom: 20px;
    }

    &__term {
        padding-top: 71px;
        background: $color-lightest-gray;
        border-radius: 0px;
        height: 357px;
        padding-left: 72px;
        padding-right: 72px;

        @include screen-md {
            min-height: 357px;
        }

        &-title {
            margin-top: 85px;
            font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
            letter-spacing: -0.54px;
            margin-bottom: 16px;
            font-size: 56px;
            font-weight: 600;
            letter-spacing: -0.54px;
        }

        &-text {
            font-size: 14px;
            color: $color-font-gray;
            max-width: 581px;
        }
    }

    &__content {
        padding-top: 64px;
        background: $color-light-gray;
        height: calc(100vh - 358px);

        &-title {
            padding-left: 72px;
            font-size: 28px;
            font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
            letter-spacing: -0.2px;
            margin-bottom: 16px;
        }

        &-text {
            font-size: 14px;
            color: $color-font-gray;
            padding-left: 72px;
            margin-bottom: 0;
        }

        &-form {
            padding-left: 72px;
            width: 569px;
            margin-top: 48px;
        }

        &-btn {
            width: 136px;
            float: right;
        }
    }

    &__menu {
        display: flex;
        flex-direction: column;
        min-width: 200px;

        &-title {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 23px;
            color: $color-brand-1;

            &:hover {
                color: $color-brand-1;
            }
        }
    }
}