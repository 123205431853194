@import "./../../styles/utils/mixins";
@import "./../../styles/utils/variables";

@font-face {
  font-family: "Bookmania Semibold";
  src: url("../../styles/fonts/bookmania-semibold.otf") format("opentype");
}

.modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
  overflow: hidden !important;
}

.breach-concluded-modal {
  background: #fff;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  height: 378px;
  max-width: 693px !important;
  width: 100% !important;

  @include screen-sm {
    max-width: 400px !important;
  }

  .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.modal-manage-consent {
  .manage-consent {
    overflow: auto;

    &__container {
      height: auto;
      padding-top: 0px;
      overflow: auto !important;
      background: $color-light-gray;
    }

    &__header {
      background: $color-lightest-gray;
      height: 307px;
      padding-top: 105px;

      &-title {
        font-family: "Bookmania Semibold", "Roboto", sans-serif;
        letter-spacing: -0.54px;
        margin-bottom: 24px;
      }

      &-text {
        font-size: 14px;
        color: $color-font-gray;
      }
    }

    &__items {
      background: #fff;
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      margin-top: 49px;
    }

    &__item {
      display: flex;
      flex-direction: column;
      padding: 29px 50px 38px 29px;
      border-bottom: 1px solid $color-lightest-gray;

      &-title {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 3px;
      }

      &-text {
        font-size: 14px;
        color: $color-font-gray;
        max-width: 300px;
      }

      &-more {
        background: $color-light-gray;
        border-radius: 4px;
        padding: 10px;
        color: $color-font-gray;
        font-size: 14px;
        margin-top: 24px;
      }
    }
  }
}

.select-users {
  min-height: 571px !important;
  max-width: 1219px !important;
  width: 100% !important;
  height: auto !important;

  &__title {
    font-size: 22px;
    font-family: "Bookmania Semibold", "Roboto", sans-serif;
    letter-spacing: -0.16px;
    margin-bottom: 16px;
  }

  &__cancel,
  &__save {
    width: 136px !important;
  }

  &__cancel {
    margin-right: 24px;
  }

  &__text {
    font-size: 14px;
    color: $color-font-gray;
    margin-bottom: 42px;
  }

  &__btns {
    margin-top: 64px;
  }

  img {
    margin-bottom: 29px;
  }

  &__suggestions {
    background: #fff;
    width: 764px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    max-height: 110px;
    overflow: auto;
    padding-left: 31px;
    padding-top: 10px;
    font-size: 14px;

    @include screen-sm {
      width: 90%;
    }

    p {
      cursor: pointer;
    }
  }

  &__selected {
    background: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    height: 59px;
    min-width: 384px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 24px;
    margin-top: 32px;
    font-size: 14px;
    font-weight: 600;
    color: #8697a6;
  }

  &__remove-selected {
    cursor: pointer;
  }

  .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.modal-introduction {
  height: 410px;
  max-width: 693px !important;
  width: 100% !important;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);

  .modal-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  &.-bigger-modal {
    height: 702px;
    min-width: 1112px !important;
  }

  &__img {
    width: 132px;
    margin-bottom: 32px;
  }

  &__title {
    font-size: 22px;
    font-weight: bold;
    color: $color-brand-1;
    margin-bottom: 24px;
  }

  &__message {
    font-size: 14px;
    width: 317px;
    text-align: center;
    color: $color-font-gray;
    margin-bottom: 24px;
  }

  &__btn {
    width: 223px;
  }
}

.modal-delete-page {
  height: 210px;
  max-width: 693px !important;
  width: 100% !important;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);

  .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.modal-upgrade {
  height: 663px;
  max-width: 1219px !important;
  width: 100% !important;
  background: fff;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px !important;
  overflow: hidden;

  @include screen-md {
    width: 90% !important;
  }

  @include screen-xs {
    height: 95%;
  }

  &__container {
    display: flex;
    padding: 0 !important;

    @include screen-xs {
      flex-direction: column-reverse;
      overflow: auto;
    }
  }

  &__close {
    position: absolute;
    top: 10px;
    cursor: pointer;
    right: 10px;
    z-index: 99;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 70%;
    padding: 10px;

    @include screen-xs {
      width: 100%;
    }

    ul {
      padding: 0;

      li {
        list-style-type: none;
        font-size: 16px;
        color: $color-font-gray;
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        @include screen-xs {
          svg {
            min-width: 20px;
            height: 20px;
          }
        }
      }
    }

    &-upgrade {
      max-width: 136px;
    }

    &-title {
      font-family: "Bookmania Semibold", "Roboto", sans-serif;
      letter-spacing: -0.2px;
      font-size: 28px;
      margin-bottom: 24px;

      @include screen-xs {
        text-align: center;
      }
    }

    &-subtitle {
      font-size: 16px;
      max-width: 483px;
      color: $color-font-gray;
      margin-bottom: 67px;
      text-align: center;

      @include screen-xs {
        margin-bottom: 20px;
      }
    }

    &-icon {
      width: 20px;
      height: 20px;
      margin-right: 16px;
    }
  }

  &__banner {
    width: 30%;
    background: $color-brand-1;
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include screen-xs {
      width: 100%;
    }

    &-img1 {
      max-width: 261px;
      margin-left: auto;
      width: 100%;
      z-index: 3;

      @include screen-xs {
        position: relative;
        top: 25px;
        height: 130px;
      }
    }

    &-img2 {
      margin-top: -52px;
      position: relative;
      right: 65px;
      width: 100%;

      @include screen-xs {
        position: relative;
        top: -15px;
        height: 130px;
        margin-top: -52px;
      }
    }

    &-img3 {
      max-width: 212px;
      margin-bottom: -92px;
      margin-left: -71px;

      @include screen-xs {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &-img4 {
      max-width: 280px;
      margin-left: auto;
      margin-right: auto;

      @include screen-xs {
        height: 120px;
      }
    }

    &-img5 {
      max-width: 441px;
      position: relative;
      left: -57px;

      @include screen-xs {
        height: 150px;
        left: 0px;
      }
    }

    &-img6 {
      max-width: 405px;
      position: relative;
      left: -20px;
    }

    &-img7 {
      width: 192px;
      margin-top: -90px;
      position: relative;
      left: -30px;
    }

    &-check {
      position: absolute;
      bottom: -6px;
      right: 90px;

      @include screen-sm {
        bottom: 6px;
      }
    }
  }
}

.modal-signature {
  height: auto;
  max-width: 693px !important;
  width: 100% !important;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  min-height: 429px;

  .sigCanvas {
    border-color: $color-brand-1 !important;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 47px;
  }

  &__file {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    height: 59px;
    width: 280px;
    padding: 13px 10px;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    &-type {
      border-radius: 0px;
      border: 1px solid rgb(223, 228, 238);
      box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.1);
      min-height: 34px;
      min-width: 26px;
      font-size: 7px;
      font-weight: bold;
      color: $color-brand-4;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
    }

    &-name {
      margin-bottom: 0;
      font-size: 12px;
      color: $color-font-gray;
      width: 195px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-remove {
      cursor: pointer;
    }
  }

  &__clear {
    font-size: 14px;
    font-weight: bold;
    color: $color-brand-1;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__btns {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-around;
  }

  &__title {
    font-size: 14px;
    color: $color-font-dark;
  }

  &__btn {
    max-width: 136px !important;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }

    &.-is-disabled {
      color: #dfe4ee !important;
      border-color: #dfe4ee !important;
      background: #fff;

      &:hover {
        background-color: #fff !important;
      }
    }
  }

  .modal-content {
    height: 100%;
    min-height: 429px;
  }
}

.modal-preview {
  min-width: 100vw !important;
  height: 100vh;
  overflow: hidden;

  .-is-contained {
    border-radius: 4px;
    min-height: 36px;
    min-width: 136px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  &__dpo-section {
    padding-left: 72px;
    padding-right: 72px;
  }

  &__impressum {
    &-info {
      padding-left: 72px;
    }

    &-title {
      width: 300px;
    }
  }

  &__divider {
    width: 90%;
    height: 1px;
    background: #dfe4ee;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  &__title {
    padding-left: 72px;
  }

  &__name {
    letter-spacing: -0.2px;
    font-family: "Bookmania Semibold", "Roboto", sans-serif;
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 24px;
    max-width: 600px;
    white-space: pre-wrap;
  }

  &__legal-content {
    background: rgb(255, 255, 255);
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    padding-top: 40px;
    margin-top: -30px;
    margin-bottom: 30px;
    margin-left: 72px;
    width: 90%;
    max-width: 1200px;
    padding-bottom: 40px;
  }

  .footer {
    &__container {
      padding-left: 72px;
      padding-right: 72px;
      margin-left: auto;
      margin-right: auto;
      justify-content: space-around;
      padding-top: 49px;
      padding-bottom: 49px;
      background: #dfe4ee;
    }

    &__logo {
      background: $color-brand-1;
      opacity: 0.65;
      height: 45px;
      width: 45px;
      min-width: 45px;
      display: block;
      border-radius: 50%;
      margin-right: 24px;

      &-container {
        height: 45px;
        display: block;
        margin-right: 24px;
        overflow: hidden;
        max-width: 150px;

        img {
          height: 100%;
          object-fit: contain;
          width: 100%;
        }
      }
    }

    p {
      font-size: 14px;
    }

    a {
      color: $color-font-base;
      font-size: 14px;

      &:hover {
        color: $color-font-base;
        text-decoration: none;
      }
    }

    &-text {
      border-top: 1px solid #969696;
      text-align: center;
      padding-top: 26px;
      padding-bottom: 26px;
      background: #dfe4ee;
      font-size: 14px;
    }
  }

  .page-preview {
    margin-top: 42px;
    height: calc(100vh - 209px);
    overflow: auto;
    width: 80% !important;
    zoom: 80%;
    height: 100%;

    blockquote {
      overflow: hidden;
      padding-right: 1.5em;
      padding-left: 1.5em;
      margin-left: 0;
      margin-right: 0;
      font-style: italic;
      border-left: 5px solid #ccc;
    }

    .table {
      table {
        width: 100%;
      }

      td {
        border: 1px solid #dee2e6 !important;
      }
    }

    &__popups-preview {
      height: 100vh;
    }

    @include screen-sm {
      margin-top: 20px;
      width: 100% !important;
      zoom: 100%;
    }

    &__header {
      background: rgb(223, 228, 238);
      border-radius: 0px;
      min-height: 306px;
      padding-left: 30px;

      @include screen-sm {
        padding-left: 10px;
        height: auto;
        min-height: auto;
        padding-bottom: 20px;
      }

      &-title {
        padding-top: 105px;
        font-family: "Bookmania Semibold", "Roboto", sans-serif;
        letter-spacing: -0.54px;
        font-size: 48px;
        margin-bottom: 16px;

        @include screen-sm {
          padding-top: 30px;
        }
      }

      &-text {
        font-size: 18px;
        color: $color-font-gray;
      }
    }

    &__content {
      margin-top: 0px;
      padding-top: 64px;
      padding-left: 30px;
      padding-right: 30px;
      display: flex;
      min-height: 400px;

      @include screen-xs {
        flex-direction: column;
        padding-right: 10px;
        margin-top: 30px;
        padding-right: 10px;
      }

      @include screen-sm {
        padding-left: 10px;
        padding-right: 10px;
      }

      &-container {
        max-width: 600px;

        .table {
          table {
            width: 100%;
          }

          td {
            border: 1px solid #dee2e6 !important;
          }
        }

        @include screen-xs {
          max-width: 100%;
        }
      }

      &-menu {
        min-width: 200px;
      }

      &-menu-item {
        font-size: 14px;
        font-weight: bold;
        color: $color-brand-1;
      }

      &-title {
        font-size: 28px;
        font-weight: 600;
        font-family: "Bookmania Semibold", "Roboto", sans-serif;
        letter-spacing: -0.2px;
      }

      &-text {
        font-size: 14px;
        color: $color-font-gray;
        white-space: pre-wrap;
      }
    }
  }

  .modal-body {
    padding: 0;
  }

  .cookie-preview {
    background: url("../../images/Settings/cookie-customization.svg") no-repeat
      fixed;
    background-position: center 77px;
    background-size: cover;
    height: calc(100vh - 77px);
  }

  &__page-container {
    height: calc(100vh - 90px);
    overflow: auto;
  }

  &__dpo {
    &-avatar {
      box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
      height: 160px;
      width: 160px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;

      @include screen-md {
        top: 20px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-name {
      font-family: "Bookmania Semibold", "Roboto", sans-serif;
      letter-spacing: -0.2px;
      font-size: 28px;
      font-weight: 600;
      margin-top: 54px;
      margin-bottom: 24px;
    }

    &-description {
      font-size: 14px;
      color: $color-font-gray;

      a {
        color: $color-brand-1;

        &:hover {
          color: $color-brand-1;
        }
      }
    }

    &-request {
      color: $color-brand-1;
    }
  }

  &__container {
    height: calc(100vh - 90px);
    overflow-y: auto !important;
    overflow-x: hidden !important;
    margin-top: 72px;
  }

  &__banner {
    height: 307px;
    background: $color-lightest-gray;

    &-title {
      font-family: "Bookmania Semibold", "Roboto", sans-serif;
      margin-bottom: 24px;
    }

    &-text {
      font-size: 14px;
      color: $color-font-gray;
    }
  }

  &__cookie-close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__cookie-popup {
    position: fixed;

    .customization-cookie__small {
      margin-bottom: 48px !important;

      .customization-cookie__popup-title,
      .customization-cookie__popup-text {
        text-align: left;
        width: 100%;
      }
    }

    &.-is-white,
    &.-is-gray {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 10;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }

    &.-is-white {
      background-color: rgba(255, 255, 255, 0.73);

      .customization-cookie__overlay {
        width: 100%;

        .customization-cookie__get {
          text-align: right;
        }
      }
    }

    &.-is-gray {
      background-color: rgba(0, 0, 0, 0.73);

      .customization-cookie__overlay {
        width: 100%;

        .customization-cookie__get {
          text-align: right;
        }
      }
    }

    &.-is-left {
      left: 48px;
      bottom: 0;
    }

    &.-is-right {
      right: 48px;
      bottom: 0;
    }

    &.-is-bottom {
      bottom: 0;
      width: 100%;
      position: fixed;

      .customization-cookie__vertical {
        max-width: 100% !important;

        .customization-cookie__get {
          text-align: right;
        }
      }

      .customization-cookie__popup-text {
        max-width: 624px;
      }
    }

    &.-is-top {
      top: 78px;
      width: 100%;

      .customization-cookie__vertical {
        max-width: 100% !important;

        .customization-cookie__get {
          text-align: right;
        }
      }

      .customization-cookie__popup-text {
        max-width: 624px;
      }
    }
  }

  .btn-round {
    border-radius: 30px !important;
  }

  &__container {
    background: $color-light-gray;
    height: calc(100vh - 77px);
    overflow: hidden;

    &.-has-overflow {
      overflow: auto;
    }
  }

  &__header {
    box-shadow: 2px 4px 16px 0px rgba(51, 51, 51, 0.08);
    height: 77px;
    display: flex;
    background: rgb(176, 186, 201);
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__close-btn {
    position: absolute;
    right: 91px;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;

    @include screen-xs {
      right: 10px;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
    color: #fff;
  }

  &__welcome-section {
    height: 430px;
    background: $color-lightest-gray;
  }

  &__info {
    &-privacy {
      background: #fff;
      border-radius: 4px;
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      min-height: 258px;
      max-width: 219px !important;
      margin-right: 172px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;

      &:last-child {
        margin-right: 0;
      }

      svg {
        margin-top: 32px;
        min-height: 31px;
        margin-bottom: 25px;
      }

      @include screen-md {
        margin-right: 20px;
        margin-top: 30px;
      }

      @include screen-xs {
        margin-right: 10px;
        margin-left: 10px;
      }
    }

    &-subtitle {
      font-family: "Bookmania Semibold", "Roboto", sans-serif;
      font-size: 28px;
      letter-spacing: -0.2px;
      text-align: center;
      margin-top: 90px;
      margin-bottom: 24px;
      white-space: pre-wrap;
    }

    &-details {
      font-size: 18px;
      color: $color-font-gray;
      max-width: 695px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 67px;
      padding-left: 10px;
      padding-right: 10px;
      white-space: pre-wrap;

      &-item {
        width: 163px;
        text-align: center;
        margin-left: 72px;
        margin-right: 72px;
      }
    }

    &-title {
      font-size: 18px;
      font-weight: 600;
      color: $color-brand-1;
      margin-bottom: 24px;
      white-space: pre-wrap;
    }

    &-text {
      font-size: 16px;
      color: $color-font-gray;
      margin-bottom: 21px;
      white-space: pre-wrap;
    }

    &-more {
      font-size: 14px;
      font-weight: bold;
      color: $color-brand-1;
      white-space: pre-wrap;
    }

    &-content {
      justify-content: center;
      top: -140px;
      position: relative;
      margin-bottom: -140px;
    }
  }

  &__welcome {
    &-title {
      font-size: 56px;
      font-weight: 600;
      font-family: "Bookmania Semibold", "Roboto", sans-serif;
      letter-spacing: -0.54px;
      text-align: center;
      padding-top: 70px;
      margin-bottom: 24px;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      white-space: pre-wrap;
    }

    &-text {
      font-size: 14px;
      color: $color-font-gray;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      padding-left: 10px;
      padding-right: 10px;
      white-space: pre-wrap;
      width: 90%;
    }
  }
}

.modals {
  background: fff;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.modal-backdrop {
  background-color: rgba(244, 246, 252, 0.73) !important;
  opacity: 1 !important;
}

.modal-content {
  height: 100%;
  border: none !important;
  border-radius: 4px;
}

.breach-sent-email {
  background: #fff;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  height: 378px !important;
  max-width: 693px !important;
  width: 693px !important;

  .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.modal-edit-privacy-category {
  background: #fff;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  height: 635px;
  max-width: 897px !important;
  width: 100% !important;

  &__content {
    width: 100%;
    height: 100%;
    padding-left: 88px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    max-height: 635px;

    @include screen-sm {
      padding-left: 15px;
    }

    .check {
      margin-left: 0;
    }

    &-check {
      border-radius: 4px;
      border: 1px solid $color-font-light;
      display: block;
      min-height: 13px;
      min-width: 13px;

      &.-is-active {
        background: $color-brand-1;
      }

      &-label {
        font-size: 14px;
        color: $color-font-dark;
        margin-right: 48px;
        width: 89px;
        margin-bottom: 24px;

        input {
          height: 38px !important;
          min-width: 325px !important;
          border-radius: 5px !important;
          border: 1px solid $color-font-light !important;

          @include screen-sm {
            min-width: 300px !important;
          }
        }
      }
    }

    &-close {
      margin-left: auto;
      display: block;
      margin-top: 16px;
      margin-right: 16px;
      cursor: pointer;
    }

    &-text {
      font-size: 14px;
      color: $color-font-dark;
    }

    &-parties {
      font-size: 14px;
      margin-bottom: 16px;

      &-item {
        font-size: 14px;
        color: $color-font-gray;
      }
    }

    &-btn {
      height: 36px !important;
      min-width: 136px !important;
      padding: 0 !important;
      display: flex;
      align-items: center;
    }

    &-btns {
      margin-bottom: 24px;
      justify-content: flex-end;
      margin-right: 24px;

      .btn {
        height: 36px !important;
        width: 136px !important;

        &:first-child {
          margin-right: 16px;
        }
      }
    }

    &-title {
      font-size: 18px;
      font-weight: 600;
      color: $color-brand-1;
      margin-bottom: 24px;
    }

    &-subtitle {
      font-size: 14px;
      color: $color-font-dark;
      margin-bottom: 24px;
      max-width: 355px;
    }
  }

  &__data {
    min-width: 364px;
    background: $color-lightest-gray;
    height: 100%;
    padding-left: 42px;
    padding-right: 42px;

    @include screen-sm {
      display: none;
    }

    &-title {
      margin-top: 48px;
      font-family: "Bookmania Semibold", "Roboto", sans-serif;
      font-size: 22px;
      letter-spacing: -0.16px;
      margin-bottom: 8px;
    }

    &-text {
      font-size: 14px;
      color: $color-font-dark;
      margin-bottom: 40px;
    }

    &-item {
      height: 59px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;
      color: $color-font-dark;
      padding-left: 16px;
      padding-right: 16px;
      margin-bottom: 16px;
      cursor: pointer;

      &:hover {
        background: #fff;
        border-radius: 4px;
        box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      }

      &.-is-active {
        background: #fff;
        border-radius: 4px;
        box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .modal-body {
    padding: 0;
    display: flex;
    flex-direction: row;
  }
}

.modal-suggested-action {
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  min-height: 267px !important;
  max-width: 864px !important;

  @include screen-md {
    max-width: 90% !important;
  }

  &__message {
    margin-top: 62px;
    margin-bottom: 0;
    max-width: 77%;
    text-align: center;
    font-size: 14px;
    color: $color-font-base;
    margin-left: auto;
    margin-right: auto;
  }

  &__link {
    font-size: 14px;
    font-weight: bold;
    color: $color-brand-1;
    margin-top: 37px;
    display: flex;
    justify-content: center;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.publish-notification {
  background: rgb(255, 255, 255);
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  min-height: 429px;
  max-width: 693px !important;
  width: 100% !important;

  .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.modal-suppliers {
  background: rgb(255, 255, 255);
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  min-height: 710px;
  min-width: 1219px;
  padding-top: 16px;
  padding-left: 35px;
  padding-right: 35px;

  &.-is-empty {
    min-height: 400px;
  }

  @include screen-md {
    min-width: 90% !important;
  }

  &__text {
    text-align: center;
    font-size: 14px;
    color: $color-font-gray;
  }

  .modal-body {
    padding: 0;
  }

  &__container {
    padding-left: 12px;
    padding-right: 12px;

    @include screen-sm {
      flex-wrap: wrap;
      max-height: 300px;
      overflow: scroll;
      padding-top: 44px;
    }
  }

  &__cancel {
    background: #fff !important;
    border-radius: 4px;
    border: 1px solid $color-brand-1 !important;
    font-size: 14px !important;
    font-weight: bold !important;
    height: 36px;
    width: 136px;
    color: $color-brand-1 !important;
    margin-right: 16px;
  }

  &__select {
    background: $color-brand-1 !important;
    border-radius: 4px;
    border: none !important;
    font-size: 14px !important;
    font-weight: bold !important;
    height: 36px;
    min-width: 136px;
    color: #fff !important;
    margin-right: 16px;
  }

  &__btns {
    margin-top: 98px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .input-group {
    height: 52px;
    width: 402px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 69px;
  }

  &__label {
    font-size: 18px;
    font-weight: 600;
    margin-top: 58px;
    text-align: center;
    margin-bottom: 0;
    margin-bottom: 24px;
  }

  &__link {
    font-size: 14px;
    font-weight: bold;
    color: $color-brand-1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;

    &:hover {
      color: $color-brand-1;
    }
  }

  &__supply {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translate(-50%);
    background: #fff;
    border-radius: 10px;
    box-shadow: 2px 4px 16px 0px rgba(51, 51, 51, 0.08);
    height: 103px;
    width: 254px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 90px;
      object-fit: cover;
    }

    &-placeholder {
      height: 90px;
      width: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #dfe4ee;
      font-size: 20px;
    }
  }

  &__supplies {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 14px;
    height: 327px;
    width: 302px;
    border: 2px solid transparent;
    cursor: pointer;

    &.-is-selected {
      border: 2px solid rgba(116, 80, 200, 0.3);
    }

    @include screen-sm {
      margin-bottom: 65px;
    }

    &-btns {
      margin-bottom: 30px;
    }
  }

  input {
    height: 52px !important;
    border: none !important;
    border-radius: 4px;

    &:focus {
      outline: none;
      box-shadow: none;
      border: none !important;
    }
  }

  &__addon {
    background: $color-brand-1 !important;
    min-width: 80px;
    border-radius: 4px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.modal-feedback {
  background: #fff;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  max-width: 693px !important;
  width: 693px !important;
  min-height: 440px;
  border-radius: 4px;

  @include screen-md {
    max-width: 90% !important;
  }

  &__message {
    font-size: 14px;
    color: $color-font-gray;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 32px;
  }

  textarea {
    border-radius: 5px;
    border: 1px solid $color-font-light;
    height: 221px !important;
    width: 568px;
    max-width: 568px;
    resize: none;
    margin-bottom: 24px;

    &:focus {
      box-shadow: none;
      border-color: $color-font-light;
    }

    &::placeholder {
      font-size: 14px;
      color: $color-font-light;
    }
  }

  &__img {
    margin-top: 43px;
  }

  &__title {
    margin-top: 47px;
    font-size: 22px;
    font-weight: bold;
    color: $color-brand-1;
    margin-bottom: 24px;
  }

  &__thanks {
    font-size: 14px;
    color: $color-font-gray;
    margin-bottom: 29px;
  }

  &__link {
    font-size: 14px;
    font-weight: bold;
    color: $color-brand-1;

    &:hover {
      color: $color-brand-1;
    }
  }

  &__btns {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  &__send,
  &__cancel {
    border-radius: 4px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    height: 36px;
    width: 136px;
  }

  &__cancel {
    margin-right: 16px;
    border: 1px solid $color-brand-1 !important;
    color: $color-brand-1 !important;
    background: #fff !important;
  }

  &__send {
    border: 1px solid $color-brand-1 !important;
    color: #fff !important;
    background: $color-brand-1 !important;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.notify-legalsite {
  min-height: 279px;
  min-width: 750px !important;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);

  @include screen-xs {
    min-width: 95% !important;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__text {
    font-size: 14px;
    color: $color-font-gray;
    width: 80%;
    margin-top: 16px;
    margin-bottom: 32px;
    text-align: center;
  }
}

.publish-legalsite {
  min-height: 479px;
  min-width: 750px !important;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);

  @include screen-sm {
    min-width: 90% !important;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img {
    width: 178px;
  }

  &__btn {
    margin-bottom: 60px;
    min-width: 136px !important;
  }
}

.discard-changes,
.delete-modal {
  background: #fff;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  min-height: 251px;
  max-width: 693px !important;
  width: 100% !important;

  &__input {
    height: 121px !important;
    width: 568px !important;
    margin-top: 24px;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__btns {
    margin-top: 48px;
    display: flex;
    justify-content: space-around;

    .btn {
      width: 136px;
      padding: 0;
      margin-left: 12px;
      margin-right: 12px;
    }
  }
}

.edit-page-details {
  background: #fff;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  min-height: 408px;
  max-width: 693px !important;
  width: 100% !important;

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__form {
    margin-top: 32px;

    input {
      width: 325px;
    }
  }

  &__btns {
    margin-top: 32px;
    display: flex;
    margin-bottom: 16px;
    justify-content: space-around;

    .btn {
      width: 136px;
    }
  }
}

.modal-assign {
  background: #fff;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  height: 378px;
  max-width: 693px !important;
  border-radius: 4px;

  @include screen-sm {
    min-height: 478px;
  }

  &__text {
    margin-top: 42px;
    font-size: 14px;
    text-align: center;
    max-width: 423px;
    color: $color-dark-gray;
    margin-bottom: 40px;
  }

  &__feedback {
    &-faces {
      width: 282px;
      margin-top: 38px;
    }

    &-face {
      cursor: pointer;
      height: 125px;
      width: 105px;
      display: flex;

      &.-is-selected {
        border-radius: 4px;
        border: 2px solid rgb(173, 156, 212);
      }

      &.-is-grayed-out {
        filter: grayscale(100%);
      }
    }

    &-form {
      max-width: 325px;
    }

    &-label {
      font-size: 14px;
      font-weight: bold;
    }
  }

  @include screen-md {
    max-width: 90% !important;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__close {
    display: flex;
    float: right;
    cursor: pointer;
    color: $color-font-gray;
    margin-left: auto;
  }

  &__image {
    margin-top: 31px;
    width: 119px;
    height: 119px;
  }

  &__title {
    margin-top: 11px;
    font-size: 22px;
    font-weight: bold;
    color: $color-brand-1;
    text-align: center;
    white-space: nowrap;
    margin-bottom: 0;

    @include screen-sm {
      white-space: pre-wrap;
    }
  }

  &__message {
    font-size: 14px;
    font-weight: normal;
    color: $color-font-gray;
    width: 43%;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 0;

    @include screen-sm {
      width: 90%;
    }
  }

  &__link {
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
    color: $color-brand-1;

    &:hover {
      color: $color-brand-1;
    }
  }
}

.modal-consent {
  background: #fff;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  height: 378px;
  min-width: 693px !important;
  border-radius: 4px;

  @include screen-md {
    max-width: 90% !important;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.modal-add-user {
  background: rgb(255, 255, 255);
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  min-height: 548px;
  min-width: 693px;

  @include screen-sm {
    min-width: 90%;
    overflow: auto;
    max-height: 95%;
    min-height: 548px;

    form {
      display: flex;
      flex-direction: column;
    }
  }

  .avatar-error {
    position: absolute;
    bottom: -50px;
    font-size: 80%;
    font-weight: 400;
  }

  .show-upload {
    width: 63px;
    height: 63px;
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
    top: 0;
  }

  @include screen-md {
    max-width: 90% !important;
  }

  &__avatar-container {
    box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
    height: 63px;
    width: 63px;
    display: block;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__input-icon {
    right: 5px;
    top: 13px;
  }

  select.form-control {
    appearance: none;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    font-size: 22px;
    font-weight: bold;
    color: $color-brand-1;
    margin-bottom: 40px;
  }

  &__placeholder {
    background: $color-font-light;
    box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
    border-radius: 50%;
    height: 63px;
    width: 63px;
    margin-bottom: 8px;
  }

  &__avatar {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1.5px;
    color: $color-font-gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 41px;
    position: absolute;
    left: 60px;

    @include screen-sm {
      position: relative;
      margin-right: 0;
      left: 0;
    }

    &.-change {
      left: 40px;
    }
  }

  &__form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .form-group {
    margin-bottom: 0;

    @include screen-sm {
      width: 100%;
    }
  }

  input,
  select {
    width: 325px;
    margin-bottom: 24px;
    font-size: 14px;

    @include screen-sm {
      max-width: 325px;
      width: 100%;
    }
  }

  select {
    color: $color-font-light;
  }

  &__btns {
    margin-top: 2px;
    margin-bottom: 16px;
  }

  &__cancel,
  &__add {
    width: 136px !important;

    @include screen-sm {
      width: 106px !important;
    }
  }

  &__cancel {
    margin-right: 16px;
  }
}

.modal-add-category {
  background: rgb(255, 255, 255);
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  max-width: 897px !important;
  height: 348px;
  width: 100% !important;

  .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .form-group {
    margin-bottom: 67px;
    margin-top: 67px;
  }
}

.modal-edit-cookies {
  min-width: 897px !important;
  overflow: scroll;

  .modal-body {
    padding: 0;
    max-height: calc(100vh - 200px);
  }

  .modal-content {
    height: auto !important;
  }

  &__small-description {
    width: 568px !important;
  }

  &__dp {
    width: 100%;

    &-title {
      font-size: 16px;
      font-weight: bold;
      color: #7450c8;
      margin-bottom: 24px;
    }

    &-placeholder {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: #dfe4ee;
      display: block;
      margin-right: 10px;
    }

    &-logo {
      max-height: 30px;
      height: 30px;
      max-width: 60px;
      object-fit: contain;
      margin-right: 5px;
    }

    .ReactTable {
      border: none;
      margin-bottom: 70px;

      .rt-thead {
        box-shadow: none;
      }

      .rt-th {
        border: none;
        text-align: left;
        padding: 0;
        margin-bottom: 23px;
      }

      .rt-td {
        padding-left: 0;
      }
    }
  }

  .check {
    border-radius: 4px;
    border: 1px solid $color-font-light;
    min-height: 13px;
    min-width: 13px;
    height: 13px;
    width: 13px;
    display: block;
    margin-right: 8px;
    margin-left: 0;

    &.-is-checked {
      background: $color-brand-1;
    }
  }

  &__consent {
    margin-left: 32px;
    font-size: 14px;
    color: $color-font-gray;
  }

  &__consent-label {
    font-size: 14px;
    color: $color-font-gray;
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 122px;
    background: $color-lightest-gray;
    width: 100%;
    padding: 16px;
  }

  .modal-assign__close {
    min-height: 25px;
    margin-top: 16px;
  }

  &__title {
    font-family: "Bookmania Semibold", "Roboto", sans-serif;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.16px;
    margin-bottom: 8px;
  }

  &__text {
    font-size: 14px;
    color: #292e31;
  }

  &__content {
    padding-top: 48px;
    max-height: 464px;
    overflow: scroll;
    width: 100%;
    padding-left: 165px;
    padding-right: 165px;
  }

  &__form {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    input {
      margin-bottom: 24px;
    }

    textarea {
      min-height: 221px;
      resize: none;
      margin-bottom: 24px;
      font-size: 14px;
    }
  }

  &__name {
    width: 325px !important;
    margin-left: auto;
    margin-right: auto;
  }

  &__cancel,
  &__save {
    width: 136px !important;
  }

  &__cancel {
    margin-right: 16px;
  }
}
