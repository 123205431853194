@import "../../styles/utils/mixins";
@import "../../styles/utils/_variables";

@font-face {
  font-family: "Bookmania Semibold";
  src: url("../../styles/fonts/bookmania-semibold.otf") format("opentype");
}

.lawyers {
  height: calc(100vh - 90px);
  overflow: scroll;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 48px;

  &__actions {
    background: rgba(255, 255, 255, 0);
    box-shadow: 0px 1px 0px 0px rgb(234, 236, 238);
    height: 60px;
    align-items: center;
    display: flex;
  }

  &__action {
    font-size: 14px;
    font-weight: bold;
    color: $color-font-gray;
    display: flex;
    align-items: center;
    margin-right: 57px;
    cursor: pointer;
  }

  @include screen-sm {
    height: calc(100vh - 53px);
  }

  &__header {
    font-size: 22px;
    font-family: "Bookmania Semibold", "Roboto", sans-serif;
    margin-bottom: 8px;
  }

  &__text {
    font-size: 14px;
    color: $color-font-gray;
    margin-bottom: 32px;
  }

  input {
    width: 245px !important;
  }

  .users__table {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .input-group {
    max-width: 445px !important;
    flex-wrap: nowrap;
    width: 100%;
  }

  &__table-content {
    min-width: 813px;
    width: 100%;
  }

  &__view {
    color: $color-brand-1;
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  }

  &__close {
    position: absolute;
    top: 21px;
    right: 0;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;

    @include screen-sm {
      right: 10px;
    }
  }
}

.lawyer-view {
  height: calc(100vh - 90px);
  overflow: scroll;
  padding-top: 48px;
  padding-bottom: 36px;
  position: relative;

  @include screen-sm {
    height: calc(100vh - 53px);
  }

  .users__table {
    box-shadow: none !important;
  }

  tr {
    border-top: 1px solid #8697a6;
    padding-top: 35px;
  }

  th {
    color: #8697a6;
    padding-bottom: 12px !important;
    padding-top: 40px !important;
  }

  tbody {
    td {
      padding-top: 29px;
      padding-bottom: 29px;
      color: #373737;
      font-size: 14px;
      font-weight: bold;
    }
  }

  &__close {
    position: absolute;
    top: 21px;
    right: 0;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;

    @include screen-sm {
      right: 10px;
    }
  }

  &__title {
    font-size: 22px;
    font-family: "Bookmania Semibold", "Roboto", sans-serif;
    letter-spacing: -0.16px;
  }

  &__container {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    min-height: 416px;
    margin-top: 25px;
    padding: 24px 32px;
  }

  &__info {
    font-size: 18px;
    font-weight: 600;
    color: $color-brand-1;
    margin-bottom: 24px;

    &-text {
      font-size: 16px;
      color: $color-font-gray;
      margin-bottom: 24px;
    }
  }

  &__avatar {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: $color-font-gray;
    display: flex;
    margin-right: 24px;

    @include screen-xs {
      margin-bottom: 24px;
    }
  }

  form {
    width: 325px;
    margin-bottom: 24px;

    @include screen-xs {
      width: 100%;
    }
  }

  &__form {
    margin-right: 100px;

    @include screen-xs {
      margin-right: 0;
    }
  }

  &__download,
  &__delete {
    font-size: 14px;
    font-weight: bold;
    color: $color-brand-1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__download {
    margin-right: 24px;
  }

  &__bank {
    background: #fff;
    border: 1px solid $color-brand-1;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    min-height: 87px;
    width: 360px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    padding: 21px 16px;

    @include screen-xs {
      width: 100%;
      flex-wrap: wrap;
    }

    &-name {
      font-size: 14px;
      color: $color-font-gray;
      margin-bottom: 8px;

      @include screen-xs {
        margin-top: 8px;
      }
    }

    &-code {
      font-size: 14px;
      color: $color-font-gray;
      margin-bottom: 0px;

      @include screen-xs {
        margin-bottom: 8px;
      }
    }
  }

  input {
    margin-bottom: 24px;
  }

  &__label {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  &__view {
    display: flex;
    align-items: center;
    color: $color-brand-1;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  &__added {
    font-size: 18px;
    font-weight: 600;
    color: $color-brand-1;
    margin-bottom: 24px;
  }

  &__date {
    font-size: 14px;
    font-weight: bold;
    color: $color-font-gray;
  }

  &__details {
    @include screen-xs {
      flex-direction: column;
      width: 100%;
    }
  }

  &__btn {
    display: flex;
    align-items: flex-end;

    .btn {
      width: 136px !important;
    }
  }

  &__invoices {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    max-height: 795px;
    margin-top: 32px;
  }
}
