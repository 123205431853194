@import "../../styles/utils/mixins";
@import "../../styles/utils/variables";

@font-face {
  font-family: "Bookmania Semibold";
  src: url("../../styles/fonts/bookmania-semibold.otf") format("opentype");
}

.pages-card {
  background: #fff;
  border-radius: 4px;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  min-height: 461px;
  margin-top: 32px;
  padding-top: 24px;
  padding-left: 24px !important;
  margin-bottom: 8px;

  @include screen-sm {
    padding-left: 15px !important;
  }

  &__title {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.16px;
    font-family: "Bookmania Semibold", "Roboto", sans-serif;
  }

  &__text {
    font-size: 16px;
    color: $color-font-gray;
    margin-bottom: 16px;
  }

  &__add {
    width: 136px !important;
    margin-bottom: 24px;
    background: rgb(116, 80, 200);
    border-radius: 4px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      text-decoration: none;
    }
  }

  &__item {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    min-height: 121px;
    max-width: 369px !important;
    min-width: 369px !important;
    margin-right: 32px;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    padding: 16px 24px;
    justify-content: space-between;

    @include screen-sm {
      width: 100% !important;
      min-width: 100% !important;
    }

    &-title {
      font-size: 18px;
      margin-bottom: 8px;
      font-weight: 600;
    }

    &-update {
      font-size: 14px;
      margin-bottom: 8px;
      color: $color-font-gray;
    }

    &-edit {
      color: $color-brand-1;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0;

      &:hover {
        color: $color-brand-1;
        text-decoration: none;
      }
    }
  }

  &__items {
    padding-left: 15px;
    padding-right: 10px;
  }
}

.dashboard__container {
  max-height: calc(100vh - 90px);
  height: 100%;
  overflow: scroll;
}

.introduction-step3 {
  position: absolute;
  background: $color-brand-1;
  border-radius: 4px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  height: 221px;
  width: 278px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 91px;
  top: 245px;
  z-index: 9;

  &:before {
    content: " ";
    position: absolute;
    left: -7px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    width: 14px;
    height: 14px;
    background-color: $color-brand-1;
    transform: rotate(140deg);
  }
}

.introduction-step4 {
  position: absolute;
  background: $color-brand-1;
  border-radius: 4px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  height: 221px;
  width: 278px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 91px;
  top: 100px;
  z-index: 1999;

  &:before {
    content: " ";
    position: absolute;
    left: -7px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    width: 14px;
    height: 14px;
    background-color: $color-brand-1;
    transform: rotate(140deg);
  }
}

.introduction-step6 {
  position: absolute;
  background: $color-brand-1;
  border-radius: 4px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  min-height: 64px;
  width: 278px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  right: 130px;
  top: 400px;
  z-index: 9999;

  &:before {
    content: " ";
    position: absolute;
    left: 0px;
    right: 0px;
    top: -8px;
    margin-left: auto;
    margin-right: auto;
    width: 14px;
    height: 14px;
    background-color: $color-brand-1;
    transform: rotate(135deg);
  }

  @include screen-sm {
    top: 380px;
    right: 100px;
  }

  @include screen-xs {
    top: 420px;
    right: 80px;
  }
}

.introduction-navbar__dropdown.dropdown-menu {
  display: block;
  top: 64px;
  height: 331px;
  right: 71px;
  margin-left: auto;
  width: 272px;
  background: #333;
  padding-bottom: 0;
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.5);
  z-index: 9999;
  pointer-events: none;

  .dropdown-divider {
    margin-bottom: 0;
  }

  &:before {
    position: absolute;
    top: -7px;
    right: 9px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #333;
    border-left: 7px solid transparent;
    border-bottom-color: #333;
    content: "";
  }

  @include screen-sm {
    right: 10px;
    top: 41px;
  }

  @include screen-xs {
    top: 89px;
    right: 38px;
  }
}

.introduction-step5 {
  position: absolute;
  background: $color-brand-1;
  border-radius: 4px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  height: 221px;
  width: 278px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 91px;
  bottom: 162px;
  z-index: 9;

  &:before {
    content: " ";
    position: absolute;
    left: 3px;
    bottom: -6px;
    margin-bottom: auto;
    width: 14px;
    height: 14px;
    background-color: $color-brand-1;
    transform: rotate(140deg);
  }
}

.introduction-step1 {
  position: absolute;
  background: $color-brand-1;
  border-radius: 4px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  height: 221px;
  width: 278px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 5px;
  z-index: 999;

  &:before {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: -5px;
    width: 14px;
    height: 14px;
    background-color: $color-brand-1;
    transform: rotate(135deg);
  }
}

.introduction-step2 {
  position: absolute;
  background: $color-brand-1;
  border-radius: 4px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  height: 221px;
  width: 278px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 91px;
  top: 175px;
  z-index: 9;

  &:before {
    content: " ";
    position: absolute;
    left: -7px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    width: 14px;
    height: 14px;
    background-color: $color-brand-1;
    transform: rotate(140deg);
  }
}

.info {
  padding-top: 30px !important;

  &.-has-banner {
    height: calc(100vh - 185px);

    @include screen-sm {
      height: calc(100vh - 147px);
    }
  }

  &__suppliers {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    min-height: 174px;
    max-width: 593px;
    width: 49%;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;

    @include screen-xs {
      width: 100%;
    }

    &-title {
      font-size: 22px;
      font-family: "Bookmania Semibold", "Roboto", sans-serif;
      margin-bottom: 8px;
    }

    &-btns {
      display: flex;

      @include screen-md {
        flex-wrap: wrap;
      }
    }

    &-text {
      font-size: 14px;
      color: $color-font-gray;
    }

    &-manage {
      min-width: 136px !important;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        text-decoration: none;
      }
    }

    &-container {
      margin-top: 32px;
      display: flex;
      justify-content: space-between;
      padding: 0 !important;

      @include screen-xs {
        flex-direction: column;
      }
    }
  }

  &__container-dashboard {
    flex-direction: row;

    @include screen-md {
      flex-direction: column;
    }

    @include screen-sm {
      padding-left: 0 !important;
    }
  }

  &__step1 {
    position: absolute;
    background: $color-brand-1;
    border-radius: 4px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
    height: 221px;
    width: 278px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    left: 19px;
    top: 19px;

    &:before {
      content: " ";
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      bottom: -7px;
      width: 14px;
      height: 14px;
      background-color: $color-brand-1;
      transform: rotate(45deg);
    }

    &-close {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }

    &-title {
      font-size: 18px;
      font-weight: bold;
      color: #fff;
      margin-bottom: 16px;
    }

    &-text {
      font-size: 12px;
      color: #fff;
    }

    &-next {
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      text-align: right;
      cursor: pointer;
    }
  }

  &__alert {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      &:hover {
        text-decoration: none;
      }
    }

    .close {
      color: $color-brand-5 !important;
      opacity: 1;
    }

    &-action {
      font-size: 14px;
      font-weight: 600;
      color: $color-brand-5;
      margin-left: 11px;
      margin-right: 16px;
    }

    &-text {
      font-size: 14px;
      color: $color-font-dark;
      margin-right: 32px;
    }

    &-review {
      font-size: 14px;
      font-weight: 600;
      color: $color-brand-5;
      margin-right: 9px;
    }
  }

  &__overview {
    margin-top: 15px;
  }

  &__stats-container {
    justify-content: center !important;
  }

  &__legal {
    margin-top: 15px;

    @include screen-md {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  &-empty {
    &__container {
      min-height: 469px;
      background: #fff;
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      margin: 32px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 27px;
      max-width: 909px;
      position: relative;
    }

    &__show-menu {
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    &__header {
      margin-bottom: 53px;
      font-weight: 600;
      color: $color-font-base;
      font-family: "Bookmania Semibold", "Roboto", sans-serif;

      @include screen-sm {
        margin-bottom: 20px;
      }
    }

    &__images {
      position: relative;
      margin-bottom: 66px;
    }

    &__image2,
    &__image3,
    &__image4 {
      position: absolute;
    }

    &__image2 {
      bottom: -30px;
      left: -30px;
    }

    &__image3 {
      right: 20px;
    }

    &__image4 {
      top: 50px;
      right: 3px;
    }

    &__text {
      color: $color-brand-1;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      margin-bottom: 24px;
      text-align: center;
    }

    &__secondary-text {
      font-size: 16px;
      color: $color-font-gray;
      letter-spacing: 0;
      text-align: center;
    }

    &__column-container {
      display: flex;
      justify-content: space-between;
      max-width: 909px;
      margin-left: auto;
      margin-right: auto;

      @include screen-md {
        flex-direction: column;
        align-items: center;
      }
    }

    &__column {
      height: 226px;
      width: 219px;
      border-radius: 4px;
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      background: #fff;
      padding: 27px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      @include screen-md {
        margin-bottom: 20px;
      }

      &-text {
        color: $color-brand-1;
        font-weight: 500;
        font-size: 18px;
        margin-top: 36px;
      }

      &-text-secondary {
        margin-top: 18px;
        font-size: 14px;
        color: $color-font-gray;
      }
    }
  }
}

.select-plan {
  .horizontal-slider {
    height: 12px;
    background: #573c96;
    border-radius: 10px;
    margin-top: 20px;
  }

  .example-track-0 {
    background-color: #fff;
    box-shadow: none;
    border-radius: 6.62px;
    height: 12px;
  }

  .example-thumb {
    background: #fff;
    border: 2px solid #f4f6fc;
    box-shadow: 2px 4px 16px 0 rgba(116, 80, 200, 0.08);
    height: 24px;
    width: 24px;
    border-radius: 50%;
    top: -6px;
    outline: none;
    cursor: pointer;
  }

  u {
    cursor: help;
  }

  &__logo {
    padding-top: 32px;
    margin-left: 54px;
  }

  &__header {
    background: #f4f6fc;
    height: 304px;
  }

  &__title {
    font-family: "Bookmania Semibold", "Roboto", sans-serif;
    text-align: center;
    margin-top: 40px;
  }

  .slider {
    max-width: 608px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 52px;
    margin-bottom: 52px;
    margin-bottom: 56px;

    .rangeslider__fill {
      background-color: #fff;
      box-shadow: none;
      border-radius: 6.62px;
    }

    .rangeslider__handle {
      background: #fff;
      border: 2px solid rgb(244, 246, 252);
      box-shadow: 2px 4px 16px 0px rgba(116, 80, 200, 0.08);
      height: 24px;
      width: 24px;

      &:focus {
        outline: none;
      }

      &:after {
        display: none;
      }
    }

    .rangeslider {
      box-shadow: none;
      background: #573c96;
    }

    .value {
      font-size: 18px;
      color: $color-font-dark;
      text-align: center;
    }
  }

  &__questions {
    font-family: "Bookmania Semibold", "Roboto", sans-serif;
    font-size: 44px;
    margin-top: 103px;
    text-align: center;
  }

  &__message {
    text-align: center;
    font-size: 18px;
  }

  &__plans {
    margin-top: -80px;
    &-add {
      background: #fff;
      border-radius: 4px;
      box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
      min-height: 690px;
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;

      &-value {
        font-size: 22px;
        font-weight: bold;
        color: $color-brand-1;
      }

      &-subtitle {
        font-size: 22px;
        font-weight: bold;
        color: #fff;
        margin-bottom: 14px;
        text-align: center;
        margin-top: 75px;
      }

      &-content {
        font-size: 18px;
        text-align: center;
        margin-bottom: 31px;
        color: #fff;
      }

      &-total-value {
        font-size: 36px;
        font-weight: 600;
        font-family: "Bookmania Semibold", "Roboto", sans-serif;
        margin-left: 18px;
        margin-right: 7px;
      }

      &-monthly {
        font-size: 14px;
        font-weight: bold;
        color: $color-font-dark;
      }

      &-title {
        font-size: 28px;
        font-family: "Bookmania Semibold", "Roboto", sans-serif;
        letter-spacing: -0.38px;
        padding-top: 45px;
        text-align: center;
      }

      &-current {
        letter-spacing: -0.16px;
        font-weight: bold;
        font-size: 22px;
        color: $color-brand-1;
        text-align: center;
        margin-bottom: 16px;
      }

      &-text {
        font-size: 18px;
        color: $color-font-dark;
        text-align: center;
      }

      &-progress {
        .progress {
          max-width: 608px;
          margin-left: auto;
          margin-right: auto;
          margin-top: 37px;
          height: 13px;
          border-radius: 6.62px;
          background: #dfe4ee;
        }

        .progress-bar {
          border-radius: 6.62px;
          background: $color-brand-3;
        }
      }
    }

    &-business {
      background: linear-gradient(
        45deg,
        rgb(106, 69, 192) 0%,
        rgb(156, 120, 241) 100%
      );
      border-radius: 4px;
      box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
      min-height: 682px;
      max-width: 662px;
      margin-bottom: 48px;
    }

    &-free {
      background: rgb(255, 255, 255);
      border-radius: 4px;
      border: 1px solid rgb(116, 80, 200);
      height: 274px;
      max-width: 413px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 32px;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 69px;

      &-title {
        margin-top: 39px;
        letter-spacing: -0.16px;
        color: $color-brand-1;
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 31px;
      }

      &-text {
        font-size: 14px;
        color: $color-font-dark;
        margin-bottom: 33px;
      }
    }

    &-business {
      &-title {
        font-size: 28px;
        font-weight: bold;
        letter-spacing: -0.2px;
        color: #fff;
        text-align: center;
        margin-top: 53px;
        margin-bottom: 30px;
      }

      &-main {
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        margin-bottom: 10px;
      }

      &-secondary {
        font-size: 14px;
        color: #fff;
        font-weight: normal;
      }

      &-upgrade {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex: 1;
      }

      &-legal {
        flex: 1;
      }

      &-upgrade-amount {
        font-size: 44px;
        font-weight: 600;
        color: #fff;
        font-family: "Bookmania Semibold", "Roboto", sans-serif;
        margin-bottom: 0;
      }

      &-upgrade-month {
        color: #fff;
        font-size: 14px;
        font-weight: bold;
      }

      &-upgrade-subjects {
        font-size: 14px;
        color: #fff;
      }
    }

    &-badge {
      position: absolute;
      background: #fff;
      text-align: center;
      z-index: 1;
      height: 24px;
      width: 149px;
      display: flex;
      right: 0;
      top: 44px;
      align-items: center;

      p {
        position: relative;
        display: block;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0px;
        color: #7450c8;
        background: #fff;
        width: 140px;
        margin-bottom: 0;
      }

      &:before {
        content: "";
        border: 12px solid #fff;
        border-left-color: transparent;
        border-left-width: 14px;
        position: absolute;
        top: 0;
        left: -20px;
      }
    }
  }
}

.thank-you {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: scroll;

  &__logo {
    padding-top: 32px;
    margin-left: 54px;
  }

  &__container {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
    height: 629px;
    max-width: 847px !important;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    img {
      margin-bottom: 48px;
    }
  }

  &__title {
    font-size: 44px;
    font-family: "Bookmania Semibold", "Roboto", sans-serif;
    margin-bottom: 45px;
  }

  &__text {
    font-size: 16px;
    margin-bottom: 37px;
    text-align: center;
  }
}
