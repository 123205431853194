@import "../../styles/utils/mixins";
@import "../../styles/utils/_variables";

@font-face {
  font-family: "Bookmania Semibold";
  src: url("../../styles/fonts/bookmania-semibold.otf") format("opentype");
}

.compliance-free {
  height: calc(100vh - 90px);
  overflow: scroll;
  background: #fff;
  display: flex;

  @include screen-sm {
    height: calc(100vh - 53px);
    flex-direction: column-reverse;
  }

  &__banner {
    width: 30%;
    background: $color-brand-1;
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;

    @include screen-sm {
      width: 100%;
    }
  }

  &__img1 {
    max-width: 492px;
    position: relative;
    left: -80px;
    top: 180px;

    @include screen-sm {
      left: 0;
      top: 0;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: 100%;
      padding-bottom: 10px;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 70%;
    padding: 10px;

    @include screen-sm {
      left: 0;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      padding-bottom: 10px;
    }

    ul {
      padding: 0;
      li {
        list-style-type: none;
        font-size: 16px;
        color: $color-font-gray;
        display: flex;
        align-items: center;
        margin-bottom: 25px;
      }
    }

    &-upgrade {
      max-width: 136px;
    }

    &-title {
      font-family: "Bookmania Semibold", "Roboto", sans-serif;
      letter-spacing: -0.2px;
      font-size: 28px;
      margin-bottom: 24px;

      @include screen-sm {
        text-align: center;
      }
    }

    &-subtitle {
      font-size: 16px;
      max-width: 483px;
      color: $color-font-gray;
      margin-bottom: 67px;
      text-align: center;
    }

    &-icon {
      width: 20px;
      height: 20px;
      margin-right: 16px;
    }
  }
}

.compliance {
  height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  overflow: scroll;

  .rt-th {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
    border: none !important;
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    text-align: left;

    &:first-child {
      width: 65px !important;
    }

    &:last-child {
      width: 5px !important;
    }
  }

  .pagination-bottom {
    background: #fff;
  }

  .-pagination {
    box-shadow: none !important;
  }

  .rt-td {
    border: none !important;
    font-size: 14px;
    color: $color-font-dark;
    z-index: 0;
    padding: 12px !important;

    &:first-child {
      width: auto !important;
    }

    &:last-child {
      width: 5px !important;
    }
  }

  .-next,
  .-previous {
    height: 40px !important;
    width: 223px !important;
    border-radius: 4px !important;
    flex: none !important;

    .-btn {
      color: #fff !important;
      font-size: 14px !important;
      font-weight: bold !important;
      border-radius: 4px !important;
      height: 40px !important;
      width: 223px !important;
      background-color: $color-brand-1 !important;
    }
  }

  .rt-noData {
    width: 100%;
    background: #fff;
    text-align: center;
  }

  .rt-tbody {
    background: #fff;
  }

  .rt-tr-group {
    border: none !important;

    &:hover {
      background: $color-light-gray;
    }
  }

  &.-has-banner {
    height: calc(100vh - 155px);

    @include screen-sm {
      height: calc(100vh - 117px);
    }
  }

  @include screen-sm {
    height: calc(100vh - 53px);
  }

  &.-is-sample {
    height: calc(100vh - 142px);
    overflow: auto;
  }

  &__container {
    margin-left: 72px;
    height: calc(100vh - 90px);
    overflow: auto;

    @include screen-sm {
      height: calc(100vh - 53px);
      margin-left: 15px;
    }
  }

  &__subtitle {
    font-size: 14px;
    max-width: 658px;
    color: $color-font-gray;
    margin-top: 48px;
    margin-bottom: 48px;
  }

  &__item {
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    height: 285px;
    width: 382px;
    background: #fff;
    padding: 24px 10px 30px 56px;
    position: relative;
    margin-right: 32px;
    margin-bottom: 32px;

    &-title {
      font-size: 18px;
      font-weight: 600;
      margin-left: 8px;
      margin-bottom: 24px;

      &-container {
        margin-left: -33px;

        svg {
          max-width: 25px;
          width: 25px;
        }
      }
    }

    &-subtitle {
      font-size: 14px;
      color: $color-font-dark;
      margin-bottom: 24px;
    }

    &-text {
      font-size: 14px;
      color: $color-font-dark;
      margin-bottom: 24px;
    }

    &-done {
      font-size: 14px;
      font-weight: bold;
      color: $color-font-gray;
    }

    &-update {
      font-size: 12px;
      color: $color-font-gray;
      margin-left: 24px;
    }

    &-link {
      font-size: 14px;
      font-weight: bold;
      color: $color-brand-1;
    }

    &-link-container {
      position: absolute;
      bottom: 29px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.compliance-privacy {
  &__dropdown {
    width: 155px;
    position: relative;

    &.show {
      .compliance-privacy__toggle {
        border-top: 1px solid $color-lightest-gray !important;
        border-left: 1px solid $color-lightest-gray !important;
        border-right: 1px solid $color-lightest-gray !important;
      }
    }
  }

  &__back {
    font-size: 14px;
    font-weight: bold;
    color: $color-brand-1;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 72px;
    margin-bottom: -24px;
    position: relative;
    top: 15px;
  }

  &__container {
    @include screen-sm {
      flex-direction: column;
    }
  }

  &__categ {
    height: calc(100vh - 200px);
    overflow: scroll;

    @include screen-sm {
      height: 100%;
    }
  }

  &__add-text {
    margin-bottom: 0;
    color: $color-brand-1;
    font-size: 14px;
    font-weight: bold;
    margin-left: 9px;
    cursor: pointer;
    width: auto;
  }

  &__add {
    margin-left: 56px;
    margin-bottom: 24px;
  }

  &__title {
    font-size: 22px;
    font-weight: 600;
    font-family: "Bookmania Semibold", "Roboto", sans-serif;
    margin-top: 48px;
    margin-bottom: 16px;
    margin-left: 72px;

    @include screen-sm {
      margin-left: 15px;
    }
  }

  &__text {
    font-size: 14px;
    max-width: 549px;
    color: $color-font-dark;
    margin-left: 72px;

    &.-select {
      margin-left: 50px;

      @include screen-sm {
        margin-left: 0px;
      }
    }

    @include screen-sm {
      margin-left: 15px;
    }
  }

  &__items {
    margin-top: 48px;
    margin-left: 32px !important;
    margin-right: 0 !important;

    @include screen-sm {
      margin-left: 15px !important;
    }
  }

  &__item {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    min-height: 159px;
    max-width: 549px !important;
    padding: 0 !important;
    margin-bottom: 40px;
    margin-left: 36px;
    margin-right: 36px;

    &.-has-margin {
      margin-right: 72px;
    }

    @include screen-sm {
      margin-left: 0 !important;
    }

    &-title {
      margin-top: 24px;
      margin-left: 24px;
      font-size: 18px;
      font-weight: 600;
      color: $color-brand-1;
    }

    &-done {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: bold;
      color: $color-font-gray;
      margin-bottom: 8px;
      margin-left: 24px;
    }

    &-update {
      margin-bottom: 0;
      font-size: 12px;
      color: $color-font-gray;
      margin-left: 24px;
    }

    &-text {
      font-size: 14px;
      color: $color-font-dark;
      margin-left: 24px;
      margin-right: 24px;
      margin-bottom: 24px;
    }

    &-label {
      font-size: 14px;
      color: $color-font-dark;
      margin-left: 24px;
      margin-right: 16px;
    }
  }

  .dropdown-menu {
    border-top: none !important;
    width: 155px;
    max-width: 155px;
    min-width: 155px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: $color-lightest-gray;
    margin-top: 0;

    .dropdown-item {
      color: $color-font-gray;
      font-size: 14px;
    }
  }

  &__caret {
    margin-left: 10px;
  }

  &__toggle {
    background: #fff !important;
    color: $color-font-gray !important;
    font-size: 14px !important;
    font-weight: bold !important;
    border: none !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding-left: 24px !important;

    &:focus {
      outline: none;
      box-shadow: none !important;
    }
  }

  &__categories {
    background: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    min-height: 590px;
    height: auto;
    max-width: 909px;
    margin-top: 40px;
    padding-bottom: 10px;

    &-title {
      font-size: 18px;
      font-weight: 600;
      color: $color-brand-1;
      padding-top: 32px;
      margin-left: 32px;
      margin-bottom: 25px;
    }
  }

  &__category {
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 4px;
    height: 59px;
    width: 437px;
    padding-left: 7px;
    padding-right: 34px;
    margin-bottom: 24px;
    margin-left: 32px;

    .delete {
      display: none;
    }

    &:hover {
      .delete {
        display: block;
      }
    }

    @include screen-sm {
      margin-left: 0;
      width: auto;
      height: auto;
      min-height: 59px;
    }

    &.-is-active {
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    }

    .check {
      margin-left: 0;
      margin-right: 0;
    }

    &-check {
      margin-left: 16px;
      margin-right: 24px;

      @include screen-sm {
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    &-title {
      font-size: 14px;
      font-weight: bold;
      color: $color-font-dark;
    }

    &-text {
      font-size: 14px;
      color: $color-font-gray;
    }

    &-edit {
      justify-content: flex-end;
      font-size: 14px;
      font-weight: bold;
      color: $color-brand-3;
      cursor: pointer;
    }

    &-icon {
      margin-left: 10px;
    }

    &-save {
      width: 136px !important;

      &:first-child {
        margin-right: 24px;
      }
    }
  }
}

.compliance-menu {
  right: 0;
  background: #fff;
  border-radius: 1px;
  border: 1px solid rgba(46, 91, 255, 0.08);
  top: 90px;
  height: calc(100vh - 90px);
  overflow: auto;
  min-width: 310px;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -91px;

  @include screen-md {
    min-width: 210px;
  }

  @include screen-sm {
    height: auto;
    min-width: 210px;
    margin-top: 30px;
  }

  &__download {
    cursor: pointer;
  }

  &__draft {
    height: 40px;
    width: 223px;
    background: $color-light-gray !important;
    border: none !important;
    color: $color-brand-1 !important;
    font-size: 14px !important;
    font-weight: bold !important;
    border-radius: 4px !important;
  }

  &__last-edit {
    font-size: 14px;
    font-weight: bold;
    color: $color-brand-1;
    margin-top: 32px;
  }

  &__items {
    padding-left: 44px;
    margin-top: 32px;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }

  &__download {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    color: $color-brand-1;
  }
}

.preview-report {
  &__header {
    box-shadow: 2px 4px 16px 0px rgba(51, 51, 51, 0.08);
    height: 77px;
    display: flex;
    background: rgb(176, 186, 201);
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 48px;
  }

  &__logo {
    max-height: 60px;
    height: 60px;
    min-width: 63px;
    width: 80px;

    img {
      object-fit: cover;
      max-height: 60px;
      width: 63px;
    }
  }

  &__placeholder {
    background: $color-font-light;
    box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
    border-radius: 50%;
    height: 63px;
    width: 63px;
    margin-bottom: 8px;
  }

  &__close-btn {
    position: absolute;
    right: 91px;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;

    @include screen-sm {
      right: 15px;
    }
  }

  &__container {
    height: calc(100vh - 210px);
    overflow: auto;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
    color: #fff;
  }

  &__doc {
    background: #fff;
    border-radius: 0px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    height: auto;
    max-width: 747px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;

    @include screen-sm {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  &__banner {
    height: 11px;
    background: #71e4ef;
  }

  &__info {
    margin-top: 29px;
    margin-left: 53px;
    margin-right: 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include screen-sm {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  &__company {
    margin-left: 18px;
    font-size: 18px;
    font-weight: bold;
    color: #71e4ef;
  }

  &__date {
    font-size: 13px;
    color: $color-font-gray;
  }

  &__doc-title {
    padding-top: 48px;
    margin-left: 53px;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.16px;
    font-family: "Bookmania Semibold", "Roboto", sans-serif;

    @include screen-sm {
      margin-left: 15px;
    }
  }

  &__subtitle {
    margin-top: 48px;
    margin-bottom: 32px;
    margin-left: 35px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.13px;
    font-family: "Bookmania Semibold", "Roboto", sans-serif;
    color: $color-font-gray;
  }

  ol {
    @include screen-sm {
      padding-left: 0;
    }
  }

  &__content {
    font-size: 14px;
    color: #818181;
    margin-left: 53px;
    max-width: 509px;
  }

  &__text {
    margin-left: 35px;
    font-size: 18px;
    font-weight: 600;
  }
}

.compliance-data {
  padding-top: 48px;

  &.-has-overflow {
    overflow: scroll;
  }

  .tab-content {
    @include screen-sm {
      overflow: scroll;
    }
  }

  &.-officer {
    .compliance-data__suppliers {
      height: 144px;
      display: flex;
      justify-content: space-between;
      padding-top: 24px;
      padding-bottom: 24px;
    }

    height: calc(100vh - 90px);
    overflow: auto;
  }

  &__officer {
    background: #fff;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    min-height: 430px;
    margin-top: 32px;
    padding-left: 32px;
    padding-bottom: 32px;

    @include screen-md {
      padding-left: 10px;
      padding-right: 10px;
    }

    &.-is-sample {
      pointer-events: none;
    }

    &-checks {
      @include screen-md {
        width: 100%;
        justify-content: center;
        display: flex;
        align-items: center;
      }
    }

    &-avatar {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1.5px;
      color: $color-font-gray;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .show-upload {
      height: 98px;
      width: 98px;
      border-radius: 50%;
      position: absolute;
      cursor: pointer;
      top: 0;
    }

    &-avatar-container {
      box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
      height: 98px;
      width: 98px;
      display: block;
      border-radius: 50%;
      cursor: pointer;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-form {
      display: flex;
      margin-left: 40px;
      margin-top: 18px;
      flex-direction: column;

      input {
        @include screen-md {
          margin-bottom: 8px !important;
        }

        @include screen-sm {
          margin-left: 0;
          margin-right: 0;
          width: 100% !important;
        }
      }

      @include screen-md {
        margin-left: 0;
        justify-content: center;
        flex-direction: row;

        > div {
          flex-direction: column;
        }
      }

      @include screen-sm {
        width: 100%;
      }

      &-container {
        @include screen-md {
          justify-content: center;
        }
      }
    }

    &-btn {
      max-width: 136px;
      margin-top: 32px;
    }

    &-title {
      font-size: 22px;
      font-weight: bold;
      letter-spacing: -0.16px;
      color: $color-brand-1;
      margin-bottom: 40px;
      padding-top: 32px;
    }

    &-text {
      font-size: 14px;
      color: $color-font-dark;
      margin-top: 40px;
      margin-bottom: 16px;
    }

    &-check {
      border-radius: 4px;
      border: 1px solid $color-font-light;
      display: block;
      height: 13px;
      width: 13px;

      &.-is-active {
        background: $color-brand-1;
      }

      &-container {
        font-size: 14px;
        color: $color-font-dark;
      }
    }

    &-input {
      width: 325px !important;
      margin-left: 16px;
      margin-right: 16px;

      @include screen-md {
        margin-bottom: 8px !important;
        margin-top: 8px;
      }
    }

    &-description {
      margin-left: 16px;
      margin-top: 24px;
      max-width: 550px;
      min-height: 200px;
      font-size: 14px !important;

      @include screen-md {
        margin-left: 0;
      }
    }
  }

  &__title {
    font-size: 22px;
    font-weight: 600;
    font-family: "Bookmania Semibold", "Roboto", sans-serif;
    letter-spacing: -0.16px;
    margin-bottom: 16px;
  }

  &__text {
    font-size: 14px;
    color: $color-font-dark;
    max-width: 732px;
  }

  &__suppliers {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    height: 178px;
    max-width: 273px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 25px;
    padding-right: 25px;

    @include screen-md {
      margin-left: auto;
      margin-right: auto;
    }

    &-check {
      width: 30px;
    }

    &-title {
      font-size: 18px;
      font-weight: 600;
      margin-left: 16px;
      margin-bottom: 0px;
    }

    &-text {
      font-size: 14px;
      color: $color-font-gray;
    }

    &-btn {
      max-width: 223px !important;
      width: 100% !important;
    }
  }

  &__content {
    background: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    margin-top: 32px;
    position: relative;

    .nav-tabs {
      height: 62px;
      display: flex;
      align-items: center;

      .active {
        border: none;
        color: $color-brand-1 !important;
        position: relative;

        &:after {
          content: "";
          height: 3px;
          width: 100%;
          display: block;
          background: $color-brand-1;
          position: absolute;
          bottom: -20px;
        }

        &:hover {
          color: $color-brand-1 !important;
        }
      }

      .nav-link {
        font-size: 14px;
        font-weight: bold;
        padding: 0;
        border: none;
        cursor: pointer;
        color: $color-font-gray;

        &:hover {
          border: none;
          color: $color-font-gray;
        }

        &.-users {
          margin-left: 32px;
          margin-right: 72px;
        }
      }
    }
  }
}

.compliance-no-data {
  background: #fff;
  border-radius: 4px;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  min-height: 499px;
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &-item {
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    height: 420px;
    width: 382px;
    background: #fff;
    padding: 24px 10px;
    margin-right: 32px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    img {
      max-width: 150px;
      margin-top: 20px;
    }
  }

  &__text {
    font-size: 14px;
    color: $color-font-dark;
    margin-top: 48px;
    margin-bottom: 32px;
    width: 80%;
  }

  &__title {
    font-size: 16px;
    color: $color-brand-1;
    font-weight: bold;
    margin-bottom: 20px;
  }
}

.compliance-request-signature {
  background: #fff;
  border-radius: 4px;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  min-height: 571px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  &__title {
    font-family: "Bookmania Semibold", "Roboto", sans-serif;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.16px;
    margin-top: 32px;
    margin-bottom: 16px;
  }

  &__text {
    font-size: 14px;
    color: $color-font-gray;
    text-align: center;
    max-width: 785px;
  }

  &__btns {
    margin-bottom: 48px;

    .btn {
      width: 136px !important;
    }
  }
}

.compliance-request-sent {
  background: #fff;
  border-radius: 4px;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  min-height: 406px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__title {
    font-size: 22px;
    font-weight: bold;
    letter-spacing: -0.16px;
    color: $color-brand-1;
    margin-top: 32px;
    margin-bottom: 24px;
  }

  &__text {
    font-size: 14px;
    width: 286px;
    color: $color-font-gray;
    text-align: center;
    margin-bottom: 18px;
  }

  &__link {
    font-size: 14px;
    font-weight: bold;
    color: $color-brand-1;
    cursor: pointer;
  }
}

.compliance-breach {
  margin-top: 48px;
  margin-left: 72px;

  @include screen-sm {
    margin-left: 15px;
  }

  &__back {
    font-size: 14px;
    font-weight: bold;
    color: $color-brand-1;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &.-has-scroll {
    overflow: scroll;
    height: 100%;
  }

  &__new {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    height: 486px;
    margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &-title {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      p {
        margin-bottom: 0;
        font-size: 22px;
        font-weight: 600;
        font-family: "Bookmania Semibold", "Roboto", sans-serif;
        letter-spacing: -0.16px;
        margin-left: 14px;
      }
    }

    &-text {
      font-size: 14px;
      color: $color-font-dark;
      max-width: 785px;
      text-align: center;
      margin-bottom: 36px;
    }

    &-form {
      margin-top: 46px;

      .form-group {
        min-width: 568px;
        width: 100%;
        margin-bottom: 48px;

        @include screen-sm {
          min-width: 300px;
        }
      }

      label {
        font-size: 14px;
        font-weight: 600;
      }
    }

    &-btns {
      display: flex;
      justify-content: center;

      .btn {
        width: 136px;
        margin-left: 12px;
        margin-right: 12px;
      }
    }
  }

  &__title {
    font-family: "Bookmania Semibold", "Roboto", sans-serif;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.16px;
    margin-bottom: 16px;
  }

  &__text {
    font-size: 14px;
    color: $color-font-dark;
    max-width: 536px;
  }

  &__item {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    min-height: 87px;
    max-width: 435px;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 30px 16px 24px;
    cursor: pointer;

    @include screen-sm {
      max-width: 300px;
    }

    &-title {
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 600;
    }

    &-text {
      margin-bottom: 0;
      font-size: 14px;
      color: $color-font-dark;
    }

    &-subtitle {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    &-update {
      font-size: 14px;
      color: $color-font-dark;
      margin-bottom: 16px;
    }

    &-link {
      font-size: 14px;
      font-weight: bold;
      color: $color-brand-1;
      margin-bottom: 0;
    }
  }
}

.compliance-breaches {
  margin-left: -72px;
  margin-top: -48px;
  display: flex;
  justify-content: space-between;

  .breach-menu {
    @include screen-sm {
      height: auto;
    }
  }

  @include screen-sm {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 100%;
  }

  .react-datepicker__header {
    background-color: $color-light-gray !important;
    border-color: #e0e7ff !important;
  }

  .react-datepicker {
    border-color: #e0e7ff !important;

    button {
      &:focus {
        outline: none;
      }
    }

    &__triangle {
      border-bottom-color: $color-light-gray !important;

      &:before {
        border-bottom-color: #e0e7ff !important;
      }
    }

    &__current-month,
    &-time__header,
    &-year-header {
      color: $color-brand-1;
    }

    &__time-container {
      border-color: #e0e7ff !important;
    }

    &__day,
    &__time-list-item {
      &:hover {
        background: rgba(116, 80, 200, 0.25) !important;
      }
    }

    &__day {
      &:hover {
        border-radius: 50%;
      }
    }

    &__day--keyboard-selected,
    &__day--selected,
    &__time-list-item--selected {
      background-color: $color-brand-1 !important;
    }

    &__day--keyboard-selected,
    &__day--selected {
      border-radius: 50%;
    }
  }

  &__where {
    margin-top: 48px;
    width: 100%;
    margin-left: 48px;
    margin-right: 48px;
    overflow: auto;
    height: calc(100vh - 150px);

    @include screen-sm {
      height: 100%;
    }

    &-title {
      font-size: 18px;
      font-weight: 600;
      color: $color-brand-1;
      margin-bottom: 16px;
    }

    &-text {
      font-size: 14px;
      color: $color-font-dark;
      margin-bottom: 60px;
    }

    &-input {
      max-width: 325px;
    }

    .check {
      margin-left: 0;
    }

    &-check {
      &-label {
        font-size: 14px;
        font-weight: bold;
        color: $color-font-gray;
        margin-bottom: 8px;
      }

      &-text {
        font-size: 14px;
        color: $color-font-dark;
      }
    }

    &-btn {
      background-color: #fff !important;
      border-radius: 5px !important;
      border: 1px solid rgb(224, 231, 255) !important;
      height: 38px !important;
      width: 75px !important;
      display: block !important;
      color: #2e384d !important;
      font-size: 15px !important;
      font-family: "Rubik", "Roboto", sans-serif !important;
      cursor: pointer;

      &.-month {
        width: 167px !important;
      }

      &.-is-active {
        background-color: $color-font-gray !important;
        color: #fff !important;
      }
    }

    &-date {
      background: #fff !important;
      border-radius: 5px !important;
      border: 1px solid rgb(224, 231, 255) !important;
      height: 38px !important;
      min-width: 167px !important;
      font-size: 15px;
      color: #2e384d !important;
      font-size: 15px !important;
      font-family: "Rubik", "Roboto", sans-serif !important;

      &:active,
      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }
    }

    &-btns {
      display: flex;
    }

    &-radio {
      border-radius: 4px;
      border: 1px solid $color-font-light;
      display: block;
      min-height: 13px;
      min-width: 13px;
      max-height: 13px;
      max-width: 13px;

      &.-is-active {
        background: $color-brand-1;
      }

      &-label {
        font-size: 14px;
        color: $color-font-gray !important;
        margin-right: 48px;
        width: 100% !important;
        margin-bottom: 24px;
        margin-top: 9px;

        input {
          height: 38px !important;
          min-width: 325px !important;
          border-radius: 5px !important;
          border: 1px solid $color-font-light !important;
        }
      }
    }

    &-description {
      margin-top: 56px;

      textarea {
        height: 221px !important;
        max-width: 568px;
      }
    }
  }
}

.breach-menu {
  background: #fff;
  border: 1px solid rgba(46, 91, 255, 0.08);
  min-width: 326px;
  max-width: 326px;
  height: calc(100vh - 90px);
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);

  &__header {
    display: flex;
    margin-top: 48px;
    align-items: center;
    margin-bottom: 8px;
    padding-left: 24px;

    &.-extra-margin {
      margin-bottom: 48px;
    }
  }

  &__back {
    font-size: 14px;
    font-weight: bold;
    color: $color-brand-1;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 30px;
    margin-bottom: -20px;
    padding-left: 24px;
  }

  &__content {
    overflow: auto;
    height: calc(100vh - 275px);
    padding-left: 24px;
    padding-top: 5px;
  }

  &__title {
    font-size: 22px;
    font-weight: 600;
    font-family: "Bookmania Semibold", "Roboto", sans-serif;
    margin-bottom: 0;
    margin-left: 14px;
    white-space: nowrap;
  }

  &__text {
    font-size: 14px;
    color: $color-font-dark;
    margin-bottom: 48px;
    padding: 0 24px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    background: #fff;
    height: 59px;
    width: 280px;
    cursor: pointer;
    margin-bottom: 24px;
    padding-left: 16px;
    padding-right: 19px;

    &:hover,
    &.-is-active {
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    }

    &-text {
      font-size: 14px;
      font-weight: bold;
      color: $color-font-gray;
      margin-bottom: 0;
    }
  }
}

.breach-right-menu {
  background: #fff;
  width: 310px;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  padding-left: 44px;
  padding-right: 44px;
  padding-top: 48px;

  &__icons {
    max-width: 15px;
  }
}

.notify-breach {
  height: calc(100vh - 90px);
  overflow: auto;
  margin-left: 72px;

  @include screen-sm {
    margin-left: 10px;
  }

  &__back {
    font-size: 14px;
    font-weight: bold;
    color: $color-brand-1;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 30px;
    margin-bottom: -20px;
  }

  &__title {
    font-family: "Bookmania Semibold", "Roboto", sans-serif;
    margin-top: 48px;
    margin-bottom: 16px;
  }

  &__text {
    font-size: 14px;
    color: $color-font-gray;
    margin-bottom: 67px;
  }

  &__item {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    min-height: 165px;
    width: 376px;
    margin-right: 32px;
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-right: 44px;
    padding-top: 10px;
    padding-bottom: 10px;

    &-icon {
      margin-right: 20px;
      min-width: 20px;
      min-height: 20px;
      position: relative;
      top: 3px;
    }

    &-title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    &-text {
      font-size: 14px;
      color: $color-font-dark;
      margin-bottom: 16px;
    }
  }

  &__btn {
    width: 136px !important;
  }
}

.email-breach {
  flex: 1;
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 48px;
  overflow: scroll;
  height: 100vh;
  width: 100%;

  @include screen-sm {
    padding-left: 0px;
    padding-right: 0px;
    max-width: 350px;
  }

  &__title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 14px;
  }

  &__text {
    font-size: 14px;
    color: $color-font-dark;
    margin-bottom: 65px;
  }
}

.import {
  &__upload {
    &-container {
      background: #fff;
      border-radius: 4px;
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      min-height: 262px;
      height: auto;
      padding-top: 40px;
      padding-bottom: 40px;
    }

    &-content {
      background: #fff;
      border-radius: 4px;
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      min-height: 327px;
      width: 302px;
      margin-top: 40px;
      margin-bottom: 20px;

      &.-is-active {
        border: 2px solid rgba(116, 80, 200, 0.3);
      }
    }

    &-cancel {
      width: 136px !important;
      margin-top: 48px;
    }

    &-placeholder {
      height: 90px;
      width: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #dfe4ee;
      color: #333;
      font-size: 20px;
    }

    &-avatar {
      background: #fff;
      border-radius: 10px;
      box-shadow: 2px 4px 16px 0px rgba(51, 51, 51, 0.08);
      height: 103px;
      width: 254px;
      margin-top: -40px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 90px;
        object-fit: cover;
      }
    }

    &-name {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      margin-top: 24px;
      margin-bottom: 24px;
    }

    &-description {
      font-size: 14px;
      color: $color-font-gray;
      text-align: center;
      padding: 0 24px;
    }
  }
}
