@import "../../styles/utils/mixins";
@import "../../styles/utils/variables";

@font-face {
  font-family: "Bookmania Semibold";
  src: url("../../styles/fonts/bookmania-semibold.otf") format("opentype");
}

.help {
  position: relative;
  height: calc(100vh - 90px);
  overflow: scroll;

  @include screen-sm {
    height: calc(100vh - 53px);
  }

  &__bg {
    height: 226px;
    width: 100%;
    left: 0;
    margin-top: 0 !important;
    position: absolute;
    background: $color-lightest-gray;
  }

  &__container {
    margin-top: 64px;
  }

  &__items {
    margin-top: 71px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  &__item {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    height: 226px;
    max-width: 219px !important;
    margin-left: 66px;
    margin-right: 66px;
    margin-bottom: 57px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    color: $color-brand-1;
    margin-top: 24px;
    margin-bottom: 18px;
  }

  &__info {
    font-size: 16px;
    color: $color-font-gray;
    text-align: center;
  }

  &__contact {
    background: rgb(255, 255, 255);
    border-radius: 4px;
    border: 1px solid rgb(223, 228, 238);
    box-shadow: 3px 3px 20px 0px rgba(0, 0, 0, 0.1);
    height: 583px;
    width: 376px;
    display: none;
    bottom: 45px;
    right: 45px;
    flex-direction: column;
    justify-content: space-between;

    &.-is-visible {
      display: flex;
      position: fixed;
    }

    &-header {
      background: rgb(116, 80, 200);
      border-radius: 4px;
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      height: 61px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      padding-left: 16px;
      padding-right: 16px;
    }

    &-close {
      cursor: pointer;
    }

    &-footer {
      background: $color-light-gray;
      width: 100%;
      height: 52px;
      align-items: center;
      justify-content: space-between;
      display: flex;
      padding-left: 16px;
      padding-right: 16px;

      input {
        width: 235px;
        background: transparent;
        border: none !important;

        &:focus {
          box-shadow: none;
          outline: none;
        }

        &::placeholder {
          font-size: 14px;
          color: $color-font-gray;
        }
      }
    }

    &-chat {
      height: 100%;
      padding-left: 16px;
      padding-right: 16px;
    }

    &-message-sent {
      margin-bottom: 15px;
    }

    &-message-received {
      text-align: right;
    }

    &-message-received-bubble {
      background: $color-brand-1;
      border-radius: 21px;
      border-bottom-right-radius: 0;
      padding: 12px 16px;
      margin-left: 8px;
      font-size: 14px;
      color: #fff;
    }

    &-message-sent-bubble {
      background: $color-light-gray;
      border-radius: 21px;
      border-bottom-left-radius: 0;
      padding: 12px 16px;
      margin-left: 8px;
      font-size: 14px;
      color: $color-font-gray;

      &.-no-avatar {
        margin-top: 8px;
        margin-left: 45px;
      }
    }

    &-date {
      font-size: 14px;
      color: $color-font-gray;
      display: flex;
      justify-content: center;
      margin-top: 16px;
      margin-bottom: 49px;
    }
  }
}

.empty-announcements {
  overflow-x: hidden;
  height: calc(100vh - 90px);
  overflow-y: scroll;

  @include screen-sm {
    height: calc(100vh - 53px);
  }

  &__content {
    background: #fff;
    height: 560px;
    box-shadow: 3px 3px 10px 0px $color-lightest-gray;
    border-radius: 4px;
    margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__img {
    width: 223px;
    margin-bottom: 58px;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    color: $color-brand-1;
    margin-bottom: 37px;

    @include screen-sm {
      width: 85%;
      text-align: center;
    }
  }

  &__text {
    font-size: 14px;
    color: $color-font-gray;

    @include screen-sm {
      width: 85%;
      text-align: center;
    }
  }
}

.announcements {
  overflow-x: hidden;
  height: calc(100vh - 90px);
  overflow-y: scroll;

  &__title {
    font-family: "Bookmania Semibold", "Roboto", sans-serif;
    font-size: 22px;
    font-weight: 600;
    margin-top: 48px;
    margin-bottom: 16px;
  }

  &__text {
    font-size: 14px;
    color: $color-font-gray;
  }

  &__items {
    margin-top: 81px;
    display: flex;
    width: 100%;
    overflow: auto;
    padding-bottom: 20px;
    padding-left: 72px;
  }

  &__item {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    height: 290px;
    max-width: 294px !important;
    min-width: 294px !important;
    margin-right: 64px;
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-title {
      font-size: 18px;
      font-weight: 600;
      color: $color-brand-1;
      margin-bottom: 30px;
    }

    &-text {
      font-size: 14px;
      color: $color-font-gray;
    }

    &-date {
      font-size: 12px;
      color: $color-font-gray;
      margin-bottom: 0;
    }
  }

  &__previous {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    max-height: 323px;
    overflow: auto;
    margin-bottom: 30px;

    &-title {
      font-size: 18px;
      font-weight: 600;
      margin-top: 44px;
      margin-bottom: 16px;
    }

    &-item {
      display: flex;
      justify-content: space-between;
      padding-top: 32px;
      padding-bottom: 32px;
      margin-left: 32px;
      margin-right: 32px;
      border-bottom: 1px solid $color-lightest-gray;

      &-title {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      &-text {
        font-size: 14px;
        color: $color-font-gray;
      }
    }
  }
}
