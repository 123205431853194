.btn--primary, .btn--secondary, .btn--outline, .btn--gray, .btn--empty {
    font-size: 14px !important;
    font-weight: bold !important;
    border-radius: 4px !important;
    height: 40px;
    width: 223px;
}

.-has-pointer {
    cursor: pointer;
}

.divider {
    width: 100%;
    height: 1px;
    background: #f0f1f2;
    display: block;
}

.btn--primary {
    color: #fff !important;
    border: solid 0.5px $color-brand-1 !important;
    background-color: $color-brand-1 !important;

    &:hover, &.disabled:hover, &[disabled]:hover {
        color: #fff;
        background-color: darken($color-brand-1, 15%) !important;
        border-color: darken($color-brand-1, 15%) !important;
    }
}

.btn--secondary {
    color: $color-brand-1 !important;
    border: solid 0.5px $color-brand-1 !important;
    background-color: #fff !important;

    &:hover, &.disabled:hover, &[disabled]:hover {
        color: #fff;
        background-color: $color-lightest-gray !important;
        border-color: $color-brand-1 !important;
    }
}

.btn--outline {
    color: $color-brand-1 !important;
    border: none !important;
    background-color: #fff !important;

    &:hover, &.disabled:hover, &[disabled]:hover {
        color: #fff;
        background-color: #fff !important;
        border-color: none !important;
    }
}

.btn--empty {
    color: $color-brand-1 !important;
    border: solid 0.5px $color-brand-1 !important;
    background-color: transparent !important;

    &:hover, &.disabled:hover, &[disabled]:hover {
        color: $color-brand-1;
        background-color: $color-lightest-gray !important;
        border: solid 0.5px $color-brand-1 !important;
    }
}

.btn--gray {
    color: $color-brand-1 !important;
    border: none !important;
    background-color: $color-light-gray !important;

    &:hover, &.disabled:hover, &[disabled]:hover {
        color: #fff;
        background-color: darken($color-light-gray, 5%) !important;
        border-color: none !important;
    }
}