@import "./../../styles/utils/mixins";
@import "./../../styles/utils/variables";

.sample-scheme {
  margin-bottom: 32px;

  &__color {
    width: 29px;
    height: 29px;
    border-radius: 50%;
    display: block;
    border: 1px solid rgb(151, 151, 151);
    margin-right: 9px;

    &.-white {
      background: #fff;
    }

    &.-black {
      background: #000;
    }

    &.-purple {
      background: $color-brand-1;
    }

    &.-light-gray {
      background: #f4f6fc;
    }

    &.-dark-gray {
      background: #dfe4ee;
    }
  }
}

.sample-text {
  flex-direction: column;
  padding-bottom: 15px;

  &__label {
    font-size: 14px;
    font-weight: bold;
    color: $color-font-gray;
    margin-bottom: 11px;
  }

  &__logo {
    display: flex;
    align-items: flex-end;
    font-size: 14px;
    font-weight: 600;
    color: $color-font-gray;
  }

  &__color {
    border-radius: 4px;
    border: 1px solid rgb(223, 228, 238);
    height: 38px;
    width: 38px;
    margin-right: 24px;

    &.-black {
      background: #000;
    }

    &.-gray {
      background: $color-font-gray;
    }

    &.-purple {
      background: $color-brand-1;
    }
  }

  &__size {
    background: #fff;
    border-radius: 5px;
    border: 1px solid rgb(176, 186, 201);
    height: 38px;
    width: 73px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    color: #b0bac9;
  }

  &__font {
    margin-top: 8px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid rgb(176, 186, 201);
    height: 38px;
    width: 223px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #b0bac9;
    padding-left: 16px;
    padding-right: 14px;
  }
}

.sample-customization {
  .check {
    border-radius: 4px;
    border: 1px solid $color-font-light;
    display: block;
    min-height: 13px;
    min-width: 13px;
    max-height: 13px;
    max-width: 13px;
    margin-right: 8px;

    &.-is-active {
      background: $color-brand-1;
    }
  }
}
