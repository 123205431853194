@import "../../styles/utils/mixins";
@import "../../styles/utils/_variables";

@font-face {
  font-family: "Bookmania Semibold";
  src: url("../../styles/fonts/bookmania-semibold.otf") format("opentype");
}

.users-admin {
  height: calc(100vh - 90px);
  overflow: scroll;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 48px;
  padding-bottom: 40px;

  .rt-th {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
    border: none !important;
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    text-align: left;

    &:first-child {
      width: 40px !important;
    }

    &:last-child {
      width: 5px !important;
    }
  }

  .pagination-bottom {
    background: #fff;
  }

  .-pagination {
    box-shadow: none !important;
  }

  .rt-td {
    border: none !important;
    font-size: 14px;
    color: $color-font-dark;
    z-index: 0;
    padding: 12px !important;

    &:first-child {
      width: 40px !important;
    }

    &:last-child {
      width: 5px !important;
    }
  }

  .rt-noData {
    width: 100%;
    background: #fff;
    text-align: center;
  }

  .-next,
  .-previous {
    height: 40px !important;
    width: 223px !important;
    border-radius: 4px !important;
    flex: none !important;

    .-btn {
      color: #fff !important;
      font-size: 14px !important;
      font-weight: bold !important;
      border-radius: 4px !important;
      height: 40px !important;
      width: 223px !important;
      background-color: $color-brand-1 !important;
    }
  }

  .rt-tbody {
    background: #fff;
  }

  .rt-tr-group {
    border: none !important;

    &:hover {
      background: $color-light-gray;
    }
  }

  @include screen-sm {
    height: calc(100vh - 53px);
  }

  &__header {
    font-size: 22px;
    font-family: "Bookmania Semibold", "Roboto", sans-serif;
    margin-bottom: 8px;
  }

  &__text {
    font-size: 14px;
    color: $color-font-gray;
    margin-bottom: 32px;
  }

  input {
    width: 245px !important;
  }

  .users__table {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .users__action {
    &.-is-disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .input-group {
    max-width: 445px !important;
    flex-wrap: nowrap;
    width: 100%;
  }

  &__table-content {
    min-width: 813px;
    width: 100%;
  }

  &__view {
    color: $color-brand-1;
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  }

  &__close {
    position: absolute;
    top: 21px;
    right: 0;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;

    @include screen-sm {
      right: 10px;
    }
  }

  .user-admin {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    max-height: 733px;
    min-height: 733px;
    margin-top: 25px;
    display: flex;
    padding: 24px 32px;
    flex-direction: column;
    overflow: auto;
    justify-content: space-between;

    @include screen-sm {
      height: 100%;
    }

    &__container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__avatar {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background: $color-font-gray;
      display: flex;
      margin-right: 24px;
      margin-bottom: 24px;

      @include screen-xs {
        margin-bottom: 24px;
      }
    }

    &__title {
      font-size: 18px;
      font-weight: 600;
      color: $color-brand-1;
      margin-bottom: 28px;
    }

    .form-group {
      justify-content: space-between;
      max-width: 722px;
      width: 722px;
      margin-bottom: 0;
      flex-wrap: wrap;

      @include screen-md {
        max-width: 100%;
        width: 100%;
      }
    }

    input {
      width: 325px !important;
      margin-bottom: 24px;

      @include screen-xs {
        width: 100% !important;
      }
    }

    &__added {
      font-size: 18px;
      font-weight: 600;
      color: $color-brand-1;
      margin-bottom: 24px;
    }

    &__date {
      font-size: 14px;
      font-weight: bold;
      color: $color-font-gray;
    }

    &__download,
    &__delete {
      font-size: 14px;
      font-weight: bold;
      color: $color-brand-1;
    }

    &__download {
      margin-bottom: 32px;
    }

    th,
    td {
      border: none !important;
    }

    td {
      font-size: 14px;
      color: $color-font-gray;
      white-space: nowrap;
    }

    &__user-table {
      max-height: 300px;
      overflow: auto;
    }

    &__btn {
      width: 136px;
    }
  }
}
