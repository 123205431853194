@import "./../../styles/utils/mixins";
@import "./../../styles/utils/variables";

@font-face {
  font-family: "Bookmania Semibold";
  src: url("../../styles/fonts/bookmania-semibold.otf") format("opentype");
}

.users-free {
  height: calc(100vh - 90px);
  overflow: scroll;
  background: #fff;
  display: flex;

  @include screen-sm {
    height: calc(100vh - 53px);
    flex-direction: column-reverse;
  }

  &__banner {
    width: 30%;
    background: $color-brand-1;
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include screen-sm {
      width: 100%;
    }
  }

  &__img1 {
    max-width: 405px;

    @include screen-sm {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }

  &__img2 {
    max-width: 222px;
    position: relative;
    left: -80px;
    top: -120px;

    @include screen-sm {
      left: 0;
      top: -80px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 70%;
    padding: 10px;

    @include screen-sm {
      left: 0;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      padding-bottom: 10px;
    }

    ul {
      padding: 0;
      li {
        list-style-type: none;
        font-size: 16px;
        color: $color-font-gray;
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        @include screen-sm {
          svg {
            min-width: 20px;
            height: 20px;
          }
        }
      }
    }

    &-upgrade {
      max-width: 136px;
    }

    &-title {
      font-family: "Bookmania Semibold", "Roboto", sans-serif;
      letter-spacing: -0.2px;
      font-size: 28px;
      margin-bottom: 24px;

      @include screen-sm {
        text-align: center;
      }
    }

    &-subtitle {
      font-size: 16px;
      max-width: 483px;
      color: $color-font-gray;
      margin-bottom: 67px;
      text-align: center;
    }

    &-icon {
      width: 20px;
      height: 20px;
      margin-right: 16px;
    }
  }
}

.users {
  height: calc(100vh - 90px);
  overflow: scroll;
  padding-top: 48px;
  padding-left: 15px;
  padding-right: 15px;

  &__table-pagination {
    .-pageInfo {
      display: none !important;
    }
  }

  @include screen-sm {
    height: auto;
  }

  .rt-th {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
    border: none !important;
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    text-align: left;

    &:first-child {
      width: 40px !important;
    }

    &:last-child {
      width: 5px !important;
    }
  }

  .pagination-bottom {
    background: #fff;
  }

  .-pagination {
    box-shadow: none !important;
  }

  .rt-td {
    border: none !important;
    font-size: 14px;
    color: $color-font-dark;
    z-index: 0;
    padding: 12px !important;

    &:first-child {
      width: 40px !important;
    }

    &:last-child {
      width: 5px !important;
    }
  }

  .rt-noData {
    width: 100%;
    background: #fff;
    text-align: center;
  }

  .-next,
  .-previous {
    height: 40px !important;
    width: 223px !important;
    border-radius: 4px !important;
    flex: none !important;

    .-btn {
      color: #fff !important;
      font-size: 14px !important;
      font-weight: bold !important;
      border-radius: 4px !important;
      height: 40px !important;
      width: 223px !important;
      background-color: $color-brand-1 !important;
    }
  }

  .rt-tbody {
    background: #fff;
  }

  .rt-tr-group {
    border: none !important;

    &:hover {
      background: $color-light-gray;
    }
  }

  &.-has-banner {
    height: calc(100vh - 170px);

    @include screen-sm {
      height: calc(100vh - 130px);
    }
  }

  &__view {
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    color: $color-brand-1;

    &:hover {
      text-decoration: none;
      color: $color-brand-1;
    }
  }

  &.-is-sample {
    height: calc(100vh - 191px);

    @include screen-sm {
      height: calc(100vh - 154px);
    }
  }

  &__banner {
    display: flex;
    box-shadow: 2px 4px 16px 0px rgba(51, 51, 51, 0.08);
    height: 52px;
    background: rgb(176, 186, 201);
    align-items: center;

    &-title {
      font-size: 18px;
      font-weight: 600;
      color: #fff;
      text-align: center;
      display: flex;
      justify-content: center;
      flex: 1;
      align-items: center;
      margin-bottom: 0;
    }

    &-exit {
      position: absolute;
      right: 40px;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: bold;
      color: #fff;
      cursor: pointer;
    }
  }

  @include screen-md {
    padding-left: 15px;
    padding-right: 15px;
  }

  &__header {
    &-stats {
      background: rgb(255, 255, 255);
      border-radius: 4px;
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      height: 126px;
      margin-bottom: 40px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .overview-card {
        height: auto;
        background: transparent;
        box-shadow: none;
        padding: 0;
        margin-top: 0;
        border-radius: 0;
        width: 20%;

        &__percentage-container {
          @include screen-sm {
            margin-right: 10px;
          }
        }

        @include screen-sm {
          width: 25%;
        }
      }
    }

    &-info {
      padding-left: 0 !important;
    }

    &-text {
      font-size: 14px;
      color: $color-font-dark;
      display: inline-block;
      margin-bottom: 32px;
    }

    &-import,
    &-add {
      border-radius: 4px;
      height: 40px;
      width: 136px !important;
      font-size: 14px !important;
      font-weight: bold !important;
    }

    &-import {
      margin-right: 16px;
      background: $color-brand-1 !important;
    }

    &-add {
      background: #fff !important;
      border: 1px solid $color-brand-1 !important;
      color: $color-brand-1 !important;
    }

    &-btns {
      margin-bottom: 40px;
    }
  }

  &__divider {
    height: 34px;
    width: 1px;
    background: $color-lightest-gray;
    margin-left: 18px;
    margin-right: 18px;
  }

  &__action {
    font-size: 14px;
    font-weight: bold !important;
    padding: 0 !important;
    color: $color-brand-1 !important;
    display: flex;
    align-items: center;
    background: transparent !important;
    border: none !important;
    cursor: pointer;

    &.-is-disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &.-filter {
      margin-right: 18px;
      color: $color-font-base !important;
    }

    &:active,
    &:focus {
      outline: none;
      box-shadow: none !important;
    }
  }

  &__actions {
    display: flex;
    background: #fff;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-lightest-gray;

    .dropdown {
      min-width: 105px;
    }

    .show {
      .-filter {
        position: absolute;
        width: 326px;
        left: 0;
        text-align: left;
        z-index: 999;
        background: #fff !important;
        height: 50px;
        top: -25px;
        border-right: 1px solid $color-lightest-gray !important;
        color: $color-font-base !important;
      }

      .dropdown-menu {
        width: 346px !important;
        left: -20px !important;
        top: -3px !important;
        border-radius: 4px !important;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      }

      .dropdown-item {
        padding: 10px 23px;
        font-size: 14px;
        color: $color-font-dark;

        &:hover {
          background: $color-light-gray;
          color: $color-font-base;
        }

        &:active {
          outline: none;
        }
      }
    }

    .input-group {
      max-width: 380px;
      margin-left: 0;
      margin-right: 0;
      min-width: 380px;
    }

    input {
      padding-left: 24px !important;
    }
  }

  &__table {
    background: #fff;
    overflow: scroll;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);

    .check {
      margin-right: 0;
      margin-right: 0;
    }

    &.-unchecked,
    &.-checked {
      background: $color-brand-3;
      display: block;
    }

    th {
      padding-top: 24px !important;
      padding-bottom: 24px !important;
      border: none !important;
      font-size: 14px;
      font-weight: 600;
    }

    tr {
      &:hover {
        background: $color-light-gray;
      }
    }

    thead {
      background: #fff;

      th {
        position: sticky;
        top: 0;
        background: #fff;
      }
    }

    td {
      border: none !important;
      font-size: 14px;
      color: $color-font-dark;
    }
  }

  &__data {
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    padding-left: 0 !important;
    margin-bottom: 30px;
    overflow: visible;

    &.-has-overflow {
      overflow: visible;
    }

    &-container {
      max-width: 1140px;
      min-width: 1140px;
      position: relative;
    }
  }

  &__btns-pagination {
    position: absolute;
    bottom: 5px;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    z-index: 99;

    &.-is-import {
      bottom: 4px;
    }
  }
}

.users-empty {
  &__container {
    background: #fff;
    border-radius: 0px;
    height: 560px;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__img {
    margin-bottom: 24px;
    width: 200px;
    height: 200px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    color: $color-brand-1;
    margin-bottom: 37px;
  }

  &__text {
    font-size: 14px;
    color: $color-font-gray;
    margin-bottom: 34px;
  }

  &__import,
  &__add {
    border-radius: 4px !important;
    height: 36px;
    width: 136px;
    font-size: 14px !important;
    font-weight: bold !important;
  }

  &__import {
    margin-right: 16px;
    background: $color-brand-1 !important;
    color: #fff !important;
  }

  &__add {
    background: #fff !important;
    color: $color-brand-1 !important;
    border-color: $color-brand-1 !important;
  }
}

.request-signature {
  &__container {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 571px;
    max-height: 600px;

    @include screen-sm {
      max-height: 800px;
    }
  }

  &__title {
    font-family: "Bookmania Semibold", "Roboto", sans-serif;
    font-size: 22px;
    letter-spacing: -0.16px;
    margin-bottom: 16px;
    text-align: center;
  }

  &__text {
    font-size: 14px;
    color: $color-font-gray;
    text-align: center;
    max-width: 785px;
    margin-bottom: 48px;
  }

  &__img {
    margin-bottom: 48px;
  }

  &__users {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    height: 59px;
    width: 384px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 14px;
    color: #8697a6;
    margin-bottom: 72px;

    @include screen-sm {
      margin-bottom: 10px;
      width: auto;
      padding: 10px;
    }
  }

  &__btns {
    display: flex;

    @include screen-sm {
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }

  &__send,
  &__edit,
  &__cancel {
    border-radius: 4px !important;
    height: 36px;
    width: 136px;
    font-size: 14px !important;
    font-weight: bold !important;

    @include screen-sm {
      margin-bottom: 10px;
    }
  }

  &__send,
  &__edit {
    margin-right: 16px;
  }

  &__send {
    background: $color-brand-1 !important;
    color: #fff !important;
  }

  &__edit {
    background: #fff !important;
    color: $color-brand-1 !important;
    border: 1px solid $color-brand-1 !important;
    min-width: 136px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      text-decoration: none;
    }
  }

  &__cancel {
    background: $color-light-gray !important;
    color: $color-brand-1 !important;
    border: none !important;
  }
}

.signature {
  &__container {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    height: 406px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__img {
    margin-bottom: 32px;
  }

  &__title {
    font-size: 22px;
    font-weight: bold;
    color: $color-brand-1;
    margin-bottom: 24px;
    text-align: center;
  }

  &__text {
    font-size: 14px;
    color: $color-font-gray;
    width: 286px;
    text-align: center;
    margin-bottom: 37px;
  }

  &__link {
    font-size: 14px;
    font-weight: bold;
    color: $color-brand-1;

    &:hover {
      color: $color-brand-1;
    }
  }
}

.import {
  @include screen-sm {
    height: 100vh;
  }

  &__container {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    height: 396px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__skip {
    font-size: 14px;
    font-weight: bold;
    color: $color-brand-1;
    cursor: pointer;
  }

  &__title {
    font-size: 22px;
    font-family: "Bookmania Semibold", "Roboto", sans-serif;
    letter-spacing: -0.16px;
    margin-bottom: 16px;
  }

  &__text {
    font-size: 14px;
    color: $color-font-gray;
    margin-bottom: 56px;
  }

  &__type {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    height: 80px;
    width: 435px;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    cursor: pointer;

    @include screen-sm {
      width: 100%;
    }
  }

  &__file-type {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
  }

  &__file-info {
    font-size: 14px;
    color: $color-font-gray;
  }

  &__copy {
    &-title {
      font-size: 22px;
      font-weight: 600;
      letter-spacing: -0.16px;
      font-family: "Bookmania Semibold", "Roboto", sans-serif;
      margin-bottom: 16px;
    }

    &-text {
      font-size: 14px;
      color: $color-font-dark;
      margin-bottom: 48px;
    }

    &-container {
      background: #fff;
      border-radius: 4px;
      border: 1px solid rgb(176, 186, 201);
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      height: 598px;
    }

    &-form {
      display: flex;
      height: 100%;

      textarea {
        border: none !important;
        resize: none;
        padding: 28px 25px;

        &:focus {
          outline: none !important;
          box-shadow: none !important;
        }

        &::placeholder {
          font-size: 14px;
          color: $color-font-light;
        }
      }
    }

    &-btn {
      border-radius: 4px !important;
      height: 36px;
      width: 136px;
      background: $color-brand-1 !important;
      color: #fff !important;
      font-size: 14px !important;
      font-weight: bold !important;
      float: right;
      margin-top: 24px;
    }

    &-cancel {
      border-radius: 4px !important;
      height: 36px;
      width: 136px;
      background: transparent !important;
      border: 1px solid $color-brand-1 !important;
      margin-right: 16px;
      color: $color-brand-1 !important;
      font-size: 14px !important;
      font-weight: bold !important;
      float: right;
      margin-top: 24px;
    }
  }

  &__upload {
    &-container {
      background: rgb(255, 255, 255);
      border-radius: 4px;
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      height: 396px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &-title {
      font-size: 22px;
      font-weight: 600;
      font-family: "Bookmania Semibold", "Roboto", sans-serif;
      margin-bottom: 16px;
    }

    &-text {
      font-size: 14px;
      color: $color-font-gray;
      margin-bottom: 72px;
    }
  }

  &__manual {
    &-container {
      background: #fff;
      border-radius: 4px;
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      min-height: 563px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 32px 10px;
    }

    &-check {
      border-radius: 4px;
      border: 1px solid $color-font-light;
      display: block;
      height: 13px;
      width: 13px;

      &.-is-active {
        background: $color-brand-1;
      }
    }

    &-keep {
      margin-left: 32px;
      font-size: 14px;
      color: $color-font-gray;

      @include screen-sm {
        margin-left: 0;
        margin-right: 12px;
      }
    }

    &-title {
      font-size: 22px;
      font-weight: 600;
      font-family: "Bookmania Semibold", "Roboto", sans-serif;
      letter-spacing: -0.16px;
      margin-bottom: 16px;
    }

    &-text {
      font-size: 14px;
      color: $color-font-gray;
      margin-bottom: 48px;
    }

    &-img {
      margin-bottom: 48px;
    }

    &-form {
      width: 325px;

      @include screen-sm {
        width: 100%;
      }

      input {
        height: 38px;
        margin-bottom: 24px;
        border-radius: 5px;
        border: 1px solid $color-font-light;

        &::placeholder {
          font-size: 14px;
          color: $color-font-light;
        }

        &:focus {
          box-shadow: none;
          border-color: $color-font-light;
        }
      }
    }

    &-btn {
      border-radius: 4px !important;
      height: 36px;
      width: 136px;
      background: $color-brand-1 !important;
      color: #fff !important;
      font-size: 14px !important;
      font-weight: bold !important;
    }
  }
}

.user-details {
  &__info {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    min-height: 814px;
    max-width: 344px;
    width: 100%;
    padding: 44px 60px;

    &-title {
      font-size: 28px;
      font-weight: 500;
      line-height: 28px;
      color: $color-brand-1;
      margin-bottom: 40px;
    }

    &-avatar {
      margin-bottom: 42px;
      width: 68px;
      height: 68px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &-placeholder {
        width: 68px;
        height: 68px;
        border-radius: 50%;
        background: #b0bac9;
      }
    }

    &-submit {
      width: 136px !important;
      margin-top: 18px;
    }
  }

  &__content {
    background: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 48px 124px;

    &-exit {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: bold;
      color: #333333;
      justify-content: flex-end;
      margin-top: -27px;
      margin-right: -96px;
      cursor: pointer;
    }

    &-title {
      font-size: 28px;
      font-weight: 500;
      line-height: 28px;
      color: $color-brand-1;
      margin-bottom: 40px;
    }

    &-subtitle {
      font-size: 14px;
      font-weight: 600;
      color: #333333;
      margin-bottom: 6px;
    }

    &-data {
      font-size: 14px;
      color: #292e31;
    }

    &-check {
      border-radius: 4px;
      border: 1px solid $color-font-light;
      display: block;
      height: 13px;
      width: 13px;

      &.-is-active {
        background: $color-brand-1;
      }
    }

    &-keep {
      font-size: 14px;
      font-weight: bold;
      color: $color-font-gray;
      margin-bottom: 16px;
    }

    &-dropdown {
      background-color: transparent !important;
      color: #292e31 !important;
      outline: none !important;
      box-shadow: none !important;
      border: 0 !important;
    }

    &-divider {
      height: 2px;
      width: 100%;
      background: #b0bac9;
      margin: 54px 0;
    }
  }
}
