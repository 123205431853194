@import "./../../styles/utils/mixins";
@import "./../../styles/utils/variables";

.notifications {
  max-height: calc(100vh - 90px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;

  &__container {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    max-height: 90%;
    min-height: 74px;
    padding: 0 32px;
    overflow: scroll;
  }

  &__pagination {
    margin-left: auto;
    margin-right: auto;
    display: flex;

    &-item {
      background: #fff;
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      height: 36px;
      width: 36px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;
      margin-left: 16px;
      margin-right: 16px;
      cursor: pointer;
      font-size: 14px;

      &.-is-current {
        margin-left: 24px;
      }

      &.-is-previous,
      &.-is-next {
        color: #7450c8;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  .notification {
    &__item {
      height: 74px;
      border-bottom: 2px solid $color-font-gray;

      &:last-child {
        border-bottom: none;
      }

      &-text,
      &-date {
        margin-left: 24px;
        color: $color-font-gray;
        font-size: 14px;
        font-weight: normal;
      }

      &-date {
        margin-right: 32px;
      }

      &-delete {
        cursor: pointer;
      }

      &-see {
        color: $color-brand-1;
        font-size: 14px;
        font-weight: bold;
        margin-right: 40px;
      }
    }
  }
}
