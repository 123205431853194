@import "../../styles/utils/mixins";
@import "../../styles/utils/_variables";

.overview-card {
  height: 134px;
  background: #fff;
  box-shadow: 3px 3px 10px 0px $color-lightest-gray;
  padding: 20px;
  margin-top: 35px;
  border-radius: 4px;
  width: auto;
  max-width: 278px;
  margin-right: 32px;

  @include screen-sm {
    margin-left: auto;
    margin-right: auto;
  }

  &__icon {
    margin-right: 20px;
  }

  &__more {
    font-size: 14px;
    font-weight: bold;
    color: $color-brand-1;

    &:hover {
      color: $color-brand-1;
      text-decoration: none;
    }
  }

  &__stats {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__text {
    font-size: 14px;
    font-weight: bold;
    color: $color-font-gray;
  }

  &__numbers {
    font-weight: 400;
    margin-bottom: 16px !important;
  }

  &__percentage-container {
    margin-right: 25px;
    margin-left: 5px;
  }

  &__percentage {
    width: 100px;
    height: 100px;
    transform: rotate(-90deg);
    position: absolute;

    .progress {
      height: 5px;
      background-color: $color-lightest-gray;
    }

    .progress-bar {
      border-radius: 10px;
    }
  }

  .purple {
    color: $color-brand-1;

    .progress-bar {
      background-color: $color-brand-1;
    }
  }

  .yellow {
    color: $color-brand-3;

    .progress-bar {
      background-color: $color-brand-3;
    }
  }

  .aqua {
    color: $color-brand-4;

    .progress-bar {
      background-color: $color-brand-4;
    }
  }

  .coral {
    color: $color-brand-5;

    .progress-bar {
      background-color: $color-brand-5;
    }
  }
}
