@import "./../../styles/utils/mixins";
@import "./../../styles/utils/variables";

@font-face {
  font-family: "Bookmania Semibold";
  src: url("../../styles/fonts/bookmania-semibold.otf") format("opentype");
}

.search {
  height: calc(100vh - 137px);
  overflow: scroll;
  margin-bottom: 30px;
  margin-top: 16px;

  &__exit {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    font-weight: bold;
    color: $color-font-base;
    margin-bottom: 16px;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      color: $color-font-base;
    }
  }

  &__remove {
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
    color: $color-brand-1;
    cursor: pointer;
  }

  &__filter {
    &-title {
      font-size: 14px;
      font-weight: bold;
      color: $color-brand-1;
      margin-top: 32px;
      margin-bottom: 17px;
      max-width: 1219px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    &-btns {
      max-width: 1219px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      align-items: center;
    }
  }

  &__btn {
    background: transparent !important;
    border-radius: 4px !important;
    border: 1px solid $color-brand-1 !important;
    height: 36px;
    width: 136px;
    font-size: 14px !important;
    font-weight: bold !important;
    color: $color-brand-1 !important;
    margin-right: 32px;
    margin-top: 10px;
    box-shadow: none !important;

    &.-is-active {
      background: $color-brand-1 !important;
      color: #fff !important;
    }
  }

  &__recent {
    display: flex;
    max-width: 1219px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    &-item {
      margin-right: 65px;
      font-size: 14px;
      color: $color-font-gray;
      margin-bottom: 0;
    }
  }

  &__results {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    height: auto;
    max-width: 1219px;
    width: 100%;
    margin-top: 55px;
    margin-left: auto;
    margin-right: auto;

    &-header {
      font-size: 14px;
      font-weight: 600;
      color: $color-brand-1;
      padding-top: 24px;
      padding-left: 32px;
      margin-bottom: 19px;
    }

    &-table {
      margin-left: 31px;
      margin-right: 31px;
    }
  }

  th {
    border: none !important;
    font-size: 14px;
    font-weight: 600;
  }

  td {
    font-size: 14px;
    color: $color-font-gray;
    border-top: none !important;
    border-bottom: none !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;

    &:first-child {
      width: 30%;
    }

    &:last-child {
      text-align: right;
      cursor: pointer;
    }
  }

  tr {
    border-bottom: 1px solid $color-border;

    &:last-child {
      border-bottom: none !important;
    }
  }
}

.search-user {
  height: calc(100vh - 91px);
  overflow: scroll;
  padding-top: 16px;
  padding-bottom: 30px;

  &__save {
    margin-left: 32px;
  }

  &__title {
    font-family: "Bookmania Semibold", "Roboto", sans-serif;
    font-size: 22px;
    font-weight: 600;
    max-width: 1219px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &__add {
    &-info {
      @include screen-sm {
        flex-direction: column;
      }
    }
  }

  &__container {
    background: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    height: calc(100% - 100px);
    max-width: 1219px;
    width: 100%;
    margin-top: 25px;
    overflow: scroll;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px;
  }

  &__header {
    font-size: 18px;
    font-weight: 600;
    color: $color-brand-1;
    margin-left: 32px;
    padding-top: 24px;
    margin-bottom: 24px;
  }

  &__info {
    display: flex;
    margin-left: 32px;
    margin-right: 32px;

    @include screen-md {
      flex-direction: column;
      align-items: center;
    }
  }

  &__avatar {
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 24px;
    margin-bottom: 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__avatar-placeholder {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 24px;
    margin-bottom: 20px;
    display: block;
    background: $color-font-light;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__details {
    width: 325px;
    margin-right: 32px;

    .form-group {
      margin-bottom: 23px;
    }

    input {
      font-size: 14px;
      height: 38px;
      border: 1px solid $color-font-light;

      &:focus {
        box-shadow: none;
        border-color: $color-font-light;
      }

      &::placeholder {
        font-size: 14px;
        color: $color-font-light;
      }
    }
  }

  &__description {
    textarea {
      border-radius: 5px;
      border: 1px solid $color-font-light;
      height: 221px !important;
      width: 568px;
      resize: none;
      font-size: 14px;
      padding: 8px 16px;

      &:focus {
        box-shadow: none;
        border-color: $color-font-light;
      }

      &::placeholder {
        font-size: 14px;
        color: $color-font-light;
      }
    }
  }

  &__activity {
    justify-content: space-between;
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 45px;

    @include screen-md {
      flex-direction: column;
    }
  }

  &__timeline,
  &__signed,
  &__added {
    max-width: 330px;
    width: 100%;

    @include screen-md {
      max-width: 100%;
    }
  }

  &__timeline {
    &-title {
      font-size: 18px;
      font-weight: 600;
      color: $color-brand-1;
      margin-bottom: 2px;
    }

    &-action {
      font-size: 14px;
      font-weight: bold;
      color: $color-font-gray;
    }
  }

  &__added {
    display: flex;
    flex-direction: column;
    min-width: 330px;
    text-align: right;

    @include screen-sm {
      text-align: left;
    }

    &-action {
      margin-top: 24px;
      font-size: 14px;
      font-weight: bold;
      color: $color-font-gray;
    }

    &-mark {
      margin-top: 24px;
      font-size: 14px;
      font-weight: bold;
      color: $color-brand-1;
      cursor: pointer;
    }
  }

  &__interaction {
    position: relative;
    margin-bottom: 16px;

    &-text {
      font-size: 14px;
      color: $color-font-dark;
      margin-left: 10px;
    }

    &-link {
      position: absolute;
      font-size: 14px;
      font-weight: bold;
      color: $color-brand-1;
      right: 0;
      top: 15px;
    }

    &:after {
      content: "";
      width: 2px;
      height: 16px;
      background: rgba(46, 91, 255, 0.15);
      position: absolute;
      left: 15px;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  &__interaction-content {
    margin-top: 31px;

    @include screen-md {
      max-width: 330px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__signed {
    &-file {
      background: #fff;
      border-radius: 4px;
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      height: 59px;
      width: 280px;
      margin-top: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include screen-md {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &-type {
      background: #fff;
      border-radius: 0px;
      border: 1px solid $color-lightest-gray;
      box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.1);
      height: 34px;
      width: 26px;
      font-size: 7px;
      font-weight: bold;
      color: $color-brand-5;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
    }

    &-title {
      font-size: 12px;
      color: $color-font-gray;
      margin-left: 16px;
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-remove {
      cursor: pointer;
    }

    &-download {
      font-size: 14px;
      font-weight: bold;
      color: $color-brand-1;
      margin-top: 25px;
      margin-left: 8px;
      cursor: pointer;

      @include screen-md {
        justify-content: center;
      }
    }
  }
}
