@import "../../styles/utils/mixins";
@import "../../styles/utils/_variables";

@font-face {
  font-family: "Bookmania Semibold";
  src: url("../../styles/fonts/bookmania-semibold.otf") format("opentype");
}

.status-card {
  width: 305px;
  height: 371px;
  background: #fff;
  box-shadow: 3px 3px 10px 0px $color-lightest-gray;
  border-radius: 4px;
  padding: 20px 15px;
  position: relative;

  &.-small {
    height: 300px;
  }

  @include screen-sm {
    width: 100%;
    align-items: center;
  }

  .apexcharts-legend {
    display: flex;
    flex-direction: column;
    height: auto !important;
    margin-left: auto;
    margin-right: auto;
    width: 160px;
  }

  .apexcharts-legend-series {
    justify-content: flex-start;
    font-size: 14px;
    color: $color-font-gray;
    margin-bottom: 7px !important;
  }

  &__header {
    margin-bottom: 35px;
    font-family: "Bookmania Semibold", Roboto;
    font-size: 22px;
    color: $color-font-base;
  }

  &__container {
    max-height: 230px;
    padding-top: 2px;
    overflow: scroll;
    padding-left: 3px;
    padding-right: 3px;
  }

  &__notice {
    width: 100%;
    height: 59px;
    box-shadow: 3px 3px 7px 0px $color-lightest-gray;
    border-radius: 4px;
    margin-bottom: 15px;
    padding: 5px 20px;

    &:hover {
      text-decoration: none;
    }

    &-label {
      font-size: 14px;
      font-weight: bold;
      color: $color-font-gray;
      width: 215px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include screen-sm {
        width: 200px;
      }
    }

    &-info {
      font-size: 12px;
      color: $color-font-gray;
      width: 215px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include screen-sm {
        width: 200px;
      }
    }
  }

  &__btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    position: absolute;
    bottom: 15px;
    margin-left: auto;
    margin-right: auto;
  }

  &__preview,
  &__publish {
    max-width: 136px;
    min-width: 136px;
    width: 136px;
    padding: 6.5px 0 !important;
    font-size: 14px !important;

    @include screen-sm {
      max-width: 120px;
      min-width: 120px;
    }
  }

  &__preview {
    border: 1px solid $color-brand-1 !important;
    background: transparent !important;
    color: $color-brand-1 !important;
    font-weight: 500;
    margin-right: 8px;

    &:hover {
      background: $color-lightest-gray !important;
    }

    &:focus {
      box-shadow: 0 0 0 2px $color-brand-1 !important;
    }
  }

  &__publish {
    background: $color-brand-1 !important;
    color: #fff !important;
    font-weight: 500;

    &:hover {
      background: $color-brand-2 !important;
    }

    &:focus {
      box-shadow: 0 0 0 2px $color-font-light !important;
    }
  }
}
