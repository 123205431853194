@import "../src/styles/utils/mixins";
@import "../src/styles/utils/variables";
@import "../src/styles/styles.scss";

@font-face {
  font-family: "Bookmania";
  src: url("../src/styles/fonts/bookmania-bold.otf") format("opentype");
}

@font-face {
  font-family: "Bookmania Semibold";
  src: url("../src/styles/fonts/bookmania-semibold.otf") format("opentype");
}

body {
  color: $color-font-base !important;
}

.image-upload-placeholder {
  background: #b0bac9;
  box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
  height: 63px;
  width: 63px;
  display: block;
  border-radius: 50%;
  cursor: pointer;
}

.Toastify__toast--default {
  background: rgb(51, 51, 51);
  border-radius: 4px;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  left: 122px;
  font-size: 14px;
  font-weight: 600;
  padding-left: 24px;
  min-height: 48px !important;
  height: 48px !important;
  padding-top: 0;
  padding-bottom: 0;
  width: 392px;

  @include screen-sm {
    bottom: 15px;
    width: calc(100% - 80px);
    max-width: 392px;
    left: 75px;
  }
}

.Toastify__toast-body {
  color: #fff;
}

.Toastify__close-button--default {
  color: $color-light-gray;
  opacity: 1;
}

.avatar-placeholder {
  width: 45px;
  height: 45px;
  background: $color-lightest-gray;
  font-size: 14px;
  font-weight: 600;
  color: $color-font-gray;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 16px;
}

.dashboard {
  margin-left: 77px;
  background: $color-light-gray;
  font-family: "Roboto", sans-serif;
  overflow-y: hidden;

  @include screen-sm {
    margin-left: 70px;
  }
}

.right-menu {
  padding: 10px 0;

  &__close {
    color: $color-font-gray;
    text-align: right;
    outline: none;
    cursor: pointer;
  }

  &__container {
    margin-left: 24px;
    margin-right: 24px;
  }

  &__title {
    color: $color-font-base;
    font-size: 22px;
    letter-spacing: -0.16px;
    font-weight: 600;
    font-family: "Bookmania", Roboto;
    margin-bottom: 30px;
  }

  &__restart {
    border-radius: 4px;
    height: 40px;
    width: 223px;
    background: $color-brand-1 !important;
    font-size: 14px !important;
    font-weight: bold !important;
    margin-bottom: 32px;
  }

  &__progress-bar {
    height: 5px !important;
    border-radius: 10px !important;
    background: $color-lightest-gray !important;
    margin-bottom: 5px;

    .progress-bar {
      background: $color-brand-1;
    }
  }

  &__progress-stats {
    color: $color-font-gray;
    font-size: 12px;
  }

  &__checklist {
    margin-left: 24px;
    margin-right: 24px;
  }
}

.bm-cross-button {
  display: none;
}

.bm-menu-wrap {
  background: #fff;
  border-radius: 1px;
  border: 1px solid rgba(46, 91, 255, 0.08);
  border-top: none;
  width: 310px;
  z-index: 3 !important;
  height: calc(100% - 90px) !important;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);

  @include screen-sm {
    height: calc(100% - 53px) !important;
  }
}

.bm-burger-button {
  display: none !important;
}

.info {
  padding: 0 77px;
  height: calc(100vh - 91px);
  overflow: scroll;
  padding-bottom: 30px;

  @include screen-md {
    padding: 0 30px;
  }

  @include screen-sm {
    padding: 0 10px;
    overflow-x: hidden;
    height: calc(100vh - 84px);
  }

  &__container {
    flex-direction: row;
    max-width: 1219px;
    margin-left: auto;
    margin-right: auto;

    @include screen-md {
      flex-direction: column;
    }

    @include screen-sm {
      padding-left: 0 !important;
    }
  }

  &__overview {
    width: 100%;

    &-header {
      font-family: "Bookmania Semibold", Roboto;
      font-size: 22px;
      color: $color-font-base;
      margin-left: -15px;

      @include screen-sm {
        margin-left: 0px;
      }

      @include screen-md {
        margin-left: 30px;
      }
    }
  }

  &__legal {
    min-width: 305px;

    @include screen-md {
      margin-top: 35px !important;
      max-width: 305px;
    }

    @include screen-sm {
      min-width: auto;
      width: 100%;
    }
  }
}

/*SPINNER ICON*/
background {
  width: 100vw;
  height: 100vh;
  background: #faffb8;
  --amount: 20;
}

$animationDuration: 3s;
$amount: 6;
$particleRadius: 4vmin;
.background span {
  width: $particleRadius * 2;
  height: $particleRadius * 2;
  border-radius: $particleRadius;
  backface-visibility: hidden;
  position: absolute;
  animation-name: move;
  animation-timing-function: cubic-bezier(0.4, 0, 1, 0.8);
  animation-iteration-count: infinite;
  animation-duration: $animationDuration;
  top: calc(50% - #{$particleRadius});
  left: 50%;
  transform-origin: ($particleRadius * -1) center;

  $colors: (#c5f0a4, #35b0ab, #226b80);

  @for $i from 1 through $amount {
    &:nth-child(#{$i}) {
      background: nth($colors, random(length($colors)));
      animation-delay: ($i / $amount) * $animationDuration * -1;
      opacity: 0;
    }
  }
}

@keyframes move {
  0% {
    transform: scale(1) rotate(0deg) translate3d(0, 0, 1px);
  }
  30% {
    opacity: 1;
  }
  100% {
    z-index: 10;
    transform: scale(0) rotate(360deg) translate3d(0, 0, 1px);
  }
}
