@import "./../../styles/utils/mixins";
@import "./../../styles/utils/variables";

@font-face {
  font-family: "Bookmania Semibold";
  src: url("../../styles/fonts/bookmania-semibold.otf") format("opentype");
}

.login {
  display: flex;
  flex-direction: row;
  height: 100vh;

  @include screen-xs {
    flex-direction: column;
  }

  &__terms {
    color: $color-font-gray;
    font-size: 12px;

    &-link {
      color: $color-font-gray;
      font-size: 12px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__banner,
  &__form {
    flex: 1;
  }

  &__form {
    @include screen-xs {
      flex: 2;
    }
  }

  &__error {
    position: absolute !important;
    top: 20px;
    max-width: 330px;
    min-width: 330px;
  }

  &__banner {
    background: $color-brand-1;
    padding-top: 32px;
    background-image: url("../../images/Login/bg2.png"),
      url("../../images/Login/Lines.png");
    background-size: 50%, 100%;
    background-repeat: no-repeat;
    background-position: center;

    @include screen-xs {
      padding-top: 12px;
    }

    &-logo {
      margin-left: 56px;

      @include screen-xs {
        margin-left: 12px;
      }
    }
  }

  &__form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    &-container {
      max-width: 330px;
      min-width: 330px;

      &.-check {
        h2 {
          white-space: nowrap;
        }
      }
    }

    &-link {
      color: $color-brand-1;
    }

    &-tabs {
      margin-bottom: 32px;
    }

    &-check {
      border-radius: 4px;
      border: 1px solid $color-font-light;
      display: block;
      min-height: 13px;
      min-width: 13px;

      &.-is-active {
        background: $color-brand-1;
      }

      &-label {
        font-size: 14px;
        color: $color-font-gray;
        margin-right: 48px;
        width: 50%;
        margin-bottom: 24px;

        p {
          font-size: 12px;
          color: $color-font-gray;
          position: relative;
          top: -3px;
        }

        input {
          height: 38px !important;
          min-width: 325px !important;
          border-radius: 5px !important;
          border: 1px solid $color-font-light !important;
        }
      }
    }

    &-check-label {
      font-size: 14px;
      font-weight: bold;
      color: $color-font-gray;
      margin-bottom: 8px;
    }

    &-tab {
      font-size: 14px;
      font-weight: bold;
      color: $color-font-gray;
      margin-right: 48px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      &.-is-active {
        color: $color-brand-1;
      }
    }

    &-place {
      font-size: 14px;
      color: $color-font-gray;
      margin-bottom: 94px;
    }

    &-title {
      font-family: "Bookmania Semibold", "Roboto", sans-serif;
      font-size: 44px;
      letter-spacing: -0.6px;
      text-align: left;
      margin-bottom: 24px;
    }

    &-return {
      font-size: 14px;
      color: $color-brand-1;
      cursor: pointer;
    }

    &-create {
      font-size: 14px;
      color: $color-font-gray;
      margin-bottom: 79px;

      .create {
        color: $color-brand-1;
        cursor: pointer;
      }
    }

    &-log {
      width: 136px;
    }

    &-forgot {
      font-size: 14px;
      color: $color-font-gray;
      cursor: pointer;
    }

    input {
      margin-bottom: 32px;
    }
  }
}
