@import "./../../styles/utils/mixins";
@import "./../../styles/utils/variables";

.history-free {
  height: calc(100vh - 90px);
  overflow: scroll;
  background: #fff;
  display: flex;

  @include screen-sm {
    height: calc(100vh - 53px);
    flex-direction: column-reverse;
  }

  &__banner {
    width: 30%;
    background: $color-brand-1;
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include screen-sm {
      width: 100%;
    }

    img {
      position: relative;
      max-width: 478px;
      left: -60px;

      @include screen-sm {
        left: 0;
        top: 0;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: 100%;
        padding-bottom: 10px;
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 70%;
    padding: 10px;

    @include screen-sm {
      width: 100%;
    }

    ul {
      padding: 0;

      li {
        list-style-type: none;
        font-size: 16px;
        color: $color-font-gray;
        display: flex;
        align-items: center;
        margin-bottom: 25px;
      }
    }

    &-upgrade {
      max-width: 136px;
    }

    &-title {
      font-family: "Bookmania Semibold", "Roboto", sans-serif;
      letter-spacing: -0.2px;
      font-size: 28px;
      margin-bottom: 24px;

      @include screen-sm {
        text-align: center;
      }
    }

    &-subtitle {
      font-size: 16px;
      max-width: 483px;
      color: $color-font-gray;
      margin-bottom: 67px;
      text-align: center;
    }

    &-icon {
      width: 20px;
      height: 20px;
      margin-right: 16px;
    }
  }
}

.history {
  height: calc(100vh - 90px);
  overflow: scroll;
  padding-left: 15px;
  padding-right: 15px;

  .rt-th {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
    border: none !important;
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    text-align: left;

    &:first-child {
      width: 40px !important;
    }

    &:last-child {
      width: 5px !important;
    }
  }

  .pagination-bottom {
    background: #fff;
  }

  .-pagination {
    box-shadow: none !important;
  }

  .ReactTable {
    border: none;
  }

  .rt-thead {
    box-shadow: none !important;
  }

  .rt-td {
    border: none !important;
    font-size: 14px;
    color: $color-font-dark;
    z-index: 0;
    padding: 12px !important;

    &:first-child {
      width: 40px !important;
    }

    &:last-child {
      width: 5px !important;
    }
  }

  .rt-noData {
    width: 100%;
    background: #fff;
    text-align: center;
    position: static;
    transform: none;
  }

  .-next,
  .-previous {
    height: 40px !important;
    width: 223px !important;
    border-radius: 4px !important;
    flex: none !important;

    .-btn {
      color: #fff !important;
      font-size: 14px !important;
      font-weight: bold !important;
      border-radius: 4px !important;
      height: 40px !important;
      width: 223px !important;
      background-color: $color-brand-1 !important;
    }
  }

  .rt-table {
    overflow: visible;
  }

  .rt-tbody {
    background: #fff;
  }

  .rt-tr-group {
    border: none !important;

    &:hover {
      background: $color-light-gray;
    }
  }

  .nav-tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.-has-banner {
    height: calc(100vh - 155px);

    @include screen-sm {
      height: calc(100vh - 117px);
    }
  }

  .-month {
    position: relative;
    top: -20px;
  }

  &__no-data {
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    position: absolute;
    bottom: 0px;
    font-size: 16px !important;
    margin-top: 109px;
    width: 100px;
    height: 20px;
    min-height: 20px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    background: transparent !important;
  }

  .dropdown-menu {
    width: 150px;
    max-width: 150px;
    min-width: 150px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: $color-lightest-gray;
    margin-top: 0;

    .dropdown-item {
      padding: 10px 23px;
      font-size: 14px;
      color: $color-font-gray;

      &:hover {
        background: $color-light-gray;
        color: $color-font-base;
      }

      &:active {
        outline: none;
      }
    }
  }

  &__toggle {
    font-size: 14px !important;
    font-weight: bold !important;
    color: $color-font-base !important;
    padding: 0 !important;
    border: none !important;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  &__icon {
    cursor: pointer;
    min-width: 15px;
  }

  &.-is-sample {
    height: calc(100vh - 142px);
  }

  &__container {
    margin-top: 48px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 20px !important;
    position: relative;
  }

  .search-input {
    margin-left: 0;
  }

  &__filter {
    &-title {
      font-size: 14px;
      font-weight: bold;
      color: $color-brand-1;
      margin-top: 32px;
      margin-bottom: 17px;
      max-width: 1219px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    &-btns {
      max-width: 1219px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__btn {
    background: transparent !important;
    border-radius: 4px !important;
    border: 1px solid $color-brand-1 !important;
    height: 36px;
    width: 136px;
    font-size: 14px !important;
    font-weight: bold !important;
    color: $color-brand-1 !important;
    margin-right: 28px;
    margin-top: 10px;
    box-shadow: none !important;

    &.-is-active {
      background: $color-brand-1 !important;
      color: #fff !important;
    }
  }

  &__form-group {
    position: relative;
  }

  &__input-icon {
    right: 5px;
    top: 13px;
  }

  &__share-container {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    height: 160px;
    max-width: 255px;
    padding: 16px;
    margin-left: 46px;
    margin-right: auto;

    select {
      border-radius: 5px;
      border: 1px solid $color-font-light;
      height: 38px;
      margin-bottom: 8px;
      padding-right: 25px;
      font-size: 14px;
      color: $color-font-light;

      &:focus {
        box-shadow: none;
        border: 1px solid $color-font-light;
        color: $color-font-light;
      }
    }

    select.form-control {
      appearance: none;
    }
  }

  &__share,
  &__download {
    font-size: 14px;
    font-weight: bold;
    color: $color-brand-1;
    cursor: pointer;
  }

  &__date {
    font-size: 14px;
    font-weight: bold;
    color: $color-font-gray;
    text-align: right;
    display: block;
    margin-top: 27px;
    margin-bottom: 28px;
    width: 90% !important;
    max-width: 1265px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &__activity {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    position: relative;
    flex: 1;
    width: 90%;

    &-container {
      max-width: 1265px;
      display: flex;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      width: 90%;
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-bottom: 35px;
      min-width: 850px !important;
    }

    &-date {
      font-size: 14px;
      font-weight: bold;
      margin-right: 26px;
      color: $color-font-gray;

      .-month {
        font-size: 12px;
        font-weight: normal;
      }
    }

    .nav-tabs {
      padding-left: 32px;
      padding-right: 32px;
      overflow-x: auto;
      flex-wrap: nowrap;
      overflow-y: hidden;

      .nav-link {
        padding: 21px 0;
        border: none;
        font-size: 14px;
        font-weight: bold;
        color: $color-font-gray;
        cursor: pointer;
        margin-right: 72px;
        white-space: nowrap;

        &:hover {
          color: $color-font-gray;
        }

        &.active,
        &:hover {
          border: none;
        }

        &.active {
          color: $color-brand-1;
          position: relative;

          &:hover {
            color: $color-brand-1;
          }

          &:after {
            content: "";
            width: 100%;
            height: 3px;
            background: $color-brand-1;
            position: absolute;
            display: block;
            bottom: 0;
            left: 0px;
          }
        }
      }
    }

    table {
      margin-bottom: 24px;
    }

    thead {
      background: #fff;

      th {
        top: 0;
        background: #fff;
      }
    }

    th {
      border: none !important;
      font-size: 14px;
      font-weight: bold;
      padding-top: 24px !important;
      padding-bottom: 24px !important;
      vertical-align: top !important;
      white-space: nowrap;
    }

    td {
      font-size: 12px;
      color: $color-font-dark;
      border: none !important;
      white-space: nowrap;
    }
  }

  &__table-container {
    padding-left: 32px !important;
    padding-right: 32px;
    overflow: visible;

    tr {
      &:hover {
        background: $color-light-gray;
      }
    }
  }

  &__table-download {
    min-width: 110px;
    text-align: right;
  }

  &__empty {
    &-container {
      background: rgb(255, 255, 255);
      border-radius: 0px;
      height: 560px;
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      margin-top: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    &-img {
      margin-bottom: 58px;
    }

    &-title {
      font-size: 18px;
      font-weight: 600;
      color: $color-brand-1;
      margin-bottom: 37px;
    }

    &-text {
      font-size: 14px;
      color: $color-font-gray;
      margin-bottom: 32px;
      text-align: center;
    }
  }
}
