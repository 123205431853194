@import "./../../styles/utils/mixins";
@import "./../../styles/utils/variables";

@font-face {
  font-family: "Bookmania Semibold";
  src: url("../../styles/fonts/bookmania-semibold.otf") format("opentype");
}

.data-processors {
  height: calc(100vh - 90px);
  overflow: scroll;

  &.-has-banner {
    height: calc(100vh - 155px);

    @include screen-sm {
      height: calc(100vh - 117px);
    }
  }

  &.-is-sample {
    height: calc(100vh - 142px);
  }

  @include screen-sm {
    height: calc(100vh - 53px);
  }

  &__keep {
    margin-left: 32px;
    font-size: 16px;
    color: $color-font-gray;

    @include screen-sm {
      margin-left: 0;
      margin-right: 12px;
    }
  }

  &__check {
    border-radius: 4px;
    border: 1px solid $color-font-light;
    display: block;
    height: 13px;
    width: 13px;

    &.-is-active {
      background: $color-brand-1;
    }
  }

  .-show-on-mobile {
    display: none;

    @include screen-xs {
      display: block;
    }
  }

  @include screen-xs {
    flex-direction: column;
  }

  &__btn {
    min-height: 40px !important;
    min-width: 223px !important;
    margin-bottom: 32px;
  }

  &__added-content {
    background: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    min-height: 406px;
    margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__cookie-content {
    background: rgb(255, 255, 255);
    border-radius: 4px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.-has-border {
      border-top: 1px solid #dfe4ee;
    }
  }

  &__placeholder {
    height: 90px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: $color-lightest-gray;
    color: $color-font-base;
    font-size: 20px;

    &:hover {
      text-decoration: none;
      color: $color-font-base;
    }
  }

  &__back {
    color: $color-brand-1 !important;
    margin-top: 42px;
    margin-left: auto;
    margin-right: auto;
    display: block !important;
    border: none !important;
    font-weight: bold !important;
    text-align: center;
  }

  &__add {
    &-content {
      background: #fff;
      border-radius: 4px;
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      min-height: 666px;
      margin-top: 48px;
      padding: 0 10px;
    }

    &-subtitle {
      font-size: 16px;
      color: $color-font-dark;
      margin-bottom: 48px;
      max-width: 785px;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }

    &-title {
      font-family: "Bookmania Semibold", "Roboto", sans-serif;
      font-size: 22px;
      text-align: center;
      padding-top: 32px;
      margin-bottom: 24px;
    }

    &-text {
      font-size: 18px;
      width: 95%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      color: $color-font-gray;
      margin-bottom: 40px;
    }

    &-items {
      max-width: 771px;
      margin-left: auto;
      margin-right: auto;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &-img {
      margin-left: auto;
      margin-right: auto;
      display: block;
      margin-bottom: 56px;
    }

    &-form {
      min-width: 325px;
      max-width: 325px;
      margin-left: auto;
      margin-right: auto;

      @include screen-sm {
        min-width: 100%;
      }
    }

    &-cancel,
    &-next {
      width: 136px !important;
    }

    &-cancel {
      margin-right: 16px;
    }

    &-item {
      background: rgb(255, 255, 255);
      border-radius: 4px;
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      height: 426px;
      width: 324px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      padding-bottom: 10px;

      img {
        width: 132px;
        margin-bottom: 51px;
      }

      &-title {
        color: $color-brand-1;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 51px;
        margin-top: 24px;
      }

      &-text {
        font-size: 14px;
        color: $color-font-gray;
        width: 187px;
        text-align: center;
      }
    }
  }

  &__filter {
    min-width: 310px;
    max-width: 310px;
    background: rgb(255, 255, 255);
    border-radius: 1px;
    border: 1px solid rgba(46, 91, 255, 0.08);
    height: 100%;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);

    @include screen-xs {
      height: auto;
      width: 100%;
      max-width: 100%;
    }

    &-title {
      margin-top: 49px;
      margin-bottom: 30px;
      margin-left: 72px;
      font-size: 22px;
      font-weight: bold;
      display: flex;
      align-items: center;
      color: $color-brand-1;

      @include screen-md {
        margin-left: 10px;
      }

      @include screen-xs {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    &-item {
      height: 55px;
      display: flex;
      align-items: center;
      padding-left: 73px;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;

      @include screen-md {
        padding-left: 10px;
      }

      &.-is-active {
        color: $color-brand-1;
        background: $color-light-gray;
      }
    }

    &-container {
      height: calc(100% - 113px);
      overflow: scroll;

      @include screen-xs {
        height: calc(100% - 54px);
      }
    }
  }

  &__content {
    max-height: 100%;
    overflow: scroll;
    height: 100%;
    width: 100%;
  }

  &__title {
    font-size: 14px;
    text-align: center;
    max-width: 785px;
    color: $color-font-dark;
    margin-top: 48px;
    margin-bottom: 43px;

    @include screen-md {
      width: 90%;
    }
  }

  &__item {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    height: 327px;
    width: 302px;
    position: relative;
    padding-left: 24px;
    padding-right: 24px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 88px;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    &-title {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 24px;
      color: $color-font-base;
    }

    &-info {
      text-align: center;
      font-size: 14px;
      color: $color-font-dark;
    }

    &-content {
      padding-top: 87px;
    }

    &-edit {
      font-size: 14px;
      font-weight: bold;
      color: $color-brand-1;
      text-align: center;
      display: block;
      margin-bottom: 16px;

      &:hover {
        color: $color-brand-1;
      }
    }
  }

  &__logo {
    background: #fff;
    border-radius: 10px;
    box-shadow: 2px 4px 16px 0px rgba(51, 51, 51, 0.08);
    height: 103px;
    width: 254px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -51px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    img {
      height: 90px;
      object-fit: contain;
    }
  }

  &__current {
    max-width: 1002px;

    &-title {
      margin-top: 46px;
      margin-bottom: 90px;
      text-align: center;
      font-size: 22px;
      font-weight: bold;
      letter-spacing: -0.16px;
    }

    &-container {
      justify-content: center;

      @media (max-width: 1378px) {
        flex-wrap: wrap;
      }
    }
  }

  &__add {
    max-width: 1002px;
  }

  &__pagination {
    margin-top: -40px;
    margin-bottom: 45px;
    display: flex;

    &-link {
      font-size: 14px;
      font-weight: normal;
      background: #fff;
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      max-height: 36px;
      max-width: 36px;
      min-height: 36px;
      min-width: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-left: 12px;
      margin-right: 12px;
      padding: 10px;
      cursor: pointer;
    }
  }
}

.data-processors-free {
  height: calc(100vh - 90px);
  overflow: scroll;
  background: #fff;
  display: flex;

  @include screen-sm {
    height: calc(100vh - 53px);
    flex-direction: column-reverse;
  }

  &__banner {
    width: 30%;
    background: $color-brand-1;
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include screen-sm {
      width: 100%;
    }
  }

  &__img1 {
    max-width: 405px;

    @include screen-sm {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }

  &__img2 {
    max-width: 289px;
    position: relative;
    left: -80px;
    top: -60px;

    @include screen-sm {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      left: 0;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 70%;
    padding: 10px;

    @include screen-sm {
      width: 100%;
    }

    ul {
      padding: 0;
      li {
        list-style-type: none;
        font-size: 16px;
        color: $color-font-gray;
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        @include screen-sm {
          svg {
            min-width: 20px;
            height: 20px;
          }
        }
      }
    }

    &-upgrade {
      max-width: 136px;
    }

    &-title {
      font-family: "Bookmania Semibold", "Roboto", sans-serif;
      letter-spacing: -0.2px;
      font-size: 28px;
      margin-bottom: 24px;

      @include screen-sm {
        text-align: center;
      }
    }

    &-subtitle {
      font-size: 16px;
      max-width: 483px;
      color: $color-font-gray;
      margin-bottom: 67px;
      text-align: center;
    }

    &-icon {
      width: 20px;
      height: 20px;
      margin-right: 16px;
    }
  }
}

.data-processor {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  max-width: 1219px;
  height: auto;
  padding-bottom: 30px;
  width: 100%;
  padding-top: 48px;
  overflow: scroll;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  &__purpose {
    width: 325px !important;
    margin-bottom: 63px;
  }

  &__add-other {
    margin-bottom: 0;
    color: $color-brand-1;
    font-size: 14px;
    font-weight: bold;
    margin-left: 9px;
    cursor: pointer;
    width: auto;
  }

  &__header {
    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    &-title {
      font-size: 22px;
      font-family: "Bookmania Semibold", "Roboto", sans-serif;
      letter-spacing: -0.16px;
    }

    &-text {
      font-size: 14px;
      margin-bottom: 79px;
    }

    &-label {
      margin-top: 38px;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    &-api {
      margin-bottom: 70px;

      @include screen-sm {
        flex-wrap: wrap;
      }

      .form-control[readonly] {
        background-color: #fff;
        color: $color-font-gray !important;
      }
    }
  }

  &__add {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    min-height: 555px;
    margin-top: 18px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &.-small {
      min-height: 406px;
    }

    &-cloud {
      position: absolute;
      width: 31px !important;
      margin-bottom: 0 !important;
      top: -15px;
      left: -15px;
    }

    &-check2 {
      position: absolute;
      margin-bottom: 0 !important;
      width: 22px !important;
      bottom: -18px;
      right: -27px;
    }

    &-check {
      position: absolute;
      width: 17px !important;
      margin-bottom: 0 !important;
      left: -30px;
      top: 30px;
    }

    &-title {
      font-family: "Bookmania Semibold", "Roboto", sans-serif;
      font-size: 22px;
      font-weight: 600;
      letter-spacing: -0.16px;
      margin-bottom: 16px;
    }

    &-text {
      font-size: 14px;
      color: $color-font-gray;
      margin-bottom: 48px;
      width: 423px;
      text-align: center;
    }

    img {
      margin-bottom: 56px;
      width: 119px;
    }

    form {
      max-width: 325px;
      width: 100%;
    }

    &-btn {
      width: 136px !important;
    }

    &-logo {
      background: #fff;
      border-radius: 10px;
      box-shadow: 2px 4px 16px 0px rgba(51, 51, 51, 0.08);
      height: 85px;
      width: 183px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;

      &-container {
        margin-bottom: 49px;
      }

      img {
        max-height: 42px;
        width: auto;
        height: 100%;
        margin-bottom: 0;
        object-fit: cover;
      }
    }

    &-link {
      font-size: 14px;
      font-weight: bold;
      color: $color-brand-1;
      cursor: pointer;

      &:hover {
        text-decoration: none;
        color: $color-brand-1;
      }
    }

    &-name {
      margin-bottom: 0;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.11px;
    }
  }

  &__file {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    height: 59px;
    width: 280px;
    padding: 13px 10px;
    display: flex;
    align-items: center;

    &-type {
      border-radius: 0px;
      border: 1px solid rgb(223, 228, 238);
      box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.1);
      min-height: 34px;
      min-width: 26px;
      font-size: 7px;
      font-weight: bold;
      color: $color-brand-4;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
    }

    &-name {
      margin-bottom: 0;
      font-size: 12px;
      color: $color-font-gray;
      width: 195px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-remove {
      cursor: pointer;
    }
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__api {
    border-top: 1px solid $color-lightest-gray;
    margin: 39px 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 40px;

    &-title {
      font-family: "Bookmania Semibold", "Roboto", sans-serif;
      font-size: 22px;
      letter-spacing: -0.16px;
      margin-bottom: 16px;
    }

    &-subtitle {
      font-size: 14px;
      color: $color-font-gray;
      margin-bottom: 48px;
      max-width: 785px;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }

    &-text {
      font-size: 16px;
      margin-bottom: 57px;
      color: $color-font-gray;
      text-align: center;
      width: 85%;
    }

    img {
      margin-bottom: 65px;
    }

    form {
      width: 568px;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &-btn {
      width: 136px !important;
    }
  }

  &__company-details {
    border-top: 1px solid $color-lightest-gray;
    margin: 39px 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 40px;

    &-title {
      font-family: "Bookmania Semibold", "Roboto", sans-serif;
      font-size: 22px;
      letter-spacing: -0.16px;
      margin-bottom: 16px;
    }

    &-text {
      font-size: 16px;
      margin-bottom: 57px;
      color: $color-font-dark;
      text-align: center;
      width: 85%;
    }

    &-text-secondary {
      font-size: 14px;
      width: 187px;
      color: $color-font-dark;
      text-align: center;
    }

    img {
      margin-bottom: 65px;
    }

    form {
      width: 325px;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &-btn {
      width: 136px !important;
    }
  }

  &__btns {
    justify-content: center;
    display: flex;

    .btn {
      margin-left: 12px;
      margin-right: 12px;
    }
  }

  &__data {
    margin-left: 30px;
    display: flex;
    margin-bottom: 54px;

    &-item {
      background: #fff;
      border-radius: 4px;
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      height: 318px;
      width: 265px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      img {
        min-height: 133px;
      }

      &:first-child {
        margin-right: 56px;
      }
    }

    &-text {
      font-size: 14px;
      max-width: 187px;
      color: $color-font-gray;
      text-align: center;
      margin-top: 17px;
      margin-bottom: 13px;
    }
  }

  &__form {
    margin-top: 64px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 65px;

    .form-group {
      width: 100%;
    }

    input {
      height: 38px;
      max-width: 568px;
      width: 95% !important;
      display: flex;
      flex: 1;
    }

    label {
      font-size: 22px;
      font-weight: 600;
      letter-spacing: -0.16px;
    }
  }

  &__logo {
    background: #fff;
    border-radius: 10px;
    box-shadow: 2px 4px 16px 0px rgba(51, 51, 51, 0.08);
    height: 180px;
    width: 387px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    img {
      height: 90px;
      object-fit: cover;
    }

    @include screen-xs {
      width: 100%;
    }
  }

  &__description {
    font-size: 18px;
    color: $color-font-dark;
    max-width: 696px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 48px;
    margin-bottom: 32px;

    @include screen-md {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__connect {
    border-radius: 4px !important;
    height: 40px;
    width: 223px;
    background: $color-brand-1 !important;
    font-size: 14px !important;
    font-weight: bold !important;
    display: block !important;
    margin-left: auto;
    margin-right: auto;
  }

  &__title {
    font-size: 22px;
    font-weight: bold;
  }

  &__info {
    margin-top: 87px;
    margin-bottom: 48px;
    flex-wrap: wrap;

    @media (max-width: 1120px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @include screen-sm {
      margin-top: 0;
    }

    &-img,
    &-content {
      flex: 1;
      padding: 0px 6.5%;
      justify-content: center;
      display: flex;
      flex-direction: column;

      span {
        max-width: 452px;
        width: 100%;
        background: $color-font-gray;
        height: 323px;
        border-radius: 10px;
      }

      img {
        object-fit: contain;
        max-width: 452px;
        width: 100%;
      }

      @include screen-sm {
        width: 100%;

        img {
          object-fit: contain;
          max-width: 452px;
          width: 100%;
        }
      }

      @media (max-width: 11120px) {
        margin-top: 20px;
      }
    }

    &-title {
      font-size: 18px;
      font-weight: 600;
    }

    &-text {
      font-size: 14px;
      color: $color-font-gray;
    }
  }
}
