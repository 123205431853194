@import "../../styles/utils/mixins";
@import "../../styles/utils/variables";

.checklist-item {
  display: flex;
  justify-content: flex-start;
  margin-top: 40px;

  &__title,
  &__info {
    margin-bottom: 0;
  }

  &__container {
    &:hover {
      text-decoration: none;
    }
  }

  &__title {
    color: $color-font-base;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 7px;
    margin-left: 16px;

    &.-is-checked {
      text-decoration: line-through;
    }
  }

  &__info {
    color: $color-font-gray;
    font-size: 12px;
    font-weight: normal;
    margin-left: 16px;

    &:hover {
      text-decoration: none;
      color: $color-font-gray;
    }
  }
}
