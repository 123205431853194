@import './../../styles/utils/mixins';
@import './../../styles/utils/variables';

@font-face {
	font-family: 'Bookmania Semibold';
	src: url('../../styles/fonts/bookmania-semibold.otf') format('opentype');
}

.navigationBar {
	height: 90px;
	padding: 0 !important;
	color: $color-font-base;
	background-color: #fff !important;
	border-bottom: 1px solid rgb(223, 228, 238);
	padding-left: 72px !important;
	padding-right: 72px !important;

	@include screen-md {
		height: 90px;
	}

	@include screen-sm {
		padding: 0 10px !important;
		height: auto;
	}

	&__content-menu {
		height: 211px;
		overflow: scroll;
	}

	.navbar-collapse {
		background: #fff;
		z-index: 900;

		.navbar-nav {
			display: flex;
			flex-direction: row;
			align-items: flex-start !important;
			justify-content: center;
		}
	}

	.navbar-toggler {
		&:focus {
			outline: none;
		}
	}

	&__inbox {
		height: 41.27px;
		display: flex;
		align-items: center;
	}

	&__notification {
		white-space: initial;
	}

	&__default-avatar,
	&__default-avatar2 {
		background: $color-font-light;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
		color: #fff;
	}

	&__default-avatar {
		width: 45px;
		height: 45px;
	}

	&__default-avatar2 {
		width: 32px;
		height: 32px;
	}

	&__container {
		max-width: 1219px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		width: 100%;
	}

	&__brand {
		font-size: 28px !important;
		font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
	}

	&__time-ago {
		font-size: 12px;
		color: $color-font-gray;
	}

	&__notifications-dropdown {
		min-width: 275px !important;
		left: -75px !important;
		max-height: 313px;

		@include screen-sm {
			left: 0 !important;
			right: 0 !important;
			margin-left: auto !important;
			margin-right: auto !important;
			min-width: 250px !important;
			width: 250px !important;
		}

		&.dropdown-menu:before {
			right: 125px !important;

			@include screen-sm {
				right: 203px !important;
			}
		}

		&.dropdown-menu:after {
			right: 125px !important;

			@include screen-sm {
				right: 203px !important;
			}
		}
	}

	&__header {
		font-size: 14px;
		font-weight: 600;
		color: #fff !important;
		margin-left: 5px;
	}

	&__dropdown {
		&-avatar {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&-name {
			color: #fff;
			font-size: 14px;
			margin-left: 16px;
			margin-bottom: 10px;
		}

		&-email {
			color: $color-font-gray;
			font-size: 12px;
			margin-bottom: 0;
			margin-left: 16px;
		}

		&-item {
			padding: 16px 0 !important;
			color: #fff !important;
			border-bottom: 1px solid #b0bac9 !important;
			width: calc(100% - 32px) !important;
			margin-left: auto;
			margin-right: auto;
			font-size: 12px !important;
			font-family: Helvetica;

			a {
				color: #fff !important;
				&:hover {
					color: #fff;
				}
			}

			&:active {
				color: #fff !important;
				background: #333 !important;
			}

			&:last-child {
				border-bottom: none !important;
			}

			&.-notifications {
				padding: 8px 0 !important;
			}

			&.-no-notifications {
				height: 250px;
				align-items: center;
				justify-content: center;
				font-size: 14px !important;
				font-weight: bold;
				flex-direction: column;
				position: absolute;
				top: 10px;
				left: 0;
				right: 0;
				margin-left: auto;
				margin-right: auto;
				z-index: -1;

				img {
					margin-bottom: 30px;
				}
			}

			&:hover {
				background-color: $color-dark-gray !important;
				color: #fff !important;
			}

			&.-see-all {
				font-size: 14px;
				font-weight: 600;
				text-align: center;
			}
		}

		&-icon {
			max-width: 13px;
			min-width: 13px;
			margin-right: 16px;

			svg {
				fill: #fff;
			}

			color: #fff;
		}
	}

	&__avatar {
		width: 45px;
		height: 45px;
		border-radius: 50%;
		overflow: hidden;
		margin-right: 8px;
		margin-left: 40px;

		@include screen-sm {
			margin-left: 20px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__user-name {
		font-size: 14px;
		color: $color-font-gray !important;
		max-width: 250px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__notifications {
		margin-left: 50px;

		@include screen-sm {
			margin-left: 30px;
		}

		&-alert {
			width: 10px;
			height: 10px;
			background: $color-brand-5;
			box-shadow: 3px 3px 4px 0px rgba(248, 107, 103, 0.1);
			display: block;
			border-radius: 50%;
			position: absolute;
			top: 9px;
			right: 15px;
		}
	}

	.dropdown.show {
		.navigationBar__user-name {
			color: $color-font-base !important;
		}
	}

	.dropdown-menu-right {
		width: 195px;

		@include screen-sm {
			position: absolute !important;
		}
	}

	.dropdown-item {
		color: $color-font-gray;
		font-size: 14px;

		&:hover,
		&:active {
			color: $color-font-base;
			cursor: pointer;
			background: $color-light-gray;
			outline: none;
		}
	}

	.dropdown-menu {
		width: 272px;
		background: $color-dark-gray;
		padding-bottom: 0;
		box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.5);
	}

	.dropdown-divider {
		margin-bottom: 0;
	}

	.dropdown-menu:before {
		position: absolute;
		top: -7px;
		right: 9px;
		display: inline-block;
		border-right: 7px solid transparent;
		border-bottom: 7px solid $color-dark-gray;
		border-left: 7px solid transparent;
		border-bottom-color: $color-dark-gray;
		content: '';
	}

	.dropdown-menu:after {
		position: absolute;
		top: -5px;
		right: 10px;
		display: inline-block;
		border-right: 6px solid transparent;
		border-bottom: 6px solid $color-dark-gray;
		border-left: 6px solid transparent;
		content: '';
	}
}
