@import "../../styles/utils/mixins";
@import "../../styles/utils/_variables";

.chart {
  background: #fff;
  margin-top: 30px;
  box-shadow: 3px 3px 10px 0px $color-lightest-gray;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 20px;

  @include screen-md {
    min-height: 477px;
    overflow-y: scroll;
    width: 100%;
  }

  .apexcharts-gridRow {
    fill: #fff;
  }

  .apexcharts-toolbar {
    display: none;
  }

  .apexcharts-tooltip {
    background: #fff;
    color: $color-font-gray;
  }

  &__legend {
    color: $color-font-dark;
    font-size: 14px;
    padding-left: 25px;
    margin-bottom: 5px;
    margin-top: 20px;
  }

  &__marker {
    width: 12px;
    height: 12px;
    display: block;
    border-radius: 50%;

    &.-is-yellow {
      background: $color-brand-3;
    }

    &.-is-purple {
      background: $color-brand-1;
    }
  }

  &__tabs {
    border-bottom: 1px solid $color-lightest-gray;
    height: 60px;
    align-items: center;
    padding-left: 25px;

    @include screen-md {
      min-width: 744px;
    }
  }

  &__container {
    @include screen-md {
      min-width: 744px;
    }
  }

  &__tab {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;

    &.-is-active {
      .chart__tab-text {
        color: $color-brand-1;
        position: relative;

        &:before {
          content: "";
          width: 100%;
          height: 3px;
          background: $color-brand-1;
          position: absolute;
          display: inline;
          bottom: 0px;
        }
      }
    }
  }

  &__tab-text {
    color: $color-font-gray;
    font-weight: bold;
    font-size: 14px;
    margin-right: 50px;
    margin-bottom: 0;
    height: 100%;
    display: flex;
    align-items: center;

    @include screen-md {
      margin-right: 25px;
      min-width: 97px;
    }
  }

  &__caret {
    margin-left: 10px;
  }

  &__toggle {
    background: transparent !important;
    color: $color-font-gray !important;
    font-size: 14px !important;
    font-weight: bold !important;
    border: none !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding-left: 24px !important;

    &:focus {
      outline: none;
      box-shadow: none !important;
    }
  }

  &__dropdown {
    width: 150px;
    position: relative;

    &.show {
      .chart__toggle {
        border-top: 1px solid $color-lightest-gray !important;
        border-left: 1px solid $color-lightest-gray !important;
        border-right: 1px solid $color-lightest-gray !important;
      }
    }
  }

  .dropdown-menu {
    border-top: none;
    width: 150px;
    max-width: 150px;
    min-width: 150px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: $color-lightest-gray;
    margin-top: 0;

    .dropdown-item {
      color: $color-font-gray;
      font-size: 14px;
      outline: none;

      &:active {
        background: #fff;
      }
    }
  }
}
