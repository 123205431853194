@import "../../styles/utils/mixins";
@import "../../styles/utils/_variables";

.info-table {
  box-shadow: 3px 3px 10px 0px $color-lightest-gray;
  border-radius: 4px;
  background: #fff;
  margin-top: 30px;
  margin-bottom: 90px;
  font-size: 14px;
  width: 100%;

  .rt-th {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
    border: none !important;
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    text-align: left;

    &:last-child {
      width: 5px !important;
    }
  }

  .pagination-bottom {
    background: #fff;
  }

  .-pagination {
    box-shadow: none !important;
  }

  .ReactTable {
    border: none;
  }

  .rt-thead {
    box-shadow: none !important;
  }

  .rt-td {
    border: none !important;
    font-size: 14px;
    color: $color-font-dark;
    z-index: 0;
    padding: 12px !important;

    &:last-child {
      width: 5px !important;
    }
  }

  .rt-noData {
    width: 100%;
    background: #fff;
    text-align: center;
  }

  .-next,
  .-previous {
    height: 40px !important;
    width: 223px !important;
    border-radius: 4px !important;
    flex: none !important;

    .-btn {
      color: #fff !important;
      font-size: 14px !important;
      font-weight: bold !important;
      border-radius: 4px !important;
      height: 40px !important;
      width: 223px !important;
      background-color: $color-brand-1 !important;
    }
  }

  .rt-table {
    overflow: visible;
  }

  .rt-tbody {
    background: #fff;
  }

  .rt-tr-group {
    border: none !important;

    &:hover {
      background: $color-light-gray;
    }
  }

  @include screen-md {
    overflow-y: scroll;
    min-height: 380px;
  }

  &__text-gray {
    color: $color-font-gray;

    &.-name {
      max-width: 310px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__text-dark-gray {
    a {
      color: $color-font-base;

      &:hover {
        color: $color-font-base;
      }
    }
  }

  &__container {
    padding-left: 15px;
    overflow: scroll;

    @include screen-md {
      min-width: 870px;
    }
  }

  td {
    padding-top: 20px !important;
  }

  tr {
    &:hover {
      background: $color-light-gray;
    }
  }

  thead {
    background: #fff;

    th {
      position: sticky;
      top: 0;
      background: #fff;
    }
  }

  table {
    padding-left: 25px;
  }

  &__tabs {
    border-bottom: 1px solid $color-lightest-gray;
    height: 60px;
    align-items: center;
    padding-left: 25px;

    @include screen-md {
      min-width: 870px;
    }
  }

  &__tab {
    cursor: pointer;
    &.-is-active {
      .info-table__tab-text {
        color: $color-brand-1;
        position: relative;

        &:before {
          content: "";
          width: 100%;
          height: 3px;
          background: $color-brand-1;
          position: absolute;
          display: inline;
          bottom: -20px;

          @media (max-width: 1147px) {
            bottom: -9px;
          }
        }
      }
    }
  }

  &__tab-text {
    color: $color-font-gray;
    font-weight: bold;
    font-size: 14px;
    margin-right: 60px;
    margin-bottom: 0;
  }

  &__caret {
    margin-left: 10px;
  }

  &__toggle {
    background: transparent !important;
    color: $color-font-gray !important;
    font-size: 14px !important;
    font-weight: bold !important;
    border: none !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding-left: 24px !important;

    &:focus {
      outline: none;
      box-shadow: none !important;
    }
  }

  &__dropdown {
    width: 150px;
    position: relative;

    &.show {
      .info-table__toggle {
        border-top: 1px solid $color-lightest-gray !important;
        border-left: 1px solid $color-lightest-gray !important;
        border-right: 1px solid $color-lightest-gray !important;
      }
    }
  }

  &__action-btn {
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  .dropdown-menu {
    border-top: none;
    width: 150px;
    max-width: 150px;
    min-width: 150px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: $color-lightest-gray;
    margin-top: 0;

    .dropdown-item {
      color: $color-font-gray;
      font-size: 14px;
    }
  }
}
