@import "./../../styles/utils/mixins";
@import "./../../styles/utils/variables";

@font-face {
  font-family: "Bookmania Semibold";
  src: url("../../styles/fonts/bookmania-semibold.otf") format("opentype");
}

.manage-consent {
  &__container {
    height: 100vh;
    padding-top: 71px;
    overflow: auto !important;
    background: $color-light-gray;
  }

  &__header {
    background: $color-lightest-gray;
    height: 307px;
    padding-top: 105px;

    &-title {
      font-family: "Bookmania Semibold", "Roboto", sans-serif;
      letter-spacing: -0.54px;
      margin-bottom: 24px;
    }

    &-text {
      font-size: 14px;
      color: $color-font-gray;
    }
  }

  &__items {
    background: #fff;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    margin-top: 49px;
    margin-bottom: 30px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    padding: 29px 50px 38px 29px;
    border-bottom: 1px solid $color-lightest-gray;

    &-title {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 3px;
    }

    &-text {
      font-size: 14px;
      color: #292e31 !important;
      max-width: 300px;
    }

    &-more {
      background: $color-light-gray;
      border-radius: 4px;
      padding: 22px 24px !important;
      color: #292e31 !important;
      font-size: 14px;
      margin-top: 24px;
    }

    &-placeholder {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: #dfe4ee;
      display: block;
      margin-right: 10px;
    }

    &-logo {
      max-height: 30px;
      height: 30px;
      max-width: 60px;
      object-fit: contain;
      margin-right: 5px;
    }
  }

  .ReactTable {
    border: none;
    margin-bottom: 70px;

    .rt-thead {
      box-shadow: none;
    }

    .rt-th {
      border: none;
      text-align: left;
      padding: 0;
      margin-bottom: 23px;
    }

    .rt-td {
      padding-left: 0;
    }
  }
}
