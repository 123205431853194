@import "./../../styles/utils/mixins";
@import "./../../styles/utils/variables";

.search-input {
  &__addon {
    background: $color-brand-1 !important;
    min-width: 80px;
    border-radius: 4px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  &.input-group {
    height: 49px;
    max-width: 764px;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    margin-left: auto;
    margin-right: auto;

    @include screen-md {
      width: 90%;
    }

    input {
      height: 49px !important;
      border-color: $color-border !important;
      padding-left: 31px;

      &::placeholder {
        font-size: 14px;
        color: $color-font-light;
      }

      &:focus {
        box-shadow: none;
        outline: none;
        border-color: $color-border;
      }
    }
  }
}
