@import "./../../styles/utils/mixins";
@import "./../../styles/utils/variables";

@font-face {
  font-family: "Bookmania Semibold";
  src: url("../../styles/fonts/bookmania-semibold.otf") format("opentype");
}

.legalsite {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: $color-light-gray;

  &__container {
    overflow-x: hidden;
  }

  &__nav {
    background: rgb(255, 255, 255);
    box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
    height: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 72px;
    padding-right: 72px;
    position: fixed;
    width: 100%;
    z-index: auto;

    &.-page-scroll {
      position: sticky;
      top: 0;
    }

    &-link {
      font-size: 14px;
      color: $color-font-base;

      &:hover {
        color: $color-font-base;
        text-decoration: none;
      }
    }

    @include screen-sm {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__logo {
    background: $color-brand-1;
    opacity: 0.65;
    height: 45px;
    width: 45px;
    display: block;
    border-radius: 50%;
    margin-right: 24px;
  }

  &__logo-container {
    display: block;
    margin-right: 24px;
    overflow: hidden;
    max-width: 150px;

    img {
      object-fit: contain;
      max-height: 60px;
      width: 100%;
    }
  }

  &__brand {
    font-size: 28px;
    font-weight: bold;
    color: $color-brand-1;
    opacity: 0.65;
  }

  &__link {
    font-size: 14px;
    color: $color-brand-1;
    cursor: pointer;

    &.-submit {
      border-radius: 4px;
      border: 1.5px solid rgb(116, 80, 200);
      height: 36px;
      display: flex;
      align-items: center;
      padding: 8px 12px;
    }
  }

  &__welcome {
    background: $color-lightest-gray;
    height: 430px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 72px;

    &-title {
      margin-top: 73px;
      font-family: "Bookmania Semibold", "Roboto", sans-serif;
      margin-bottom: 24px;
    }

    &-text {
      font-size: 14px;
      color: $color-font-gray;
      text-align: center;
      padding: 0 15px;
    }
  }

  &__options {
    background: $color-light-gray;

    &-content {
      justify-content: center;
      top: -140px;
      position: relative;
      margin-bottom: -140px;
    }

    &-info {
      background: #fff;
      border-radius: 4px;
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      min-height: 258px;
      max-width: 219px !important;
      min-width: 219px !important;
      margin-right: 172px;
      margin-left: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      margin-bottom: 20px;

      svg {
        margin-top: 32px;
        min-height: 31px;
        margin-bottom: 25px;
      }

      @include screen-md {
        margin-right: 20px;
        margin-top: 30px;
      }

      @include screen-xs {
        margin-right: 10px;
        margin-left: 10px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &-subtitle {
      font-family: "Bookmania Semibold", "Roboto", sans-serif;
      font-size: 28px;
      margin-bottom: 24px;
      text-align: center;
      margin-top: -66px;
      padding-top: 90px;

      &-text {
        font-size: 18px;
        text-align: center;
        max-width: 695px;
        margin-left: auto;
        margin-right: auto;
        color: $color-font-gray;
        margin-bottom: 64px;
        padding: 0 15px;
      }
    }
  }

  &__info {
    &-title {
      font-size: 18px;
      font-weight: 600;
      color: $color-brand-1;
      margin-bottom: 24px;
    }

    &-text {
      font-size: 14px;
      color: $color-font-gray;
      margin-bottom: 21px;
    }

    &-more {
      font-size: 14px;
      font-weight: bold;
      color: $color-brand-1;
      cursor: pointer;

      &:hover {
        color: $color-brand-1;
      }
    }
  }
}
