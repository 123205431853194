@import "./../../styles/utils/mixins";
@import "./../../styles/utils/variables";

.check {
  margin-left: 16px;
  margin-right: 24px;
  border-radius: 4px;
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;

  &.-unchecked {
    border: 1px solid $color-font-light;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: transparent !important;
    }
  }

  &.-checked {
    background: $color-brand-3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
