@import "./../../styles/utils/mixins";
@import "./../../styles/utils/variables";

.inbox {
  height: calc(100vh - 90px);
  display: flex;

  &__icon {
    width: 17px;
    margin-right: 10px;
  }

  &__transparent-btn {
    background-color: transparent !important;
  }

  &__add-new-user {
    flex-direction: row;

    @include screen-sm {
      flex-direction: column;
    }
  }

  .-has-cursor {
    cursor: pointer;
  }

  &__menu-status {
    height: 100vh;
    max-height: 885px;
    overflow: scroll;

    .dropdown-item {
      color: $color-font-dark !important;
    }
  }

  &__internal {
    border-bottom: 1px solid #f0f1f2;
    padding: 32px 82px;

    @include screen-sm {
      padding: 12px 5px;
    }

    &-table {
      th {
        text-align: left !important;
      }

      img {
        max-width: 55px;
        object-fit: contain;
      }
    }

    &-logo-placeholder {
      background: $color-font-light;
      min-width: 40px;
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: bold;
      border-radius: 50%;
    }

    &-file {
      background: #fff;
      border-radius: 4px;
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      height: 59px;
      width: 280px;
      padding: 13px 10px;
      display: flex;
      align-items: center;

      &-type {
        border-radius: 0px;
        border: 1px solid rgb(223, 228, 238);
        box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.1);
        min-height: 34px;
        min-width: 26px;
        font-size: 7px;
        font-weight: bold;
        color: $color-brand-4;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
      }

      &-name {
        margin-bottom: 0;
        font-size: 12px;
        color: $color-font-gray;
        width: 195px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-remove {
        cursor: pointer;
      }
    }

    &-table-container {
      box-shadow: 2px 4px 16px 0px rgba(51, 51, 51, 0.08);
      margin-top: 37px;

      @include screen-sm {
        overflow: scroll;
      }

      th {
        font-size: 14px;
        font-weight: bold;
        color: $color-font-gray;
        max-width: 16px;
        text-align: center;

        svg {
          min-width: 20px;
        }
      }

      tbody {
        tr {
          &:first-child {
            th,
            td {
              border-top: none !important;
            }
          }

          td,
          th {
            > span {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
              height: 105px;
            }
          }

          td {
            font-size: 14px;
          }
        }
      }

      thead {
        th {
          border-bottom: none !important;
        }
      }
    }

    &-header {
      padding: 16px;

      &-send,
      &-received {
        font-size: 14px;
        font-weight: bold;
        color: $color-brand-1;
        cursor: pointer;

        &.-is-disabled {
          pointer-events: none;
          opacity: 0.6;
        }
      }

      &-divider {
        height: 19px;
        width: 1px;
        background: $color-brand-1;
        display: block;
        margin-left: 24px;
        margin-right: 24px;
      }
    }

    &-title {
      font-size: 18px;
      margin-bottom: 24px;
    }

    &-text {
      font-size: 14px;
      margin-bottom: 24px;
    }

    &-btn {
      width: 136px !important;
    }
  }

  &__data-actions {
    padding: 32px 82px;

    @include screen-sm {
      padding: 12px 5px;
    }

    &-solve {
      width: 136px !important;

      @include screen-sm {
        margin-bottom: 8px;
      }
    }
  }

  &__data-company {
    width: 16px;
    height: 16px;
    margin-right: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  &__delay {
    background: #fff;
    max-height: 715px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
    margin-top: 24px;

    img {
      margin-top: 99px;
      margin-bottom: 32px;
    }

    &-title {
      font-size: 22px;
      font-weight: bold;
      color: $color-brand-1;
      margin-bottom: 24px;
    }

    &-text {
      font-size: 14px;
      color: $color-font-gray;
      text-align: center;
      margin-bottom: 32px;
    }

    &-next {
      font-size: 14px;
      font-weight: bold;
      color: $color-brand-1;
      cursor: pointer;
    }
  }

  &__select-status {
    width: 325px !important;
    padding-left: 40px;

    @include screen-sm {
      width: 100% !important;
    }

    select.form-control {
      appearance: none;
    }
  }

  &__data-edit {
    &-title {
      font-size: 28px;
      letter-spacing: -0.2px;
      margin-bottom: 7px;
      text-align: center;
    }

    &-subtitle {
      font-size: 14px;
      color: $color-font-gray;
      text-align: center;
      margin-bottom: 22px;
    }

    &-close {
      svg {
        cursor: pointer;
      }
    }
  }

  &__mail-preview {
    box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
    height: 772px;
    max-width: 685px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 49px;
    background: #fff;
  }

  &__data-preview-header {
    background: #b0bac9;
    box-shadow: 2px 4px 16px 0px rgba(51, 51, 51, 0.08);
    height: 52px;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    z-index: 9;

    p {
      margin-bottom: 0;
    }

    span {
      position: absolute;
      right: 32px;
      font-size: 12px;
      cursor: pointer;
    }
  }

  &__select-date-container {
    align-items: center;
    justify-content: space-between;
    max-width: 370px;
    flex-wrap: wrap;

    @include screen-sm {
      flex-direction: column;
    }
  }

  &__add-user-form {
    width: 325px;
    font-size: 14px;
    color: $color-font-gray;
    margin-bottom: 24px;

    @include screen-sm {
      margin-top: 10px;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__create-verify {
    font-size: 14px;
    color: #292e31;
    margin-bottom: 24px;
    margin-left: 40px;
  }

  &__image-placeholder {
    height: 50px;
    width: 50px;
    display: block;
    background: $color-font-gray;
    border-radius: 50%;
    align-items: baseline;
    margin-left: 40px;
    margin-right: 24px;
  }

  &__avatar-container {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    align-items: baseline;
    margin-left: 40px;
    margin-right: 24px;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__logo-container {
    align-items: baseline;
    .show-upload {
      height: 50px;
      width: 50px;
      position: absolute;
      left: 40px;
      cursor: pointer;
    }
  }

  &__select-label {
    padding-left: 40px;
    font-size: 14px;
    color: #292e31;
  }

  &__select-date {
    padding-left: 40px;
    margin-bottom: 24px;
    display: inline-block;
  }

  &__select-btns {
    padding-left: 40px;
    padding-bottom: 24px;

    @include screen-sm {
      padding-left: 5px;
    }
  }

  &__select-question {
    padding-left: 40px;
    font-size: 14px;
    font-weight: bold;
  }

  &__create-textarea {
    height: 221px !important;
    max-width: 568px;
    margin-left: 40px !important;
    margin-bottom: 24px;

    @include screen-sm {
      width: 200px !important;
    }
  }

  &__remove-selected {
    cursor: pointer;
  }

  &__selected {
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    height: 59px;
    width: 384px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 24px;
    margin-left: 40px;
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 600;
    color: #8697a6;
  }

  &__select-user,
  &__add-user {
    width: 136px !important;
  }

  &__select-user {
    margin-right: 16px;
  }

  &__create {
    font-size: 14px;
    padding-left: 40px;
    padding-right: 40px;

    @include screen-sm {
      margin-right: 12px;
    }

    &-title {
      font-size: 22px;
      letter-spacing: -0.16px;
      padding-top: 24px;
      margin-bottom: 24px;
      padding-left: 40px;
      padding-right: 40px;
    }

    &-check {
      border-radius: 4px;
      border: 1px solid $color-font-light;
      display: block;
      height: 13px;
      width: 13px;

      &.-is-active {
        background: $color-brand-1;
      }
    }
  }

  &.-has-banner {
    height: calc(100vh - 155px);

    @include screen-sm {
      height: calc(100vh - 118px);
    }

    .inbox__menu-content {
      height: calc(100vh - 300px);
    }

    .inbox__data {
      height: calc(100vh - 182px);
    }
  }

  @include screen-md {
    height: calc(100vh - 55px);
  }

  &__request {
    &-question {
      min-height: 221px;
    }

    &-add-empty {
      width: 95%;
      color: $color-brand-1;
      padding: 10px;
      font-weight: bold;

      a {
        color: $color-brand-1;

        &:hover {
          color: $color-brand-1;
        }
      }
    }

    &-users {
      font-size: 14px;

      &-toggle {
        cursor: pointer;
        display: flex;
      }
    }

    &-avatar-placeholder {
      height: 32px;
      width: 32px;
      display: block;
      border-radius: 50%;
      background: $color-lightest-gray;
      object-fit: cover;
    }

    &-actions {
      max-width: 136px;
    }

    &-assign-menu {
      width: 236px;
    }

    &-action-menu {
      width: 195px;
    }

    &-action-menu,
    &-assign-menu {
      .dropdown-item {
        font-size: 14px;
        color: $color-font-gray;
        padding: 10px 23px;

        img {
          height: 32px;
          width: 32px;
          object-fit: cover;
          border-radius: 50%;
        }

        &:active {
          background: inherit;
          color: inherit;
          outline: none;
        }
      }
    }
  }

  &.-is-sample {
    height: calc(100vh - 142px);
  }

  &__empty {
    height: 492px;
    max-width: 1219px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 48px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-img {
      margin-bottom: 56px;
    }

    &-title {
      font-size: 18px;
      font-weight: 600;
      color: $color-brand-1;
      margin-bottom: 40px;
    }

    &-text {
      font-size: 14px;
      color: $color-font-gray;
    }
  }

  &__banner {
    display: flex;
    box-shadow: 2px 4px 16px 0px rgba(51, 51, 51, 0.08);
    height: 52px;
    background: rgb(176, 186, 201);
    align-items: center;

    &-title {
      font-size: 18px;
      font-weight: 600;
      color: #fff;
      text-align: center;
      display: flex;
      justify-content: center;
      flex: 1;
      align-items: center;
      margin-bottom: 0;
    }

    &-exit {
      position: absolute;
      right: 40px;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: bold;
      color: #fff;
      cursor: pointer;
    }
  }

  &__menu {
    border-radius: 0px;
    border: 0.5px solid $color-font-light;
    border-top: none;
    width: 412px;
    max-width: 412px;
    min-width: 412px;
    padding-top: 19px;

    &.-is-mobile {
      position: fixed;
      z-index: 9999;
      background: #f4f6fc;
      width: calc(100vw - 70px) !important;
      min-width: calc(100% - 70px);
      height: calc(100% - 53px);
    }

    @include screen-sm {
      width: 200px !important;
      max-width: 200px;
      min-width: 200px;
    }

    &-verified {
      font-size: 12px;
      color: $color-font-gray;
      margin-right: 8px;
    }

    @include screen-md {
      width: 30%;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      text-align: center;
      padding: 0 30px 0 30px;
      border-bottom: 1px solid $color-font-light;
      padding-bottom: 18px;

      @include screen-md {
        padding: 0 5px;
      }
    }

    &-stats {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-divider {
      height: 36px;
      width: 1px;
      background: $color-font-light;
      position: relative;
      top: 5px;
      margin-left: 3px;
      margin-right: 3px;

      @include screen-md {
        display: none;
      }
    }

    &-amount {
      font-size: 18px;
      font-weight: 600;
      color: $color-font-base;
    }

    &-text {
      font-size: 14px;
      color: $color-font-base;
      font-weight: normal;
    }

    &-recent {
      padding: 16px 30px;
      border-bottom: 1px solid $color-font-light;

      &-text {
        font-size: 14px;
        font-weight: 600;
        color: $color-font-base;
        margin-right: 40px;

        @include screen-sm {
          margin-right: 5px;
        }
      }

      &-dropdown {
        &:focus {
          box-shadow: none !important;
        }
      }

      .dropdown-menu {
        width: 326px;
        box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        border: 1px solid rgb(223, 228, 238);
        top: 15px !important;
        left: -20px !important;
      }

      .dropdown-header {
        font-size: 14px;
        font-weight: 600;
        color: $color-brand-1;
        padding-top: 24px;
        padding-bottom: 24px;
      }

      .dropdown-item {
        font-size: 14px;
        color: $color-font-gray;
        padding-left: 48px;
        padding-top: 12px;
        padding-bottom: 12px;

        &:active,
        &:hover {
          background: $color-light-gray;
          color: $color-font-base;
          outline: none;
        }
      }
    }

    &-content {
      height: calc(100vh - 234px);
      overflow: scroll;

      &.-is-sample {
        height: calc(100vh - 286px);
      }
    }
  }

  &__item {
    border-bottom: 1px solid $color-font-light;
    min-height: 125px;
    padding: 17px 24px 17px 30px;
    cursor: pointer;

    @include screen-md {
      padding: 10px;
    }

    &-name {
      font-size: 14px;
      font-weight: 600;
      color: $color-font-base;
    }

    &.-is-active {
      background: #fff;
      border-right: 1px solid $color-font-light;
    }

    &-notification {
      @include screen-md {
        flex-wrap: wrap;
      }
    }

    &-date {
      font-size: 12px;
      color: $color-light-gray2;
    }

    &-email {
      font-size: 14px;
      color: $color-font-dark;
    }

    &-type {
      font-size: 12px;
      min-height: 22px;
      text-align: center;
      display: flex;
      width: 121px;
      color: #fff;
      border-radius: 4px;
      margin-right: 24px;

      &.-is-purple {
        background: $color-brand-1;
      }

      &.-is-coral {
        background: $color-brand-5;
      }

      &.-is-yellow {
        background: $color-brand-3;
      }

      &.-is-aqua {
        background: $color-brand-4;
      }

      &.-is-blue {
        background: #3a73b8;
      }

      &.-is-black {
        background: #b0bac9;
      }
    }

    &-action {
      font-size: 14px;
      color: $color-font-dark;
    }
  }

  &__content {
    &-header {
      max-width: 716px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__sent {
    border-radius: 4px;
    height: 492px;
    max-width: 716px;
    width: 100%;
    border-radius: 4px;
    background: #fff;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 24px;

    &-img {
      margin-bottom: 48px;
    }

    &-title {
      margin-bottom: 24px;
      font-size: 22px;
      font-weight: bold;
      color: $color-brand-1;
    }

    &-text {
      margin-bottom: 38px;
      width: 280px;
      text-align: center;
      font-size: 14px;
      color: $color-font-gray;
    }

    &-next {
      font-size: 14px;
      font-weight: bold;
      color: $color-brand-1;
      cursor: pointer;
    }
  }

  &__data {
    height: calc(100vh - 91px);
    overflow: scroll;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 30px;

    &.-is-sample {
      height: calc(100vh - 182px);
    }

    &-info {
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }

    &-assign {
      .dropdown-menu {
        min-width: 236px;
        box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
        border-top: none;
        z-index: 9;
        top: -2px !important;
      }

      &.show {
        .inbox__data-buttons-assign {
          border-color: $color-lightest-gray !important;
          border-bottom: none;
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          width: 246px;
          z-index: 999;

          &:hover,
          &:active,
          &:focus {
            z-index: 99 !important;
          }
        }
      }
    }

    &-header {
      font-size: 28px;
      color: $color-font-base;
    }

    &-suggestion {
      min-height: 40px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 32px;
      margin-top: 24px;
      margin-bottom: 24px;

      @include screen-md {
        flex-wrap: wrap;
      }

      &.-is-purple {
        background: rgba(116, 80, 200, 0.2);
      }

      &.-is-coral {
        background: rgba(248, 107, 103, 0.2);
      }

      &.-is-aqua {
        background: rgba(29, 220, 192, 0.15);
      }

      &.-is-yellow {
        background: rgba(235, 166, 1, 0.2);
      }

      &.-is-blue {
        background: rgba(58, 115, 184, 0.3);
      }

      &.-is-black {
        background: #dfe4ee;
      }

      &-text {
        font-size: 14px;
        font-weight: 600;
        color: $color-brand-1;
        margin-left: 17px;
        margin-right: 8px;

        &.-is-purple {
          color: $color-brand-1;
        }

        &.-is-coral {
          color: $color-brand-5;
        }

        &.-is-aqua {
          color: $color-brand-4;
        }

        &.-is-blue {
          color: #3a73b8;
        }

        &.-is-yellow {
          color: $color-brand-3;
        }

        &.-is-black {
          color: #333;
        }
      }

      &-action {
        font-size: 14px;
        color: $color-font-base;
      }

      &-read {
        font-size: 14px;
        cursor: pointer;

        @include screen-md {
          margin-left: auto;
          margin-right: auto;
        }

        &:hover {
          color: $color-brand-1;
          text-decoration: underline;
        }

        &.-is-purple {
          color: $color-brand-1;
        }

        &.-is-coral {
          color: $color-brand-5;
        }

        &.-is-aqua {
          color: $color-brand-4;
        }

        &.-is-blue {
          color: #3a73b8;
        }

        &.-is-yellow {
          color: $color-brand-3;
        }

        &.-is-black {
          color: #333;
        }
      }
    }

    &-stats {
      display: flex;
      justify-content: space-between;
      padding: 32px 82px;
      border-bottom: 1px solid $color-border;

      @include screen-sm {
        padding: 12px 5px;
      }

      &-label {
        font-size: 14px;
        font-weight: 600;
        color: $color-font-base;
      }

      &-value {
        font-size: 14px;
        color: $color-font-dark;
      }

      &-btn {
        min-width: 136px;
        max-width: 136px;
      }
    }

    &-user {
      background: #fff;
      padding: 31px 35px 35px 15px;
      border-bottom: 1px solid $color-border;

      @include screen-sm {
        flex-wrap: wrap;
      }

      &.-has-company {
        padding-left: 80px;

        @include screen-sm {
          padding-left: 5px;
        }
      }

      &-status {
        width: 8px;
        height: 8px;
        display: block;
        border-radius: 50%;
        margin-right: 16px;

        &.-is-purple {
          background: $color-brand-1;
        }

        &.-is-coral {
          background: $color-brand-5;
        }

        &.-is-aqua {
          background: $color-brand-4;
        }

        &.-is-blue {
          background: #3a73b8;
        }

        &.-is-yellow {
          background: $color-brand-3;
        }

        &.-is-black {
          background: #dfe4ee;
        }
      }

      &-avatar-company {
        max-height: 40px;
        margin-right: 16px;
        overflow: hidden;
        max-width: 60px;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;

        img {
          width: 100%;
          height: 100%;
          max-height: 40px;
          object-fit: contain;
        }

        &-placeholder {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 16px;
          background: #b0bac9;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 16px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &-placeholder {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 16px;
          background: #b0bac9;
          display: block;
          font-weight: bold;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &-name {
        font-size: 18px;
        color: $color-font-base;
      }

      &-email,
      &-date {
        font-size: 12px;
        color: $color-font-gray;
      }

      &-action {
        cursor: pointer;
        &.-user {
          background: $color-light-gray;
          display: flex;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          padding: 5px;
        }
      }
    }

    &-buttons {
      padding: 24px 32px;
      display: flex;
      justify-content: flex-end;

      &-avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 15px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-assign {
        height: 36px;
        width: 136px;
        color: $color-brand-1 !important;
        background: #fff !important;
        border-color: $color-brand-1 !important;
        border-radius: 4px !important;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px !important;
        font-weight: 600 !important;
        margin-right: 16px;

        &:focus {
          box-shadow: none !important;
        }
      }

      &-actions {
        height: 36px;
        width: 136px;
        color: #fff !important;
        background-color: $color-brand-1 !important;
        border-color: $color-brand-1 !important;
        border-radius: 4px !important;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px !important;
        font-weight: 600 !important;

        &:focus {
          box-shadow: none !important;
        }
      }

      &-items {
        color: $color-font-gray !important;
        font-size: 14px;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        padding-left: 23px !important;

        &:hover {
          color: $color-font-base !important;
          background: $color-light-gray !important;
        }

        &:active {
          background: $color-light-gray !important;
        }
      }
    }

    &-actions {
      &-btns {
        @include screen-sm {
          flex-wrap: wrap;
        }
      }
      .dropdown-menu {
        margin-top: -3px;
        border-top: none;
        border-radius: 4px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
        min-width: 136px !important;
        width: 195px !important;
      }

      &.show {
        .inbox__data-buttons-actions {
          border-color: $color-lightest-gray !important;
          border-bottom: none;
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          width: 195px;
          z-index: 999;

          &:hover,
          &:active,
          &:focus {
            z-index: 99 !important;
          }
        }
      }
    }
  }

  &__access {
    &-title {
      font-size: 18px;
      font-weight: 600;
      color: $color-font-base;
    }
  }

  &__upload {
    &-content {
      padding: 32px 82px;
      border-bottom: 1px solid $color-border;
    }

    &-title {
      font-size: 14px;
      font-weight: 600;
      color: $color-font-base;
    }
  }

  &__erasure {
    &-content {
      padding: 32px 82px;
      border-bottom: 1px solid $color-border;
    }

    &-title {
      font-size: 14px;
      color: $color-font-base;
      margin-bottom: 16px;
    }

    &-text {
      font-size: 14px;
      color: $color-font-gray;
      margin-bottom: 0;
      display: inline-block;
    }

    &-button {
      margin-top: 24px;
      background: $color-brand-1 !important;
      border-radius: 4px !important;
      height: 36px;
      width: 136px;
      padding: 0 !important;
      font-size: 14px !important;
      font-weight: bold !important;
    }

    &-textarea {
      height: 221px !important;
      max-width: 568px !important;
      resize: none;
      border-radius: 5px !important;
      border: 1px solid rgb(176, 186, 201) !important;

      @include screen-md {
      }

      &::placeholder {
        font-size: 14px !important;
        color: $color-font-light !important;
      }

      &:focus {
        box-shadow: none !important;
      }
    }
  }

  &__action {
    min-height: 90px;
    background: #fff;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-top: 24px;
    position: relative;
    padding-left: 32px;
    padding-right: 32px;

    &.-upgrade {
      max-width: 716px;
      margin-left: auto;
      margin-right: auto;
    }

    @include screen-md {
      flex-direction: column;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &-label {
      font-size: 14px;
      color: $color-font-base;
      margin-left: 16px;
      font-weight: 600;
    }

    &-status {
      font-size: 14px;
      color: #969696;
      margin-top: 10px;
      margin-left: 16px;
    }

    &-divider {
      width: 1px;
      height: 24px;
      background: $color-font-gray;
      position: absolute;
      top: -24px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }

    &-date {
      font-size: 12px;
      color: $color-font-dark;
      text-align: right;
      margin-top: 15px;
      margin-bottom: 0;
    }

    &-icon {
      width: 40px;
      height: 40px;
      background: rgba(235, 166, 1, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-radius: 50%;
      padding: 10px;
    }
  }

  &__checklist {
    padding: 32px 82px;

    .check {
      border-radius: 4px;
      height: 20px;
      width: 20px;
      margin-right: 24px;

      &.-unchecked {
        border: 1px solid $color-font-light;
      }

      &.-checked {
        background: $color-brand-3;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-option {
      display: flex;
      font-size: 14px;
      color: $color-font-gray;
      margin-top: 17px;
    }

    &-label {
      font-size: 14px;
    }
  }
}

.inbox-free {
  height: calc(100vh - 90px);
  overflow: scroll;
  background: #fff;
  display: flex;

  @include screen-sm {
    height: calc(100vh - 53px);
    flex-direction: column-reverse;
  }

  &__content-data {
    &:focus {
      outline: none;
      box-shadow: none !important;
    }
  }

  &__banner {
    width: 30%;
    background: $color-brand-1;
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include screen-sm {
      width: 100%;
    }
  }

  &__img {
    max-width: 465px;
    position: relative;
    left: -65px;

    @include screen-sm {
      left: 0;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: 100%;
      padding-bottom: 10px;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 70%;
    padding: 10px;

    @include screen-sm {
      left: 0;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      padding-bottom: 10px;
    }

    ul {
      padding: 0;

      @include screen-sm {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      li {
        list-style-type: none;
        font-size: 16px;
        color: $color-font-gray;
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        @include screen-md {
          width: 80%;
        }

        @include screen-sm {
          svg {
            min-width: 20px;
            height: 20px;
          }
        }
      }
    }

    &-upgrade {
      max-width: 136px;
    }

    &-title {
      font-family: "Bookmania Semibold", "Roboto", sans-serif;
      letter-spacing: -0.2px;
      font-size: 28px;
      margin-bottom: 24px;

      @include screen-sm {
        text-align: center;
      }
    }

    &-subtitle {
      font-size: 16px;
      max-width: 483px;
      color: $color-font-gray;
      margin-bottom: 67px;
      text-align: center;
    }

    &-icon {
      width: 20px;
      height: 20px;
      margin-right: 16px;
    }
  }
}
