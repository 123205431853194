@import './../../styles/utils/mixins';
@import './../../styles/utils/variables';

@font-face {
	font-family: 'Bookmania Semibold';
	src: url('../../styles/fonts/bookmania-semibold.otf') format('opentype');
}

.settings {
	height: calc(100vh - 90px);
	overflow: scroll;
	padding-top: 48px;
	padding-bottom: 36px;

	.horizontal-slider {
		height: 12px;
		background: #dfe4ee;
		border-radius: 10px;
		margin-top: 20px;
	}
	
	.example-track-0 {
		background-color: #573c96;
		box-shadow: none;
		border-radius: 6.62px;
		height: 12px;
	}
	
	.example-thumb  {
		background: #fff;
		border: 2px solid #f4f6fc;
		box-shadow: 2px 4px 16px 0 rgba(116,80,200,.08);
		height: 24px;
		width: 24px;
		border-radius: 50%;
		top: -6px;
		outline: none;
		cursor: pointer;
	}

	&.-has-banner {
		height: calc(100vh - 155px);

		@include screen-sm {
			height: calc(100vh - 117px);
		}
	}

	&__branding {
		border-radius: 4px;
		border: 1px solid rgb(176, 186, 201);
		height: 618px;
		width: 515px;
		margin-right: 54px;
		cursor: pointer;

		&:hover {
			box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
		}

		&-icon {
			margin-top: 49px;
			margin: 49px auto;
			display: flex;
			justify-content: center;
		}

		&-content {
			width: 258px;
			text-align: center;
			margin: 0 auto;

			ul {
				text-align: left;
				margin-top: 32px;

				li {
					margin-bottom: 24px;
				}
			}
		}
	}

	&__btn-group {
		margin-left: 16px;

		.btn {
			background-color: transparent !important;
			color: #969696 !important;
			border-color: #b0bac9;
			height: 38px;
			outline: none !important;
			box-shadow: none !important;

			&:hover {
				background-color: rgba(116, 80, 200, 0.2) !important;
			}

			&:disabled {
				background-color: transparent !important;
			}

			&.-is-active {
				background-color: rgba(116, 80, 200, 0.2) !important;
			}
		}
	}

	&__plans {
		&-plans {
			@include screen-sm {
				flex-direction: column;
			}
		}

		&-add {
			background: #fff;
			border-radius: 4px;
			box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
			min-height: 690px;
			width: 100%;
			padding-left: 15px;
			padding-right: 15px;

			&-value {
				font-size: 22px;
				font-weight: bold;
				color: $color-brand-1;
			}

			&-total-value {
				font-size: 36px;
				font-weight: 600;
				font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
				margin-left: 18px;
				margin-right: 7px;
			}

			&-monthly {
				font-size: 14px;
				font-weight: bold;
				color: $color-font-dark;
			}

			.slider {
				max-width: 608px;
				margin-left: auto;
				margin-right: auto;
				margin-top: 52px;
				margin-bottom: 52px;

				.rangeslider__fill {
					background-color: $color-brand-1;
					box-shadow: none;
					border-radius: 6.62px;
				}

				.rangeslider__handle {
					background: rgb(116, 80, 200);
					border: 2px solid rgb(244, 246, 252);
					box-shadow: 2px 4px 16px 0px rgba(116, 80, 200, 0.08);
					height: 24px;
					width: 24px;

					&:focus {
						outline: none;
					}

					&:after {
						display: none;
					}
				}

				.rangeslider {
					box-shadow: none;
					background: #dfe4ee;
				}

				.value {
					font-size: 18px;
					color: $color-font-dark;
					text-align: center;
				}
			}

			&-title {
				font-size: 28px;
				font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
				letter-spacing: -0.38px;
				padding-top: 45px;
				text-align: center;
			}

			&-current {
				letter-spacing: -0.16px;
				font-weight: bold;
				font-size: 22px;
				color: $color-brand-1;
				text-align: center;
				margin-bottom: 16px;
			}

			&-text {
				font-size: 18px;
				color: $color-font-dark;
				text-align: center;
			}

			&-progress {
				.progress {
					max-width: 608px;
					margin-left: auto;
					margin-right: auto;
					margin-top: 37px;
					height: 13px;
					border-radius: 6.62px;
					background: #dfe4ee;
				}

				.progress-bar {
					border-radius: 6.62px;
					background: $color-brand-3;
				}
			}
		}

		&-business {
			background: linear-gradient(45deg, rgb(106, 69, 192) 0%, rgb(156, 120, 241) 100%);
			border-radius: 4px;
			box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
			min-height: 682px;
			max-width: 662px;
			margin-bottom: 48px;
		}

		&-free {
			background: rgb(255, 255, 255);
			border-radius: 4px;
			border: 1px solid rgb(116, 80, 200);
			height: 274px;
			max-width: 413px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 32px;
			display: flex;
			align-items: center;
			flex-direction: column;

			&-title {
				margin-top: 39px;
				letter-spacing: -0.16px;
				color: $color-brand-1;
				font-size: 22px;
				font-weight: bold;
				margin-bottom: 31px;
			}

			&-text {
				font-size: 14px;
				color: $color-font-dark;
				margin-bottom: 33px;
			}
		}

		&-business {
			&-title {
				font-size: 28px;
				font-weight: bold;
				letter-spacing: -0.2px;
				color: #fff;
				text-align: center;
				margin-top: 53px;
				margin-bottom: 30px;
			}

			&-main {
				font-size: 16px;
				font-weight: bold;
				color: #fff;
				margin-bottom: 10px;

				u {
					cursor: help;
				}
			}

			&-secondary {
				font-size: 14px;
				color: #fff;
				font-weight: normal;
			}

			&-upgrade {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				text-align: center;
				flex: 1;
			}

			&-legal {
				flex: 1;
			}

			&-upgrade-amount {
				font-size: 44px;
				font-weight: 600;
				color: #fff;
				font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
				margin-bottom: 0;
			}

			&-upgrade-month {
				color: #fff;
				font-size: 14px;
				font-weight: bold;
			}

			&-upgrade-subjects {
				font-size: 14px;
				color: #fff;
			}
		}

		&-badge {
			position: absolute;
			background: #fff;
			text-align: center;
			z-index: 1;
			height: 24px;
			width: 149px;
			display: flex;
			right: 0;
			top: 44px;
			align-items: center;

			@include screen-sm {
				top: 34px;
			}

			p {
				position: relative;
				display: block;
				font-size: 14px;
				font-weight: 600;
				letter-spacing: 0px;
				color: #7450c8;
				background: #fff;
				width: 140px;
				margin-bottom: 0;
			}

			&:before {
				content: '';
				border: 12px solid #fff;
				border-left-color: transparent;
				border-left-width: 14px;
				position: absolute;
				top: 0;
				left: -20px;
			}
		}
	}

	@include screen-sm {
		height: calc(100vh - 53px);
	}

	&__remove {
		font-size: 14px;
		font-weight: bold;
		color: #333;
		padding-left: 44px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		margin-bottom: 32px;
		margin-top: 16px;
		cursor: pointer;
	}

	&__delete {
		font-size: 14px;
		font-weight: 600;
		color: $color-font-gray;
		align-items: center;
		justify-content: flex-end;
		cursor: pointer;
	}

	&__add-text {
		margin-bottom: 0;
		color: $color-brand-1;
		font-size: 14px;
		font-weight: bold;
		margin-left: 9px;
		cursor: pointer;
		width: auto;
	}

	&__group-form {
		flex-wrap: wrap;

		input {
			max-width: 30%;
			width: 30%;
		}
	}

	.chart__dropdown.show .chart__toggle {
		border: none !important;
	}

	&__input-icon {
		right: 5px;
		top: 13px;
	}

	&__disabled-input {
		max-width: 219px;
		background: transparent !important;
		font-size: 14px !important;
		color: $color-font-base !important;

		&.-domain {
			min-width: 325px;

			@include screen-sm {
				min-width: 100%;
			}
		}
	}

	&__copy {
		width: 136px !important;
	}

	&__input-label {
		font-size: 14px;
		font-weight: 600;
	}

	&__label {
		font-size: 16px;
		font-weight: bold;
		color: $color-font-gray;
	}

	&__select {
		font-size: 16px;
		color: #969696;
		cursor: pointer;
		margin-left: 16px;
	}

	&__avatar-container {
		max-height: 64px;
		height: 100%;
		min-width: 63px;
		width: 100%;
		max-width: 160px;
		display: block;
		cursor: pointer;
		overflow: hidden;

		img {
			height: 100%;
			object-fit: contain;
			max-height: 64px;
		}
	}

	&__favicon-container {
		max-height: 16px;
		width: 16px;
		height: 100%;
		cursor: pointer;

		img {
			height: 16px;
			object-fit: cover;
			width: 16px;
		}
	}

	&__image-placeholder {
		background: #dfe4ee;
		box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
		height: 64px;
		width: 163px;
		border-radius: 4px;
		display: block;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		color: #292e31;

		&:before {
			content: "Upload logo";
		}
	}

	&__image-favicon-placeholder {
		background: #dfe4ee;
		box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
		height: 64px;
		width: 64px;
		border-radius: 4px;
		display: block;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		color: #292e31;

		&:before {
			content: "Upload";
		}
	}

	.show-upload {
		width: 161px;
		height: 64px;
		border-radius: 50%;
		position: absolute;
		cursor: pointer;
		top: 0;
		bottom: 0;
		margin-top: auto;
		display: flex;
		align-items: center;
		justify-content: center;

		p {
			text-align: center;
		}
	}

	.show-upload-favicon {
		width: 64px;
		height: 64px;
		display: block;
		position: absolute;
		cursor: pointer;
		top: 40px;
	}

	select.form-control {
		appearance: none;
	}

	&__account {
		background: #fff;
		border-radius: 4px;
		box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
		min-height: 134px;
		margin-bottom: 32px;
		padding: 32px;
		display: flex;
		flex-direction: column;
		cursor: pointer;

		@include screen-sm {
			padding: 15px;
		}

		&.-is-active {
			cursor: default !important;
		}
	}

	&__title {
		font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
		font-size: 22px;
		font-weight: 600;
		letter-spacing: -0.16px;
		margin-bottom: 16px;
		display: flex;
		align-items: center;

		&.-is-active {
			color: $color-brand-1;
		}

		svg {
			min-width: 24px;
		}

		@include screen-sm {
			align-items: flex-start;
		}
	}

	&__text {
		font-size: 16px;
		color: $color-font-dark;
		margin-bottom: 0;

		&.-is-active {
			margin-bottom: 32px;
		}
	}

	&__legal {
		&-form {
			@include screen-sm {
				width: 100%;
			}

			&-save {
				width: 136px !important;
			}

			input,
			select {
				border-radius: 5px;
				border: 1px solid $color-font-light;
				height: 38px;
				font-size: 14px;
				color: $color-font-light;

				&:focus {
					box-shadow: none;
					border-color: $color-font-light;
				}

				@include screen-sm {
					width: 100% !important;
					max-width: 300px !important;
				}
			}

			.form-group {
				margin-bottom: 24px;
				width: 568px !important;

				@include screen-sm {
					width: 100%;
					max-width: 568px !important;
					margin-right: 0 !important;
				}
			}

			input {
				margin-bottom: 24px;
				width: 568px !important;

				&::placeholder {
					color: $color-font-light;
				}
			}

			&-group {
				max-width: 568px !important;

				@include screen-sm {
					display: flex;
					flex-wrap: wrap;
				}

				input {
					width: 223px !important;
				}

				.form-group {
					margin-bottom: 24px;
					width: 325px !important;

					@include screen-sm {
						width: 100%;
						margin-right: 0 !important;
					}
				}
			}
		}
	}

	&__user {
		&-send {
			color: $color-brand-1;
			cursor: pointer;
		}

		&-form {
			padding-top: 48px;

			input,
			select {
				border-radius: 5px;
				border: 1px solid $color-font-light;
				height: 38px;
				font-size: 14px;
				color: $color-font-light;

				&:focus {
					box-shadow: none;
					border-color: $color-font-light;
				}

				@include screen-sm {
					width: 100% !important;
					max-width: 325px;
				}
			}

			.form-group {
				margin-bottom: 24px;
				width: 325px;

				@include screen-sm {
					width: 100%;
					max-width: 325px;
					margin-right: 0 !important;
				}
			}

			input {
				margin-bottom: 24px;
				width: 325px;

				&::placeholder {
					color: $color-font-light;
				}
			}
		}

		&-actions {
			cursor: pointer;
		}

		&-delete {
			cursor: pointer;
		}

		&-view {
			color: $color-brand-1;
			font-size: 14px;
			font-weight: bold;
			display: flex;
			align-items: center;
			cursor: pointer;

			&:hover {
				color: $color-brand-1;
				text-decoration: none;
			}
		}

		&-check {
			border-radius: 4px;
			border: 1px solid $color-font-light;
			display: block;
			height: 13px;
			width: 13px;

			&.-is-active {
				background: $color-brand-1;
			}
		}

		&-keep {
			margin-left: 32px;
			font-size: 16px;
			color: $color-font-gray;

			@include screen-sm {
				margin-left: 0;
				margin-right: 12px;
			}
		}

		&-text {
			font-size: 16px;
			color: $color-font-gray;
			margin-right: 10px;
		}

		&-save {
			margin-top: 32px;
			width: 136px !important;
		}

		&-table {
			margin-top: 48px;
			overflow: scroll;

			th,
			td {
				border: none !important;
			}

			td {
				font-size: 14px;
				color: $color-font-gray;
				white-space: nowrap;
			}
		}

		&-avatar {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			overflow: hidden;
			margin-right: 24px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&-name {
			font-size: 14px;
			font-weight: 600;
			color: $color-font-base;
		}

		&-email {
			font-size: 14px;
			color: $color-font-dark;
		}

		&-access-icon {
			margin-left: 16px;
		}
	}

	&__password {
		&-form {
			margin-top: 39px;

			input {
				border-radius: 5px;
				border: 1px solid $color-font-light;
				height: 38px;
				font-size: 14px;
				color: $color-font-light;
				width: 325px;
				margin-bottom: 24px;

				@include screen-sm {
					width: 100%;
					max-width: 325px;
				}

				&::placeholder {
					color: $color-font-light;
				}

				&:focus {
					box-shadow: none;
					border-color: $color-font-light;
				}
			}
		}
	}

	&__legal {
		&-logo {
			height: 72px;
			width: 72px;
			overflow: hidden;
			border-radius: 50%;
			margin-right: 16px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&-title {
			font-size: 18px;
			font-weight: 600;
			margin-top: 32px;
			margin-bottom: 24px;
		}

		&-change {
			font-size: 14px;
			color: $color-font-gray;
		}

		&-color {
			font-size: 16px;
			font-weight: bold;
			color: $color-font-gray;
		}

		&-chosen-color {
			border-radius: 4px;
			border: 1px solid $color-brand-3;
			height: 38px;
			width: 38px;
			background: $color-brand-3;
			display: block;
		}

		&-chosen-color-text {
			font-size: 16px;
			color: $color-font-light;
			margin-left: 16px;
		}

		&-logo-container {
			margin-right: 80px;

			@include screen-sm {
				margin-bottom: 20px;
			}
		}

		&-form {
			margin-top: 24px;

			input,
			select {
				border-radius: 5px;
				border: 1px solid $color-font-light;
				height: 38px;
				font-size: 14px;
				color: $color-font-light;

				&::placeholder {
					color: $color-font-light;
				}

				&:focus {
					box-shadow: none;
					border-color: $color-font-light;
				}
			}

			input {
				width: 325px;
				margin-bottom: 16px;

				@include screen-sm {
					width: 100%;
					max-width: 325px;
				}
			}

			.form-group {
				width: 325px;
				margin-bottom: 7px;

				@include screen-sm {
					width: 100%;
					max-width: 325px;
				}
			}
		}

		&-text {
			font-size: 14px;
			color: $color-font-gray;
			margin-bottom: 25px;
		}

		&-add {
			font-size: 14px;
			font-weight: bold;
			color: $color-brand-1;
			display: flex;
			align-items: center;
		}

		&-add-icon {
			border: 1px solid $color-brand-1;
			border-radius: 50%;
			margin-right: 9px;
		}

		&-info {
			@include screen-sm {
				flex-wrap: wrap;
			}
		}
	}

	&__customization {
		background: #fff;
		display: flex;
		align-items: center;
		padding: 26px 37px;
		margin-bottom: 32px;
		cursor: pointer;
		border-radius: 4px;
		border: 1px solid rgb(176, 186, 201);
		height: 130px;
		width: 392px;

		&:hover {
			box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
		}

		&-icon {
			min-width: 24px;
			min-height: 24px;
			margin-right: 32px;
		}

		&-title {
			margin-top: 24px;
			font-size: 18px;
			font-weight: 600;
			color: $color-brand-1;
			margin-bottom: 24;
		}

		&-text {
			font-size: 16px;
			color: #292e31;
		}

		&-arrow {
			min-width: 20px;
			height: 20px;
		}
	}

	&__cookies {
		&-title {
			font-size: 16px;
			font-weight: bold;
			color: $color-brand-1;
		}

		&-textarea {
			margin-bottom: 48px;
			margin-top: 16px;
			width: 50% !important;
		}

		&-toggle {
			background: #fff !important;
			border-radius: 5px;
			border: 1px solid rgb(176, 186, 201);
			min-height: 38px;
			width: 325px;
			color: #333 !important;
			border: 1px solid #b0bac9 !important;
			margin-top: 24px;
			margin-bottom: 48px;
			outline: none !important;
			box-shadow: none !important;
		}

		&-menu-item {
			&:active {
				background-color: #f8f9fa !important;
				outline: none;
				color: #333 !important;
			}
		}
	}

	&__cookie {
		display: flex;
		align-items: center;
		background: #fff;
		border-radius: 4px;
		height: 59px;
		width: 437px;
		padding-left: 7px;
		padding-right: 34px;
		margin-bottom: 24px;

		.delete-cookie {
			display: none;
		}

		&:hover {
			.delete-cookie {
				display: block;
			}
		}

		@include screen-sm {
			width: 100%;
		}

		&.-is-active {
			box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
		}

		.check {
			margin-left: 0;
			margin-right: 0;
		}

		&-check {
			margin-left: 16px;
			margin-right: 24px;
		}

		&-title {
			font-size: 14px;
			font-weight: bold;
			color: $color-font-gray;
		}

		&-text {
			font-size: 14px;
			color: $color-font-gray;
		}

		&-edit {
			flex: 1;
			justify-content: flex-end;
			font-size: 14px;
			font-weight: bold;
			color: $color-brand-3;
			cursor: pointer;
		}

		&-icon {
			margin-left: 10px;
		}

		&-save {
			width: 136px !important;

			&:first-child {
				margin-right: 24px;
			}
		}
	}

	&__plans {
		&-title {
			font-size: 18px;
			font-weight: 600;
			padding-top: 39px;
			margin-bottom: 17px;
		}

		&-delete {
			width: 136px !important;

			&:first-child {
				margin-right: 8px;
			}
		}

		&-payment {
			display: flex;
			margin-bottom: 10px;
			align-items: center;

			&-img {
				border-radius: 4px;
				border: 1px solid $color-lightest-gray;
				height: 40px;
				width: 64px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 20px;
				cursor: pointer;

				&.-is-active {
					border-color: $color-brand-1;
				}
			}

			&-bank {
				font-size: 14px;
				color: $color-font-gray;
				margin-bottom: 0;
				margin-right: 29px;
			}

			&-use {
				color: $color-brand-1;
				font-size: 14px;
				margin-bottom: 0;
				cursor: pointer;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	&__plan {
		&-item {
			background: #fff;
			border-radius: 4px;
			box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
			height: 201px;
			width: 160px;
			margin-right: 24px;
			margin-bottom: 24px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			&.-is-active {
				border: 1px solid $color-brand-1;
			}
		}

		&-title {
			margin-top: 9px;
			margin-bottom: 0;
			text-align: center;
			font-size: 14px;
			font-weight: 600;
			margin-bottom: 15px;
		}

		&-price {
			font-size: 14px;
			color: $color-font-gray;
			margin-bottom: 23px;
		}

		&-btn {
			width: 96px !important;

			&.-simple {
				background: transparent !important;
				color: $color-brand-1;
				font-weight: bold;
				border: none;
				font-size: 14px;

				&:hover {
					color: $color-brand-1;
				}
			}
		}
	}

	&__notifications {
		&-label {
			font-size: 16px;
			font-weight: bold;
			color: $color-font-gray;
			margin-bottom: 0;
		}

		&-dropdown {
			font-size: 16px !important;
			background: transparent !important;
			border: none !important;
			color: $color-font-gray !important;
			padding: 0 !important;
		}

		&-toggle {
			margin-right: 50px;

			@include screen-sm {
				margin-right: 0;
			}
		}

		&-item {
			margin-top: 40px;
		}
	}

	&__captcha {
		&-item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: #fff;
			border-radius: 4px;
			height: 59px;
			max-width: 280px;
			padding: 8px 16px;
			margin-bottom: 32px;
			cursor: pointer;

			&.-is-active {
				box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
			}

			&:first-child {
				margin-top: 65px;
			}

			&-title {
				font-size: 14px;
				font-weight: bold;
				color: $color-font-gray;
				margin-bottom: 0;
			}

			&-text {
				font-size: 12px;
				color: $color-font-gray;
				margin-bottom: 0;
			}
		}

		&-divider {
			height: 177px;
			width: 1px;
			background: $color-lightest-gray;
			margin-right: 34px;
			margin-left: 34px;
		}

		&-preview {
			margin-bottom: 59px;
			font-size: 14px;
			font-weight: 600;
			text-align: center;
		}

		&-agree {
			font-size: 14px;
			color: $color-font-gray;
			margin-bottom: 0;
		}

		&-check {
			background: #fff;
			border-radius: 4px;
			border: 1px solid $color-font-light;
			min-height: 13px;
			min-width: 13px;
			display: block;
			margin-right: 8px;

			&.-is-checked {
				background: $color-brand-1;
			}
		}

		&-terms {
			color: $color-brand-1;
		}

		&-copy {
			width: 136px !important;

			&:first-child {
				margin-right: 16px;
			}
		}

		&-btns {
			margin-top: 32px;
		}
	}

	&__terms {
		background: #fff;
		border-radius: 4px;
		box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
		height: 393px;
		margin-top: 32px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-left: 32px;
		padding-right: 32px;

		&-title {
			margin-bottom: 56px;
			font-size: 18px;
			font-weight: 600;
			color: $color-brand-1;
		}

		&-label {
			font-size: 14px;
			font-weight: 600;
			text-align: center;
			margin-bottom: 16px;
			margin-top: 32px;

			&:first-child {
				margin-top: 0;
			}
		}

		&-form {
			input {
				background: #fff;
				border-radius: 5px;
				border: 1px solid $color-font-light;
				height: 38px;
				width: 325px;
				font-size: 14px;

				&::placeholder {
					color: $color-font-light;
				}

				&:focus {
					box-shadow: none;
					border: 1px solid $color-font-light;
				}
			}
		}
	}
}

.customize-menu {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	// height: calc(100vh - 200px);
	height: calc(100vh - 237px);

	.customise-text__font {
		width: 100% !important;
	}

	&__container {
		overflow: scroll;
		height: 100%;
	}

	&__item {
		padding-top: 22px;
		padding-bottom: 22px;
		border-bottom: 1px solid #dfe4ee;
		width: 100%;
		cursor: pointer;
	}

	&__label {
		font-size: 16px;
		font-weight: bold;
	}

	&__back {
		position: absolute;
		left: 0;
		color: #333;
		cursor: pointer;
	}

	&__logo {
		margin-top: 24px;
		font-size: 14px;
		font-weight: bold;
		margin-bottom: 4px;
	}

	&__logo-container {
		border-bottom: 1px solid #dfe4ee;
	}

	&__title {
		font-size: 16px;
		font-weight: bold;
	}

	&__header {
		margin-top: 40px;
		border-bottom: 1px solid #dfe4ee;
		padding-bottom: 24px;
	}
}

.customization {
	display: flex;
	height: calc(100vh - 90px);

	@include screen-sm {
		height: auto;
	}

	&__back {
		width: 100%;
		color: #7450c8;
		font-size: 14px;
		font-weight: bold;
		cursor: pointer;
		margin-bottom: 30px;
	}

	&__container {
		width: 100%;

		@include screen-sm {
			flex-direction: column;
			display: block !important;
		}
	}

	&.-is-sample {
		height: calc(100vh - 142px);
	}

	.btn-round {
		border-radius: 30px !important;
	}

	.show-upload {
		width: 160px;
		height: 160px;
		border-radius: 50%;
		position: absolute;
		cursor: pointer;
		top: 0;
	}

	&__navigation {
		min-width: 256px;
		background: #fff;
		border-radius: 1px;
		border: 1px solid rgba(46, 91, 255, 0.08);
		height: 100vh;
		box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
		padding-top: 48px;
		padding-left: 25px;
		padding-right: 25px;

		@include screen-sm {
			height: 400px;
		}

		@include screen-md {
			padding-left: 5px;
			padding-right: 5px;
			min-width: 226px;
		}

		&-title {
			display: flex;
			align-items: center;
			font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
			font-size: 22px;
			letter-spacing: -0.16px;
			margin-bottom: 48px;
		}
	}

	&__content {
		padding: 48px 0px 20px 23px;
		display: flex;
		width: 100%;
		position: relative;
		overflow: hidden;
		align-items: flex-start;

		@include screen-sm {
			padding: 48px 0px 20px 10px;
		}

		> div {
			@include screen-sm {
				flex-direction: column;
			}
		}

		.-has-scroll {
			overflow: auto;
			height: calc(100vh - 90px);
		}

		&-title {
			font-size: 22px;
			font-weight: bold;
		}
	}

	&-cookie {
		width: 100%;

		&__divider {
			width: 100%;
			height: 1px;
			background: #d8d8d8;
		}

		&__collect {
			display: flex;
			width: 100%;
			margin-top: 24px;
			padding-left: 44px;
			font-size: 14px;
			font-weight: bold;
			color: $color-brand-1;
			cursor: pointer;
		}

		&__save {
			width: 100%;
			padding: 0 44px;
		}

		&__menus {
			@include screen-sm {
				flex-direction: column;
			}
		}

		&__form-group {
			width: 100%;
			max-width: 624px;
		}

		&__publish,
		&__preview {
			&.-is-site-btn {
				max-width: 182px;
			}
		}

		&__back {
			width: 100%;
			color: #7450c8;
			font-size: 14px;
			font-weight: bold;
			padding-left: 20px;
			cursor: pointer;
		}

		&.-has-scroll {
			height: 100%;
			overflow: auto;
		}

		&__view {
			width: 921px;
			padding: 0 !important;
			margin-bottom: 0 !important;
			margin-top: 0 !important;
			height: 100vh;
			max-height: 683px;

			.page-view__header {
				text-align: left;
			}

			.page-view__left-menu {
				text-align: left;
			}

			.page-view__publication {
				text-align: left;
			}
		}

		&__avatar-container {
			cursor: pointer;
			overflow: hidden;
			max-height: 63px;
			min-width: 63px;
			width: 80px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&__template-img {
			position: absolute;
			left: 0;
			right: 0;
			margin-left: auto;
			margin-right: auto;
			top: 0;
			bottom: 0;
			margin-top: auto;
			margin-bottom: auto;
			pointer-events: none;
		}

		&__image-placeholder {
			background: #b0bac9;
			box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
			height: 72px;
			width: 72px;
			display: block;
			border-radius: 50%;
			cursor: pointer;
		}

		&__title {
			font-size: 22px;
			font-weight: bold;
			margin-bottom: 64px;
		}

		&__subtitle {
			font-size: 14px;
			color: $color-font-gray;
			margin-bottom: 48px;
		}

		&__input {
			max-width: 223px;
			margin-bottom: 24px;

			&-large {
				max-width: 568px;
				margin-bottom: 24px;
				width: 100% !important;
			}
		}

		&__textarea {
			min-height: 221px;
			max-width: 568px;
		}

		&__bg-screen {
			border: 1px solid rgb(176, 186, 201);
			box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
			height: 29px;
			width: 29px;
			display: block;
			border-radius: 50%;

			&.-white {
				background: #fff;
			}

			&.-gray {
				background: $color-font-gray;
			}
		}

		&__container {
			display: flex;
			flex-direction: column;
			flex: 1;
			height: calc(100vh - 90px);
			overflow: auto;
			padding-bottom: 40px;
			padding-right: 20px;

			&.-is-sample {
				height: calc(100vh - 142px);
			}
		}

		&__publish-btns {
			@include screen-sm {
				flex-direction: column;
			}
		}

		&__right-menu {
			right: 0;
			background: #fff;
			border-radius: 1px;
			border: 1px solid rgba(46, 91, 255, 0.08);
			top: 90px;
			height: calc(100vh - 90px);
			overflow: auto;
			max-width: 310px;
			min-width: 310px;
			box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
			padding-top: 48px;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: -48px;

			&.-is-site {
				max-width: 437px;
				min-width: 437px;

				@include screen-sm {
					max-width: 100%;
					min-width: 100%;
				}
			}

			&.-is-sample {
				height: calc(100vh - 142px);
			}

			@include screen-md {
				min-width: 210px;
			}

			&-snippet {
				margin-top: 17px;
				border-bottom: 1px solid #dfe4ee;
				width: calc(100% - 60px);
				margin-bottom: 16px;
				padding-bottom: 16px;

				@include screen-md {
					padding-left: 10px;
				}
			}

			&-logo {
				width: 63px;
				height: 63px;
				border-radius: 50%;
				box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			&-change {
				font-size: 14px;
				font-weight: 600;
				color: $color-font-gray;
				margin-left: 16px;
				cursor: pointer;

				&:hover {
					text-decoration: underline;
				}
			}

			&-label {
				font-size: 14px;
				font-weight: bold;
				color: $color-font-gray;
				margin-bottom: 11px;
			}

			&-btns {
				margin-top: 48px;
				margin-bottom: 20px;

				.btn {
					width: 136px !important;

					@include screen-md {
						margin-bottom: 10px;
					}
				}

				@include screen-md {
					flex-direction: column;
				}
			}

			&-bg,
			&-btn {
				border: 1px solid #979797;
				box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
				height: 38px;
				width: 38px;
				display: block;
				border-radius: 4px !important;
			}

			&-bg {
				background: #fafafa;
			}

			&-btn {
				background: $color-brand-1;
			}

			&-option {
				margin-left: 44px;
				margin-bottom: 12px;

				&:first-child {
					margin-left: 0;
				}
			}

			&-content {
				padding-left: 30px;
				margin-right: auto;

				@include screen-md {
					padding-left: 10px;
					padding-right: 10px;
				}

				&.-is-site-content {
					padding-left: 30px;
					padding-right: 30px;
				}
			}
		}

		&__preview {
			margin-bottom: 16px;
		}

		&__preview-mail {
			margin-bottom: 32px;
			background-color: $color-light-gray !important;
			border-radius: 4px !important;
			height: 40px !important;
			width: 223px !important;
			border: none !important;
			color: $color-brand-1 !important;
			font-weight: bold !important;
			font-size: 14px !important;
		}

		.check {
			border-radius: 4px;
			border: 1px solid $color-font-light;
			display: block;
			min-height: 13px;
			min-width: 13px;
			max-height: 13px;
			max-width: 13px;
			margin-right: 8px;

			&.-is-active {
				background: $color-brand-1;
			}
		}

		&__text {
			font-size: 14px;
			font-weight: bold;
			color: $color-font-gray;
			width: 180px;
		}

		&__check {
			position: relative;
			top: -10px;
			right: 13px;

			&-item {
				font-size: 14px;
				color: $color-font-gray;
				margin-right: 48px;
				width: 90px;
			}

			&-container {
				margin-bottom: 32px;
			}
		}

		input {
			width: 223px;
			margin-top: 8px;
			margin-bottom: 24px;
		}

		textarea {
			margin-top: 8px;
			min-height: 221px !important;
			margin-bottom: 20px;
			max-width: 568px;
		}

		&__img {
			cursor: pointer;

			&.-is-active {
				border-radius: 4px;
				border: 2px solid $color-brand-4;
				box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
				height: 154px;
				width: 212px;
			}
		}

		&__item {
			margin-bottom: 48px;

			@include screen-sm {
				flex-direction: column;
				align-items: center;
			}
		}

		&__next {
			position: relative;
			top: -50px;
			margin-left: 40px;
			margin-top: 60px;
		}

		&__vertical,
		&__overlay {
			background: rgb(255, 255, 255);
			box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
			padding: 24px 16px 12px 16px;
		}

		&__small {
			background: rgb(255, 255, 255);
			box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
			margin-top: 32px;
			padding: 24px 16px 12px 16px;
			margin-bottom: 20px;
		}

		&__page-view {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			padding: 10px;

			span {
				display: flex;
				width: 100%;
				justify-content: flex-end;
			}

			.customization-cookie__popup-text {
				text-align: center;
			}
		}

		&__overlay {
			max-width: 525px;
			min-width: 525px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			max-width: 681px;
			// margin-bottom: 0 !important;

			@include screen-sm {
				min-width: 100%;
				margin-bottom: 20px !important;
			}

			.customization-cookie__get {
				margin-left: auto;
				top: 0;
			}
		}

		&__vertical {
			max-width: 644px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;

			.customization-cookie__get {
				margin-left: auto;
				top: 0;
			}
		}

		&__small {
			width: 280px;
		}

		&__label {
			font-size: 14px;
			font-weight: bold;
			color: $color-font-gray;
			width: 100px;
			margin-right: 27px;

			&-secondary {
				font-size: 14px;
				color: $color-font-gray;
				width: 100px;
				margin-right: 27px;
			}
		}

		&__popup-title {
			font-size: 22px;
			font-weight: bold;
			color: $color-brand-1;
			margin-bottom: 24px;
		}

		&__popup-text {
			font-size: 14px;
			color: $color-font-gray;
			margin-bottom: 24px;
		}

		&__popup-accept,
		&__popup-options {
			min-height: 36px !important;
			padding: 15px !important;
		}

		&__popup-accept {
			margin-right: 16px;
			// font-weight: bold !important;
			border-radius: 4px !important;
			border: none !important;
		}

		&__popup-options {
			background: transparent !important;
			border: none !important;
			// font-weight: bold !important;
		}

		&__get {
			font-size: 12px;
			color: $color-brand-1;
			position: relative;
			top: 5px;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&__tip {
		background: rgba(116, 80, 200, 0.2);
		border-radius: 4px;
		min-height: 40px;
		max-width: 629px;
		width: 100%;
		padding-left: 33px;
		padding-right: 33px;
		display: flex;
		align-items: center;
		font-size: 14px;
		font-weight: 600;
		color: $color-brand-1;

		@include screen-sm {
			min-height: 70px;
		}

		&-text {
			font-size: 14px;
			color: $color-font-base;
			margin-left: 16px;
			font-weight: 500;
		}
	}
}

.customise-text {
	flex-direction: column;
	position: relative;
	margin-top: 16px;

	&__description {
		&:focus {
			outline: none;
		}
	}

	&:after {
		content: '';
		position: absolute;
		height: 1px;
		width: 267px;
		background: $color-lightest-gray;
		bottom: 0;
		left: -22px;
	}

	&.-is-legalsite {
		padding-bottom: 20px;
		margin-bottom: 20px;

		&:after {
			content: '';
			position: absolute;
			height: 1px;
			width: 375px;
			background: $color-lightest-gray;
			bottom: 0;
			left: 0px;
		}
	}

	&.-is-branding {
		padding-bottom: 20px;
		margin-bottom: 20px;
		width: 347px;

		&:after {
			content: '';
			position: absolute;
			height: 1px;
			width: 375px;
			background: $color-lightest-gray;
			bottom: 0;
			left: 0px;
		}
	}

	&__label {
		margin-bottom: 11px;
		font-size: 14px;
		font-weight: bold;
		color: #969696;

		&-secondary {
			font-size: 14px;
			color: $color-font-gray;
		}
	}

	&__textarea {
		height: 199px !important;
		width: 100% !important;
		font-size: 14px !important;
		color: $color-font-gray !important;
	}

	select.form-control {
		appearance: none;
	}

	select {
		width: 73px;
		color: $color-font-gray;
	}

	&__font {
		width: 223px !important;

		&.-is-branding {
			width: 100% !important;
		}
	}

	&__color {
		background: rgb(51, 51, 51);
		border-radius: 4px;
		border: 1px solid $color-lightest-gray;
		height: 38px;
		width: 38px;
		margin-right: 24px;
		min-width: 38px;

		&:active,
		&:focus {
			outline: none;
			box-shadow: none !important;
		}
	}
}

.customization-email {
	display: flex;
	flex-direction: column;
	flex: 1 1;
	height: calc(100vh - 90px);
	overflow: auto;
	padding-bottom: 40px;
	padding-right: 20px;
	margin-bottom: 40px;

	&.-is-branding {
		height: 100%;
		margin-bottom: 20px;
	}

	&__logo-container {
		max-height: 60px;
		height: 60px;
		min-width: 63px;
		width: 100%;
		max-width: 150px;

		img {
			object-fit: contain;
			max-height: 60px;
			width: 100%;
		}
	}

	&__tip {
		max-width: 629px;
		width: 100%;
	}

	&__overlay {
		position: absolute;
		background: rgba(223, 228, 238, 0.86);
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		cursor: pointer;
		display: none;

		&-text {
			font-size: 18px;
			font-weight: 600;
			margin-top: 10px;
		}
	}

	&__subjects {
		padding-right: 45px;

		&-container {
			width: 435px !important;
		}

		&-title {
			font-size: 22px;
			font-weight: bold;
			letter-spacing: -0.16px;
			max-width: 600px;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
		}

		&-subtitle {
			color: $color-font-gray;
			font-size: 14px;
		}

		&-text {
			font-size: 14px;
			max-width: 481px;
			color: $color-font-gray;
			margin-bottom: 32px;
		}

		&-label {
			font-size: 14px;
			font-weight: bold;
			color: $color-font-gray;
			margin-bottom: 24px;
		}

		&-item {
			background: #fff;
			border-radius: 4px;
			box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
			min-height: 87px;
			width: 100%;
			display: flex;
			margin-bottom: 24px;
			align-items: center;
			justify-content: space-between;
			padding: 16px 24px;
			cursor: pointer;

			&-title {
				font-size: 18px;
				font-weight: 600;
				margin-bottom: 8px;
			}

			&-text {
				font-size: 14px;
				color: $color-font-gray;
				margin-bottom: 0;
			}
		}
	}

	&__header {
		height: 61px;
		border-bottom: 1px solid $color-lightest-gray;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 56px;
		padding-right: 24px;

		&-logo {
			margin-bottom: 0;
			font-size: 20px;
			letter-spacing: 0.71px;
			font-family: 'Source Serif Pro', Roboto, serif;
			color: $color-brand-1;
		}

		&-link {
			font-size: 10px;
			color: $color-brand-1;
		}
	}

	&__preview {
		&-header {
			background: rgb(176, 186, 201);
			border-radius: 0px;
			box-shadow: 2px 4px 16px 0px rgba(51, 51, 51, 0.08);
			height: 52px;
			width: 100%;
			display: flex;
			position: absolute;
			top: 0;
			left: 0;
			justify-content: center;
			align-items: center;
		}

		&-title {
			font-size: 18px;
			font-weight: 600;
			color: #fff;
			margin-bottom: 0;
		}

		&-close {
			position: absolute;
			right: 46px;
			cursor: pointer;
		}

		&-exit {
			font-size: 12px;
			font-weight: bold;
			color: #fff;
			cursor: pointer;
		}
	}

	&__title {
		font-size: 22px;
		font-weight: bold;
		margin-bottom: 14px;
	}

	&__text {
		font-size: 14px;
		color: $color-font-gray;
		margin-bottom: 65px;
	}

	&__email-template {
		background: #fff;
		border-radius: 0px;
		box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
		max-width: 600px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 40px;
	}

	&__logo {
		height: 46px;
		width: 46px;
		display: flex;
		background: $color-font-light;
		border-radius: 50%;
		margin-left: 26px;
	}

	&__banner {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		padding-bottom: 56px;
		display: flex;
		justify-content: center;

		&:hover {
			.customization-email__overlay {
				display: flex;
			}
		}

		img {
			width: 100%;
			height: 267px;
			object-fit: cover;
			margin-top: 0;
		}

		img[alt*="getbanner"] {
			width: 200px;
			margin-top: 32px;
			height: 100%;
			object-fit: cover;
			margin-top: 32px;
		}
	}

	&__close {
		margin-top: 26px;
		margin-left: auto;
		display: block;
		margin-right: 25px;
	}

	&__email {
		&-title {
			font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
			letter-spacing: -0.13px;
			font-size: 22px;
			text-align: center;
			margin-bottom: 0;
			width: 100%;
			display: block;
			text-align: center;
			margin-top: 16px;
			white-space: pre-wrap;
			text-align: left;

			&:focus {
				outline: none;
			}
		}

		&-text {
			font-size: 14px;
			color: $color-font-gray;
			max-width: 477px;
			margin-bottom: 17px;
			display: block;
			width: 100%;
			text-align: center;
			white-space: pre-wrap;
			text-align: left;
			padding-left: 48px;

			&:focus {
				outline: none;
			}
		}
	}

	&__subtitle {
		font-size: 14px;
		color: $color-font-gray;
		text-align: center;
		margin-bottom: 24px;
		overflow: auto;
		width: 100%;
		display: block;
		margin-top: 24px;
		white-space: pre-wrap;
		text-align: left;

		&:focus {
			outline: none;
		}
	}

	&__button {
		min-height: 19px;
		min-width: 51px !important;
		width: auto !important;
		text-align: center;
		padding: 0 10px;
		// font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none !important;
		margin-bottom: 32px;
		border-radius: 4px !important;
		min-height: 36px;
		min-width: 136px;
		padding: 7px !important;
		margin-left: 48px;

		&:focus {
			outline: none;
		}
	}

	&__footer {
		background: #dfe4ee;
		padding: 16px 28px;
		margin-top: 37px;

		&-text {
			font-size: 9px;
			max-width: 423px;
			width: 100%;
			text-align: center;
			margin-left: auto;
			margin-right: auto;
			color: #818181;
			margin-bottom: 6px;
			display: block;
			white-space: pre-wrap;

			&:focus {
				outline: none;
			}
		}

		&-link {
			font-size: 9px;
			color: $color-brand-1;
			text-align: center;
			margin-bottom: 16px;
			white-space: pre-wrap;
		}

		&-divider {
			height: 1px;
			width: 323px;
			background: $color-font-light;
			display: block;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 16px;
			margin-top: 16px;
		}

		&-info {
			font-size: 8px;
			max-width: 464px;
			text-align: center;
			display: block;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 8px;
			font-weight: bold;
			white-space: pre-wrap;
		}

		&-unsubscribe {
			font-size: 8px;
			text-decoration: underline;
			display: block;
			margin-left: auto;
			text-align: center;
			white-space: pre-wrap;
			margin-right: auto;
		}
	}

	&__share {
		height: 19px;
		width: 19px;
		border-radius: 50%;
		background: $color-font-gray;
		margin-right: 11px;
	}
}

.customization-site {
	display: flex;
	flex-direction: column;
	flex: 1 1;
	height: calc(100vh - 90px);
	overflow-y: auto;
	padding-bottom: 40px;
	margin-right: 20px;
	margin-bottom: 40px;
	overflow-x: hidden;
	box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);

	&.-is-branding {
		height: 430px;
		width: 605px;
		padding-bottom: 0;
	}

	&__branding-title {
		font-size: 16px;
		font-weight: bold;
		color: #333;
	}

	&__branding-content {
		overflow: scroll;
		height: 100vh;
	}

	&__branding {
		&-popup {
			min-height: 234px;
			width: 250px;
			background: #fff;
			border-radius: 0px;
			box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
			margin-top: 40px;
			position: relative;
			padding: 15px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		&-close {
			position: absolute;
			right: 5px;
			top: 5px;
		}

		&-subtitle {
			color: $color-brand-1;
			font-weight: bold;
		}

		&-subtext {
			font-size: 14px;
			color: $color-light-gray2;
		}

		&-get {
			margin-bottom: 0;
			font-size: 14px;
			color: $color-brand-1;
			margin-top: 10px;
		}
	}

	.-is-contained {
		border-radius: 4px;
		min-height: 36px;
		min-width: 136px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-left: 10px;
		padding-right: 10px;
	}

	.footer {
		&__container {
			padding-left: 72px;
			padding-right: 72px;
			margin-left: auto;
			margin-right: auto;
			justify-content: space-around;
			padding-top: 49px;
			padding-bottom: 49px;
			background: #dfe4ee;
		}

		&__logo {
			background: $color-brand-1;
			opacity: 0.65;
			height: 45px;
			width: 45px;
			display: block;
			border-radius: 50%;
			margin-right: 24px;
			min-width: 45px;

			&-container {
				height: 45px;
				display: block;
				margin-right: 24px;
				overflow: hidden;
				max-width: 150px;

				img {
					height: 100%;
					object-fit: contain;
					width: 100%;
				}
			}
		}

		p {
			font-size: 14px;
		}

		a {
			color: $color-font-base;
			font-size: 14px;

			&:hover {
				color: $color-font-base;
				text-decoration: none;
			}
		}

		&-text {
			border-top: 1px solid #969696;
			text-align: center;
			padding-top: 26px;
			padding-bottom: 26px;
			background: #dfe4ee;
			font-size: 14px;
		}
	}

	&.-is-sample {
		height: calc(100vh - 142px);
	}

	&__header {
		min-height: 61px;
		border-bottom: 1px solid #dfe4ee;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 56px;
		padding-right: 24px;
	}

	&__logo-img {
		width: 35px;
		height: 35px;
		background: $color-brand-1;
		opacity: 0.6;
		display: block;
		border-radius: 50%;
		margin-right: 10px;
	}

	&__container {
		box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
	}

	&__header {
		height: 52px;
		background: #fff;
		box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
		padding-left: 70px;
		padding-right: 70px;
	}

	&__avatar {
		width: 30px;
		height: 30px;
		background: $color-brand-1;
		border-radius: 50%;
		margin-right: 20px;
		opacity: 0.6;
	}

	&__logo {
		font-size: 16px;
		color: $color-brand-1;
		font-weight: bold;
		margin-bottom: 0;
		opacity: 0.6;
		max-width: 150px;

		&.-no-opacity {
			opacity: 1;
		}

		img {
			object-fit: contain;
			width: 100%;
			max-height: 60px;
		}
	}

	&__get {
		margin-bottom: 0;
		font-size: 14px;
		color: $color-brand-1;
		border-radius: 4px;
		border: 1.5px solid rgb(116, 80, 200);
		height: 36px;
		display: flex;
		align-items: center;
		padding: 8px 12px;
	}

	&__items-container {
		transform: scale(0.7);
		margin-top: -30px;
	}

	&__item {
		background: #fff;
		border-radius: 4px;
		box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
		min-height: 214px;
		max-width: 999px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 24px;
		width: 95%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px;
		flex-direction: column;

		&-title {
			font-size: 18px;
			font-weight: 600;
			color: $color-brand-1;
			margin-bottom: 0;
		}

		&-text {
			font-size: 16px;
			color: $color-font-gray;
			max-width: 338px;
			margin-top: 24px;
			margin-bottom: 24px;
			text-align: center;
		}

		&-btn {
			width: 136px !important;
		}
	}

	&__btn {
		min-height: 36px !important;
		min-width: 122px !important;
		padding: 1px 10px 0 10px !important;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold !important;
		border: none !important;
	}

	&__welcome {
		&-title {
			font-size: 56px;
			font-weight: 600;
			letter-spacing: -0.54px;
			text-align: center;
			padding-top: 70px;
			font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
			transform: scale(0.7);
		}

		&-text {
			font-size: 14px;
			color: $color-font-gray;
			text-align: center;
			width: 90%;
			margin-left: auto;
			margin-right: auto;
			transform: scale(0.7);
		}

		&-section {
			min-height: 365px;
			background: $color-lightest-gray;
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	&__info {
		margin-top: -16px;

		&-privacy {
			background: #fff;
			border-radius: 4px;
			box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
			min-height: 258px;
			max-width: 219px !important;
			min-width: 219px !important;
			margin-right: 20px;
			margin-left: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			text-align: center;
			margin-bottom: 20px;

			svg {
				margin-top: 32px;
				min-height: 31px;
				margin-bottom: 25px;
			}

			@include screen-md {
				margin-right: 20px;
				margin-top: 30px;
			}
		}

		&-subtitle {
			font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
			font-size: 28px;
			letter-spacing: -0.2px;
			text-align: center;
			margin-top: 60px;
			margin-bottom: 17px;
			transform: scale(0.7);
		}

		&-details {
			font-size: 18px;
			color: $color-font-gray;
			max-width: 695px;
			text-align: center;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 0px;
			transform: scale(0.7);

			&-item {
				width: 163px;
				min-height: 200px;
				text-align: center;
				margin-left: 72px;
				margin-right: 72px;
				margin-bottom: 0px;
				transform: scale(0.7);
			}
		}

		&-title {
			font-size: 18px;
			font-weight: 600;
			color: $color-brand-1;
			margin-bottom: 24px;
		}

		&-text {
			font-size: 14px;
			color: $color-font-gray;
			margin-bottom: 21px;
		}

		&-more {
			font-size: 14px;
			font-weight: bold;
			color: $color-brand-1;
		}

		&-content {
			justify-content: center;
			position: relative;
			top: -140px;
			margin-bottom: -140px;
			transform: scale(0.7);
		}
	}
}

.agreement-item {
	background: #fff;
	border-radius: 4px;
	box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
	min-height: 87px;
	max-width: 435px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 16px 10px 24px;
	cursor: pointer;
	margin-bottom: 24px;

	&__title {
		font-size: 18px;
		font-weight: 600;
		margin-bottom: 8px;
	}

	&__text {
		font-size: 14px;
		color: $color-font-gray;
		margin-bottom: 0;
	}
}

.page-view {
	background: rgb(255, 255, 255);
	box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
	min-height: 603px;
	max-width: 537px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 32px;
	margin-bottom: 32px;

	&__content {
		display: flex;
		justify-content: space-between;
		padding-top: 30px;
		background: $color-light-gray;
		height: 100%;
		overflow: scroll;

		@include screen-sm {
			flex-direction: column;
		}
	}

	&__pages {
		display: flex;
		flex-wrap: wrap;
		min-height: 54px;
		align-items: center;
		background: #f4f6fc;

		&-title {
			flex: 0 50%;
			font-size: 10px;
			color: $color-brand-1;
			font-weight: bold;
			text-align: center;
			margin-bottom: 0;
		}
	}

	&__close {
		cursor: pointer;
	}

	&__container {
		overflow: hidden;
		height: 100%;
		width: 100%;

		img {
			width: 100%;
		}
	}

	&__left-menu {
		padding-left: 50px;
		width: 30%;

		@include screen-sm {
			width: 100%;
			margin-bottom: 20px;
		}

		&-item {
			margin-bottom: 5px;
			color: $color-brand-1;
			font-size: 10px;
			font-weight: bold;
			word-break: break-all;
		}
	}

	&__publication {
		width: 70%;
		padding-right: 30px;

		@include screen-sm {
			padding-left: 50px;
			width: 100%;
		}

		&-title {
			font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
			font-size: 12px;
			font-weight: 600;
			letter-spacing: -0.16px;
			margin-bottom: 8px;
		}

		&-text {
			font-size: 10px;
			margin-bottom: 20px;
			color: $color-font-gray;
		}

		blockquote {
      overflow: hidden;
      padding-right: 1.5em;
      padding-left: 1.5em;
      margin-left: 0;
      margin-right: 0;
      font-style: italic;
      border-left: 5px solid #ccc;
    }

    .table {
      table {
        width: 100%;
      }

      td {
        border: 1px solid #dee2e6 !important;
      }
    }
	}

	&__close {
		color: $color-font-gray;
		position: absolute;
		right: 15px;
		top: 15px;
	}

	&__header {
		background: $color-lightest-gray;
		min-height: 120px;
		padding-left: 50px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		position: relative;
	}

	&__title {
		font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
		font-size: 22px;
		font-weight: 600;
		letter-spacing: -0.16px;
		margin-bottom: 8px;
	}

	&__subtitle {
		font-size: 10px;
		color: $color-font-gray;
		margin-bottom: 0;
	}
}

.user-view {
	height: calc(100vh - 90px);
	overflow: scroll;
	padding-top: 48px;
	padding-bottom: 36px;
	position: relative;

	.chart__dropdown.show .chart__toggle {
		border: none !important;
	}

	select {
		min-width: 325px;
	}

	.dropdown-item {
		outline: none;

		&:active {
			background: transparent;
			color: $color-font-base;
		}
	}

	&__show-upload {
		height: 50px;
		width: 50px;
		display: block;
		position: absolute;
		top: 0;
		cursor: pointer;
	}

	@include screen-sm {
		height: calc(100vh - 53px);
	}

	&__close {
		position: absolute;
		top: 21px;
		right: 0;
		font-size: 12px;
		font-weight: bold;
		cursor: pointer;

		@include screen-sm {
			right: 10px;
		}
	}

	&__title {
		font-size: 22px;
		font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
		letter-spacing: -0.16px;
	}

	&__container {
		background: #fff;
		border-radius: 4px;
		box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
		min-height: 468px;
		margin-top: 25px;
		padding: 24px 32px;
	}

	&__info {
		font-size: 18px;
		font-weight: 600;
		color: $color-brand-1;
		margin-bottom: 24px;
	}

	select {
		-webkit-appearance: none;
		width: 100%;
	}

	&__avatar {
		height: 50px;
		width: 50px;
		border-radius: 50%;
		background: $color-font-gray;
		overflow: hidden;
		display: flex;
		margin-right: 24px;
		object-fit: cover;
		color: #fff;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		@include screen-xs {
			margin-bottom: 24px;
		}
	}

	.form {
		width: 325px;
		margin-bottom: 24px;

		@include screen-xs {
			width: 100%;
		}
	}

	&__form {
		margin-right: 100px;

		@include screen-xs {
			margin-right: 0;
		}
	}

	&__download,
	&__delete {
		font-size: 14px;
		font-weight: bold;
		color: $color-brand-1;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		cursor: pointer;
	}

	&__download {
		margin-right: 24px;

		&:hover {
			color: $color-brand-1;
			text-decoration: none;
		}
	}

	input {
		margin-bottom: 24px;
	}

	&__label {
		font-size: 14px;
		font-weight: bold;
		margin-bottom: 10px;
	}

	&__view {
		display: flex;
		align-items: center;
		color: $color-brand-1;
		font-size: 12px;
		font-weight: bold;
		margin-bottom: 16px;

		&:hover {
			text-decoration: none;
			color: $color-brand-1;
		}
	}

	&__added {
		font-size: 18px;
		font-weight: 600;
		color: $color-brand-1;
		margin-bottom: 24px;
	}

	&__date {
		font-size: 14px;
		font-weight: bold;
		color: $color-font-gray;
	}

	&__details {
		@include screen-xs {
			flex-direction: column;
			width: 100%;
		}
	}

	&__btn {
		display: flex;
		align-items: flex-end;

		.btn {
			width: 136px !important;
		}
	}
}

.data-processing {
	&__item {
		background: #fff;
		border-radius: 4px;
		border: 1px solid rgb(176, 186, 201);
		height: 440px;
		width: 324px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 16px;
		flex-direction: column;
		position: relative;
		cursor: pointer;
		margin-bottom: 24px;

		&.-is-active {
			&:before {
				border: 3px solid rgba(116, 80, 200, 0.3);
				box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
				position: absolute;
				content: '';
				height: 100%;
				width: 100%;
				top: 0;
				border-radius: 4px;
			}
		}

		&-title {
			font-size: 18px;
			font-weight: bold;
			color: $color-brand-1;
			margin-bottom: 30px;
			width: 150px;
			text-align: center;
		}

		&-img {
			margin-bottom: 30px;
		}

		&-text {
			width: 241px;
			font-size: 14px;
			height: 114px;
			color: $color-font-gray;
			font-size: 14px;
			text-align: center;
		}

		&-btn {
			margin-top: 16px;
			margin-bottom: 16px;
			min-height: 40px;
			height: auto;
		}

		&-check {
			position: absolute;
			right: -19px;
			top: -19px;
		}
	}

	&__form {
		margin-top: 24px;

		.form-group {
			max-width: 325px;
			width: 100%;
		}
	}

	&__save {
		width: 136px !important;
		margin-bottom: 24px;
	}

	&__keep {
		margin-right: 105px;

		@include screen-md {
			margin-right: 20px;
		}

		@include screen-sm {
			margin-right: 0;
		}
	}
}

.dp-profile {
	height: calc(100vh - 90px);
	overflow: scroll;
	padding-top: 48px;
	padding-bottom: 36px;

	@include screen-sm {
		height: calc(100vh - 53px);
	}

	&__banner {
		background: rgb(176, 186, 201);
		border-radius: 0px;
		box-shadow: 2px 4px 16px 0px rgba(51, 51, 51, 0.08);
		height: 52px;
		margin-bottom: 48px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		font-size: 18px;
		font-weight: bold;
	}

	&__back {
		font-size: 14px;
		font-weight: bold;
		color: $color-brand-1;
		margin-top: -20px;
		margin-bottom: 10px;

		&:hover {
			color: $color-brand-1;
			text-decoration: none;
		}
	}

	&__title {
		font-size: 24px;
		font-weight: bold;
		margin-bottom: 24px;
	}

	&__text {
		font-size: 16px;
		color: $color-font-gray;
		margin-bottom: 24px;
	}

	&__container {
		background: #fff;
		border-radius: 4px;
		box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
		min-height: 300px;
		height: auto;
		width: 100%;
		margin-top: 48px;
		padding-bottom: 48px;
	}

	&__header {
		background: #fff;
		border-radius: 10px;
		box-shadow: 2px 4px 16px 0px rgba(51, 51, 51, 0.08);
		min-height: 180px;
		width: 387px;
		margin-left: auto;
		margin-right: auto;
		padding-top: 20px;
		padding-bottom: 20px;

		@include screen-sm {
			width: 100%;
		}

		img {
			max-width: 100px;
			max-height: 90px;
			object-fit: cover;
			margin-left: auto;
			margin-right: auto;
			display: block;
		}
	}

	&__description-text {
		max-width: 696px;
		width: 100%;
		border: none !important;
		margin-left: auto;
		margin-right: auto;
		display: block;
		text-align: center;
		margin-top: 48px;
		font-size: 18px;
		color: $color-font-gray;
		margin-bottom: 32px;

		&:focus {
			outline: none;
		}
	}

	&__item {
		margin-top: 87px;
		margin-bottom: 48px;
		flex-wrap: wrap;
		display: flex;

		&-img {
			margin-top: 20px;
			flex: 1 1;
			padding: 0px 6.5%;
			justify-content: center;
			display: flex;
			flex-direction: column;

			img {
				height: 323px;
				object-fit: cover;
				width: 452px;
				border-radius: 5px;

				@include screen-sm {
					width: 250px;
				}
			}
		}

		&-content {
			flex: 1 1;
			padding: 0px 6.5%;
			justify-content: center;
			display: flex;
			flex-direction: column;
			margin-top: 20px;
		}

		&-title {
			border: none !important;
			font-size: 18px;
			font-weight: bold;
			margin-bottom: 32px;

			&:focus {
				outline: none;
			}
		}

		&-text {
			font-size: 14px;
			border: none !important;
			color: $color-font-gray;

			&:focus {
				outline: none;
			}
		}
	}

	&__add-logo {
		background: rgb(235, 235, 235);
		border-radius: 6px;
		height: 79px;
		width: 235px;
		font-size: 16px;
		font-weight: bold;
		color: $color-font-gray;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: auto;
		margin-right: auto;
		cursor: pointer;
	}

	&__add-photo {
		height: 323px;
		width: 452px;
		background: rgb(238, 238, 238);
		border-radius: 5px;
		box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
		height: 323px;
		width: 452px;
		letter-spacing: 1.71px;
		color: $color-font-gray;
		display: flex;
		font-size: 16px;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__add-photo-link {
		height: 323px;
		width: 452px;
		display: block;
		position: absolute;
		top: 0;
		cursor: pointer;
	}

	&__add-link {
		position: absolute;
		height: 79px;
		width: 235px;
		top: 0;
		left: 0;
		right: 0;
		display: block;
		margin-left: auto;
		margin-right: auto;
		cursor: pointer;
	}

	&__company-name {
		margin-left: auto;
		margin-right: auto;
		border: none !important;
		font-size: 22px;
		font-weight: bold;
		text-align: center;
		display: block;
		margin-top: 26px;

		&:focus {
			outline: none;
		}
	}
}

.customise-sell {
	flex: 1;
	height: calc(100vh - 140px);
	overflow: auto;

	&__title {
		font-size: 22px;
		font-weight: bold;
		letter-spacing: -0.16px;
		color: $color-dark-gray;
		margin-bottom: 21px;
	}

	&__subtitle {
		font-size: 16px;
		color: #292e31;
		margin-bottom: 24px;
		max-width: 504px;
		width: 100%;
	}

	&__preview {
		font-size: 16px;
		font-weight: bold;
		color: $color-brand-1;
		display: flex;
		align-items: center;
		margin-bottom: 9px;
	}

	&__label {
		font-size: 16px;
		font-weight: bold;
		color: $color-dark-gray;
		margin-top: 52px;
	}

	.-is-contained {
		border-radius: 4px;
		min-height: 36px;
		min-width: 136px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-left: 10px;
		padding-right: 10px;
	}
}
