@import "../../styles/utils/mixins";
@import "../../styles/utils/_variables";

@font-face {
  font-family: "Bookmania Semibold";
  src: url("../../styles/fonts/bookmania-semibold.otf") format("opentype");
}

.pages {
  height: calc(100vh - 90px) !important;
  overflow: hidden !important;

  .-is-contained {
    border-radius: 4px;
    min-height: 36px;
    min-width: 136px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  &.-has-banner {
    height: calc(100vh - 155px) !important;

    @include screen-sm {
      height: calc(100vh - 117px) !important;
    }
  }

  &.-has-scroll {
    overflow: scroll !important;
  }

  @include screen-sm {
    height: auto !important;
  }

  &__signature {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    min-height: 306px;
    width: 273px;
    padding: 24px 25px;
    margin-left: 35px;
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &-img {
      max-height: 50px;
      width: auto;
      margin-bottom: 20px;
      max-width: 95%;
    }

    &-title {
      font-size: 18px;
      font-weight: bold;
      color: $color-brand-1;
      margin-bottom: 16px;
    }

    &-text {
      font-size: 14px;
      color: $color-font-dark;
      text-align: center;
      margin-bottom: 24px;
    }

    @include screen-md {
      margin-left: auto;
      margin-right: auto;
    }

    @include screen-sm {
      max-width: 273px !important;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
    }
  }

  &__text {
    font-size: 16px;
    color: $color-font-gray;
    margin-top: 48px;
    margin-bottom: 48px;
  }

  &__content {
    @include screen-sm {
      flex-direction: column;
      height: 100%;
      overflow: scroll;
    }

    .table {
      width: 100%;

      table {
        width: 100%;
      }

      td {
        border: 1px solid #dee2e6 !important;
      }
    }
  }

  &__container {
    height: 100%;
    overflow: auto;
  }

  &__items {
    max-width: 911px;
    width: 100%;
  }

  &__contents {
    display: flex;

    @include screen-md {
      flex-wrap: wrap;
    }
  }

  &__item {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    min-height: 122px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 24px 24px 32px;
    max-width: 911px;
    width: 100%;
    margin-bottom: 24px;
    cursor: pointer;
    position: relative;

    @include screen-xs {
      padding: 10px;

      svg {
        margin-right: 10px !important;
      }
    }

    &-remove {
      position: absolute;
      right: 16px;
      top: 16px;
      cursor: pointer;
    }

    &-terms {
      font-size: 18px;
      font-weight: 600;
      color: $color-brand-1;
      margin-bottom: 8px;
    }

    &-done {
      font-size: 14px;
      font-weight: bold;
      color: $color-font-gray;
      margin-bottom: 8px;
    }

    &-update {
      font-size: 12px;
      color: $color-font-gray;
      margin-bottom: 0;
    }

    &-icon {
      width: 40px;
      height: 40px;
    }
  }

  &__btns {
    background: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    height: 100%;
    min-width: 273px;
    width: 273px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 24px 25px;
    margin-left: 35px;
    max-height: 255px;

    @include screen-md {
      margin-left: auto;
      margin-right: auto;
    }

    @include screen-sm {
      max-width: 273px !important;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
    }
  }
}

.page-left-menu {
  background: rgb(255, 255, 255);
  border: 1px solid rgba(46, 91, 255, 0.08);
  height: calc(100vh - 90px);
  max-width: 350px;
  min-width: 350px;
  padding: 48px 0 20px 0;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);

  &.-has-preview {
    @include screen-sm {
      display: none;
    }
  }

  &__back {
    font-size: 14px;
    font-weight: bold;
    color: $color-brand-1;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 32px;
  }

  .-is-disabled {
    opacity: 0.5;
    user-select: none;
    cursor: default !important;
  }

  &__drag {
    display: none;
    position: absolute;
    left: 2px;
  }

  @media (max-width: 1190px) {
    max-width: 270px;
    min-width: 270px;
  }

  @include screen-sm {
    max-width: 100%;
    min-width: 100%;
    height: 100%;
  }

  &__title {
    font-family: "Bookmania Semibold", "Roboto", sans-serif;
    font-size: 22px;
    letter-spacing: -0.16px;
    margin-bottom: 0;
  }

  &__header {
    padding-right: 46px;
    padding-left: 24px;

    @media (max-width: 1190px) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  &__icon {
    min-width: 16px;
    margin-right: 11px;
  }

  &__text {
    font-size: 16px;
    color: $color-font-dark;
  }

  &__item {
    min-height: 59px;
    width: 280px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 19px 7px 16px;
    margin-bottom: 24px;
    margin-left: 24px;
    position: relative;

    @media (max-width: 1190px) {
      margin-left: 5px;
      width: 260px;
    }

    @include screen-sm {
      margin-left: 10px;
    }

    &.-is-active,
    &:hover {
      background: #fff;
      border-radius: 4px;
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);

      .page-left-menu__drag {
        display: block;
      }
    }

    &-title,
    &-input {
      font-size: 14px;
      font-weight: bold;
      color: $color-font-gray;
      margin-bottom: 0;
      width: 200px !important;

      &:focus {
        outline: none;
      }
    }

    &-text {
      font-size: 12px;
      margin-bottom: 0;
      color: $color-font-gray;
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (max-width: 1190px) {
        width: 160px;
      }
    }
  }

  &__add {
    display: flex;
    align-items: center;
    color: $color-brand-1;
    margin-top: 33px;
    cursor: pointer;
    margin-left: 24px;
    margin-bottom: 30px;

    &-text {
      margin-bottom: 0;
      margin-left: 8px;
      font-size: 14px;
      font-weight: bold;
    }
  }

  &__content {
    overflow-y: auto;
    height: calc(100vh - 300px);
    padding-top: 5px;
    padding-bottom: 30px;

    @include screen-sm {
      overflow: scroll;
    }
  }
}

.page-right-menu {
  background: #fff;
  border: 1px solid rgba(46, 91, 255, 0.08);
  height: calc(100vh - 90px);
  max-width: 310px;
  width: 310px;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding-top: 48px;
  height: calc(100vh - 90px);
  overflow: auto;
  overflow-x: hidden;

  @media (max-width: 1190px) {
    max-width: 250px;
    width: 250px;
  }

  @include screen-sm {
    min-height: auto;
    height: auto;
    overflow: visible;
    width: 100%;
    max-width: 100%;
  }

  &__form {
    width: 233px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__textarea {
    &:focus {
      outline: none;
    }
  }

  &__help-section {
    width: 233px;

    &-title {
      font-size: 14px;
      font-weight: 600;
      color: $color-brand-1;
      text-align: center;
      margin-top: 24px;
      margin-bottom: 9px;
    }

    &-btns {
      display: flex;
      margin-top: 16px;
    }

    &-save,
    &-cancel {
      width: 96px !important;
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  &__help-text {
    font-size: 14px;
    color: $color-font-base;
    max-width: 80%;
    width: 100%;
    display: block;
    line-height: 26px;
  }

  &__last-edit {
    font-size: 14px;
    font-weight: bold;
    color: $color-brand-1;
    margin-top: 32px;
    cursor: pointer;
    padding-left: 44px;

    &.-is-disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    @include screen-sm {
      width: 100%;
      padding-left: 0;
      text-align: center;
    }

    @media (max-width: 1190px) {
      width: 100%;
      padding-left: 0;
      text-align: center;
    }
  }

  &__items {
    margin-left: 44px;
    margin-top: 32px;
    margin-bottom: 20px;

    @media (max-width: 1190px) {
      margin-left: 10px;
    }

    @include screen-sm {
      margin-left: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }

  &__item {
    height: 78px;
    padding-left: 44px;
    border-bottom: 1px solid $color-lightest-gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;

    @media (max-width: 1190px) {
      padding-left: 10px;
    }

    &.-is-active {
      background: $color-light-gray;
    }

    &-date {
      font-size: 14px;
      color: $color-font-gray;
      margin-bottom: 8px;
    }

    &-version {
      font-size: 14px;
      margin-bottom: 0;
      font-weight: bold;
      color: $color-brand-1;
      display: flex;

      &.-is-current {
        font-weight: normal;
        color: $color-font-gray;
      }
    }

    &-changes {
      color: $color-brand-3;
      display: flex;
      align-items: center;
    }

    &-dot {
      height: 10px;
      width: 10px;
      background: $color-brand-3;
      display: block;
      margin-left: 32px;
      margin-right: 8px;
      border-radius: 50%;
    }
  }

  &__btns {
    margin-left: 44px;

    @media (max-width: 1190px) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-left: 0;
    }

    @include screen-sm {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-left: 0;
    }
  }

  &__download {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    color: $color-brand-1;
    cursor: pointer;

    &.-is-disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &:hover {
      color: $color-brand-1;
      text-decoration: none;
    }

    @include screen-sm {
      width: 200px;
    }
  }

  &__icons {
    max-width: 15px;
    cursor: pointer;
  }
}

.page-content {
  flex: 1;
  padding: 48px 0;
  height: calc(100vh - 90px);
  overflow: auto;
  position: relative;

  .alert {
    margin-left: 40px;
    margin-right: 40px;
  }

  &__diff {
    margin-left: 40px;
    margin-right: 40px;
  }

  .ck.ck-editor__main,
  .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred,
  .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused {
    background: transparent;
    border: none;
    box-shadow: none;
  }

  .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred,
  .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused {
    padding: 0;
  }

  .ck.ck-toolbar.ck-toolbar_grouping {
    background: rgb(255, 255, 255);
    border-radius: 0px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    height: 55px;
    padding-left: 40px;
    padding-right: 40px;
    border: none;
  }

  .ck.ck-toolbar__items {
    justify-content: space-around;
  }

  .ck.ck-editor__top.ck-reset_all {
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 30px;
  }

  .ck.ck-list__item .ck-button.ck-on {
    background: $color-brand-1 !important;
  }

  .ck {
    padding-left: 40px;
    padding-right: 40px;
  }

  &__del {
    p,
    tr,
    li,
    h2,
    h1,
    h3,
    blockquote {
      background: #fcc3c2;
      color: #969696;
    }
  }

  &__ins {
    p,
    tr,
    li,
    h2,
    h1,
    h3,
    blockquote {
      background: #ffe29d;
      white-space: pre-wrap;
      color: #969696;
    }
  }

  &__edit {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
    height: 45px;
    font-size: 14px;
    font-weight: bold;
    width: 126px;
    color: #969696;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 40px;
  }

  @media (max-width: 1190px) {
    padding: 40px 10px 20px 10px;
  }

  @include screen-sm {
    flex: auto;
    min-height: auto;
    height: 100%;
    overflow: visible;
    padding: 40px 10px 20px 10px;
  }

  .Difference {
    font-size: 16px;
    color: $color-font-gray;

    span {
      white-space: pre-wrap;
    }

    ins {
      background: rgba(235, 166, 1, 0.3);
      white-space: pre-wrap;
    }

    del {
      background: rgba(248, 107, 103, 0.3);
      white-space: pre-wrap;
    }
  }

  &__title {
    font-size: 22px;
    font-weight: bold;
    letter-spacing: -0.16px;
    margin-bottom: 48px;
    background: transparent;
    width: 100%;
    outline: none;
    padding-left: 40px;
  }

  &__text {
    font-size: 16px;
    color: $color-font-dark;
    background: transparent;
    width: 100%;
    outline: none;
  }

  &__subtitle {
    font-size: 16px;
    font-weight: bold;
    color: $color-font-gray;
    background: transparent;
    width: 100%;
    outline: none;
    margin-bottom: 21px !important;
  }
}

.add-page {
  background: #fff;
  border-radius: 4px;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
  min-height: 581px;
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  &__edition {
    font-size: 14px;
    color: $color-font-gray;
    font-weight: bold;
  }

  .check {
    margin-left: 0;
    margin-right: 24px;
    border-radius: 4px;
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;

    @include screen-sm {
      margin-right: 10px;
    }

    &.-unchecked {
      border: 1px solid $color-font-light;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: transparent !important;
      }
    }

    &.-checked {
      background: $color-brand-3;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__visible {
    font-size: 14px;
    color: $color-font-gray;
  }

  &__check {
    border-radius: 4px;
    border: 1px solid $color-font-light;
    display: block;
    height: 13px;
    width: 13px;
    cursor: pointer;

    &.-is-active {
      background: $color-brand-1;
    }
  }

  &__title {
    font-family: "Bookmania Semibold", "Roboto", sans-serif;
    letter-spacing: -0.16px;
    font-size: 22px;
    margin-bottom: 16px;
  }

  &__text {
    font-size: 14px;
    color: $color-font-gray;
    width: 80%;
    text-align: center;
    margin-bottom: 48px;

    &-secondary {
      font-size: 14px;
      color: $color-font-gray;
      white-space: nowrap;

      @include screen-sm {
        white-space: pre-wrap;
      }
    }
  }

  &__form {
    margin-top: 32px;

    input {
      width: 325px;

      @include screen-sm {
        width: 100%;
      }
    }
  }

  &__label {
    font-size: 14px;
    font-weight: 600;
    color: $color-brand-1;
    margin-bottom: 8px;
  }

  &__btn {
    width: 136px !important;
    margin-left: auto;
    margin-right: auto;
    display: block !important;
    margin-top: 32px;
  }

  &__obs {
    position: absolute;
    right: -200px;
    top: 15px;
    width: 174px;
    font-size: 14px;
    color: $color-font-gray;

    @include screen-md {
      width: 100px;
      right: -120px;
    }

    @include screen-xs {
      display: none;
    }
  }
}

.footer {
  &__container {
    padding-left: 72px;
    padding-right: 72px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-around;
    padding-top: 49px;
    padding-bottom: 49px;
    background: #dfe4ee;
  }

  &__logo {
    background: $color-brand-1;
    opacity: 0.65;
    height: 45px;
    width: 45px;
    min-width: 45px;
    display: block;
    border-radius: 50%;
    margin-right: 24px;

    &-container {
      height: 45px;
      display: block;
      margin-right: 24px;
      overflow: hidden;
      max-width: 150px;

      img {
        height: 100%;
        object-fit: contain !important;
        width: 100% !important;
      }
    }
  }

  p {
    font-size: 14px;
  }

  a {
    color: $color-font-base;
    font-size: 14px;

    &:hover {
      color: $color-font-base;
      text-decoration: none;
    }
  }

  &-text {
    border-top: 1px solid #969696;
    text-align: center;
    padding-top: 26px;
    padding-bottom: 26px;
    background: #dfe4ee;
    font-size: 14px;
  }
}

.page-preview {
  margin-top: 42px;
  height: calc(100vh - 209px);
  overflow: auto;
  width: 80% !important;
  zoom: 80%;
  height: 100%;

  blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: 5px solid #ccc;
  }

  .table {
    table {
      width: 100%;
    }

    td {
      border: 1px solid #dee2e6 !important;
    }
  }

  img {
    object-fit: contain;
    max-height: 60px;
    width: 100%;
    max-width: 150px;
  }

  @include screen-sm {
    margin-top: 20px;
    width: 100% !important;
    zoom: 100%;
  }

  &.-has-zoom {
    zoom: 120%;
  }

  &__zoom {
    position: fixed;
    bottom: 24px;
    right: 24px;
    display: flex;
    flex-direction: column;

    span {
      background: #fff;
      box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.1);
      height: 36px;
      width: 36px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 4px;
      margin-bottom: 4px;
      cursor: pointer;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border-radius: 0px;
    box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
    height: 72px;
    padding-left: 30px;
    padding-right: 30px;

    @include screen-sm {
      padding-left: 10px;
      padding-right: 10px;
    }

    &-items {
      display: flex;
    }

    &-logo {
      height: 45px;
      width: 45px;
      display: block;
      background: $color-brand-1;
      border-radius: 50%;
      opacity: 0.64;
      margin-right: 24px;

      &-text {
        font-size: 28px;
        font-weight: bold;
        color: $color-brand-1;
        opacity: 0.64;
      }
    }

    &-get {
      font-size: 14px;
      color: $color-brand-1;
      margin-bottom: 0;
      border-radius: 4px;
      border: 1.5px solid rgb(116, 80, 200);
      height: 36px;
      display: flex;
      align-items: center;
      padding: 8px 12px;
    }
  }

  &__header {
    background: rgb(223, 228, 238);
    border-radius: 0px;
    min-height: 306px;
    padding-left: 30px;

    @include screen-sm {
      padding-left: 10px;
      height: auto;
      min-height: auto;
      padding-bottom: 20px;
    }

    &-title {
      padding-top: 105px;
      font-family: "Bookmania Semibold", "Roboto", sans-serif;
      letter-spacing: -0.54px;
      font-size: 48px;
      margin-bottom: 16px;

      @include screen-sm {
        padding-top: 30px;
      }
    }

    &-text {
      font-size: 18px;
      color: $color-font-gray;
    }
  }

  &__content {
    margin-top: 0px;
    padding-top: 64px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    padding-bottom: 40px;

    &.-inherit-height {
      min-height: 500px;
      height: auto;
    }

    @include screen-xs {
      flex-direction: column;
      padding-right: 10px;
      margin-top: 30px;
      padding-right: 10px;
    }

    @include screen-sm {
      padding-left: 10px;
      padding-right: 10px;
    }

    &-container {
      max-width: 600px;

      @include screen-xs {
        max-width: 100%;
      }
    }

    &-menu {
      min-width: 200px;
    }

    &-menu-item {
      font-size: 14px;
      font-weight: bold;
      color: $color-brand-1;
    }

    &-title {
      font-size: 28px;
      font-weight: 600;
      font-family: "Bookmania Semibold", "Roboto", sans-serif;
      letter-spacing: -0.2px;
    }

    &-text {
      font-size: 14px;
      color: $color-font-gray;
      white-space: pre-wrap;
    }
  }
}
