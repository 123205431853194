@import './../../styles/utils/mixins';
@import './../../styles/utils/variables';

@font-face {
    font-family: 'Bookmania Semibold';
    src: url('../../styles/fonts/bookmania-semibold.otf') format('opentype');
};

.dpo {
    &__container {
        height: 100vh;
        padding-top: 71px;
        overflow: auto !important;
        background: $color-light-gray;
    }

    &__banner {
        height: 307px;
        background: $color-lightest-gray;

        &-title {
            font-size: 56px;
            font-weight: 600;
            font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
            padding-top: 106px;
            margin-bottom: 24px;
        }

        &-text {
            font-size: 14px;
            color: $color-font-gray;
        }
    }

    &__dpo {
        &-avatar {
            box-shadow: 3px 3px 10px 0px rgba(51, 51, 51, 0.1);
            height: 160px;
            width: 160px;
            border-radius: 50%;
            overflow: hidden;
            position: relative;
            top: -40px;

            @include screen-md {
                top: 20px
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &-name {
            font-family: 'Bookmania Semibold', 'Roboto', sans-serif;
            letter-spacing: -0.2px;
            font-size: 28px;
            font-weight: 600;
            margin-top: 54px;
            margin-bottom: 24px;
        }

        &-description {
            font-size: 14px;
            color: $color-font-gray;

            a {
                color: $color-brand-1;

                &:hover {
                    color: $color-brand-1;
                }
            }
        }

        &-request {
            color: $color-brand-1;
        }
    }
}